import React from 'react'
import cn from 'classnames'
import FormControl from './FormControl'
import { useTranslation } from 'react-i18next'

const FormInput = ({ label, placeholder, type, input, meta, white, hint, onChange }) => {
  const { t } = useTranslation()

  const invalid = (meta && meta.touched && meta.error) || (meta && !!meta.submitError)
  const validationResult = invalid ? meta.error || meta.submitError : undefined
  const error = typeof validationResult === 'function' ? validationResult(t) : validationResult

  return (
    <FormControl
      className={cn('form-input', { '-white': white })}
      label={label}
      invalid={invalid}
      error={error}
      hint={hint}
    >
      <input
        className="form-input_input form-control_input"
        type={type}
        {...input}
        placeholder={placeholder}
        onChange={!meta && onChange ? onChange : input.onChange}
      />
    </FormControl>
  )
}

export default FormInput
