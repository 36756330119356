import React, { useState } from 'react'
import cn from 'classnames'
import { find, propEq } from 'ramda'

import MD from './Markdown'
import Tile from './Tile'
import OptionList from './OptionList'
import Button from './Button'
import FormTextarea from './FormTextarea'
import Hint from './Hint'
import Modal from './Modal'

import '../../styles/blocks/yes-if-no-option.scss'
import { useTranslation } from 'react-i18next'

export const YES = 'Yes'
export const NO = 'No'
export const YES_IF = 'YesIf'

const YesIfNoOption = ({ params, values, form }) => {
  const { t } = useTranslation()

  const questionId = params.id
  const { otherOptionId, options } = params.yesIf
  const { answer, optionId, optionText } = values[questionId] || {}
  const { hiddenOptions = [] } = params

  const [showYesIfModal, setShowIfYesModal] = useState(false)
  const [showTypeAnswer, setShowTypeAnswer] = useState(false)

  const setAnswer = (value) => {
    form.mutators.setValue(`${questionId}[answer]`, value)
  }

  const setOptionId = (value) => {
    form.mutators.setValue(`${questionId}[optionId]`, value)
  }

  const setOptionText = (value) => {
    form.mutators.setValue(`${questionId}[optionText]`, value)
  }

  const clearOptionData = () => {
    setOptionId(null)
    setOptionText('')
  }

  const onYesClick = () => {
    setAnswer(YES)
    clearOptionData()
  }

  const onYesIfClick = () => {
    setShowIfYesModal(true)
  }

  const onNoClick = () => {
    setAnswer(NO)
    clearOptionData()
  }

  const setYesIfAnswer = ({ id, text }) => () => {
    setShowIfYesModal(false)
    setShowTypeAnswer(false)

    if ((text || '').trim().length > 0) {
      const optionText = id === otherOptionId ? text : ''
      setAnswer(YES_IF)
      setOptionId(id)
      setOptionText(optionText)
    }
  }

  const typeYesIfAnswer = () => {
    setShowTypeAnswer(true)
  }

  const handleChange = ({ target: { value } }) => {
    setOptionText(value)
  }

  const handleModalClose = () => {
    setShowIfYesModal(false)
    if (!optionText) {
      setShowTypeAnswer(false)
    }
  }

  return (
    <div className="yes-if-no-option">
      <Tile className="yes-if-no-option_tile" title={params.title}>
        {params.hint && (
          <Hint
            className="yes-if-no-option_hint"
            title={params.hint.title}
            contents={params.hint.contents}
            text={params.hint.text}
          />
        )}
        <div className="yes-if-no-option_text">
          <MD>{params.text}</MD>
        </div>
        <div className="yes-if-no-option_buttons">
          {!hiddenOptions.includes(YES) && (
            <button
              type="button"
              className={cn('yes-if-no-option_button', {
                '-active': answer === YES,
              })}
              onClick={onYesClick}
            >
              {t('common.yesIfNoOption.yes')}
            </button>
          )}
          {!hiddenOptions.includes(YES_IF) && (
            <button
              type="button"
              className={cn('yes-if-no-option_button', {
                '-active': answer === YES_IF,
              })}
              onClick={onYesIfClick}
            >
              {t('common.yesIfNoOption.yesIf')}
            </button>
          )}
          {!hiddenOptions.includes(NO) && (
            <button
              type="button"
              className={cn('yes-if-no-option_button', {
                '-active': answer === NO,
              })}
              onClick={onNoClick}
            >
              {t('common.yesIfNoOption.no')}
            </button>
          )}
        </div>
        {answer === YES_IF && optionId && (
          <div className="yes-if-no-option_selected-answer">
            <div className="yes-if-no-option_selected-answer-inset">
              {optionId === otherOptionId ? optionText : (find(propEq('id', optionId), options) || {}).text}
            </div>
          </div>
        )}
        <Modal title={params.yesIf.title} isOpen={showYesIfModal} onRequestClose={handleModalClose}>
          <div className="modal_text">
            <MD allowParagraphs>{params.yesIf.text}</MD>
          </div>
          {!showTypeAnswer ? (
            <>
              {params.yesIf.questionChoose && (
                <div className="modal_text yes-if-no-option_modal-question">
                  <MD allowParagraphs>{params.yesIf.questionChoose}</MD>
                </div>
              )}
              <div className="yes-if-no-option_yi-options">
                <OptionList options={options} onOptionSelect={setYesIfAnswer} />
                <span className="yes-if-no-option_yi-custom-btn" onClick={typeYesIfAnswer} role="button">
                  {t('common.yesIfNoOption.custom')}
                  {optionId === otherOptionId && <p className="modal_text">{optionText}</p>}
                </span>
              </div>
            </>
          ) : (
            <>
              {params.yesIf.questionCustom && (
                <div className="modal_text yes-if-no-option_modal-question">
                  <MD allowParagraphs>{params.yesIf.questionCustom}</MD>
                </div>
              )}
              <div className="yes-if-no-option_yi-custom">
                <FormTextarea value={optionText} onChange={handleChange} />
                <Button
                  className="yes-if-no-option_yi-custom-button -wide"
                  type="button"
                  onClick={setYesIfAnswer({
                    id: otherOptionId,
                    text: optionText,
                  })}
                >
                  {t('common.yesIfNoOption.continue')}
                </Button>
              </div>
            </>
          )}
        </Modal>
      </Tile>
    </div>
  )
}

export default YesIfNoOption
