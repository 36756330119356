import React from 'react'
import { find, propEq } from 'ramda'
import TickList from '../TickList'
import { YES_IF, NO } from '../YesIfNoOption'

export const YesIfNoSummary = ({ params: { qids, labels, options }, answers }) => {
  const getIntervention = ({ label, value, qid }) => {
    const { answer, optionId, optionText } = value
    if (answer !== YES_IF) {
      return {
        text: `${label}: ${answer}`,
        icon: answer === NO ? 'minus' : 'tick',
      }
    }
    const getOptionText = (optionId) => (find(propEq('id', optionId), options[qid].options) || {}).text

    const getText = (optionId) => (optionId === options[qid].otherOptionId ? optionText : getOptionText(optionId))
    return {
      text: `${label}: Yes, ${getText(optionId)}`,
      icon: 'tick',
    }
  }

  const interventionsData = []

  labels.forEach((label, i) => {
    const qid = qids[i]
    const value = answers[qid]

    if (value) {
      const data = getIntervention({ label, value, qid })
      interventionsData.push(data)
    }
  })

  return interventionsData.length ? <TickList items={interventionsData} /> : null
}
