import React from 'react'


const RadioInput = ({ label, value, checked, disabled, setter, className }) => {
  return (
    <label>
      <input
        type="radio"
        checked={checked === value}
        disabled={disabled}
        onChange={() => setter(value)}
        className={className}
      />
      <span>{label}</span>
    </label>
  )
}

export default RadioInput
