import React from 'react'
import MD from './Markdown'

import { ReactComponent as ArrowRight } from '../../assets/images/arrow-corner-right.svg'

import '../../styles/blocks/option.scss'

const Option = ({ onClick, text }) => (
  <div className="option" role="button" onClick={onClick}>
    <span className="option_text">
      <MD>{text}</MD>
    </span>
    <ArrowRight className="option_icon icon -arrow-corner-right" />
  </div>
)

export default Option