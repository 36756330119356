import React from 'react'
import { map, find, propEq, compose, propOr } from 'ramda'
import { relationships } from '../../../constants/relationships'
import DefinitionsList from '../DefinitionsList'
import { useTranslation } from 'react-i18next'

const getLabelByValue = (value) => compose(propOr('', 'label'), find(propEq('value', value)))

const getRelationship = (value, otherValue) => (value === 'other' ? otherValue : getLabelByValue(value)(relationships))

const getDefinitions = (t) => ({ name, role, relationship, relationshipother }) => {
  const relationshipValue = getRelationship(relationship, relationshipother)

  return [
    {
      term: 'Name',
      definition: name || '-',
    },
    {
      term: 'Role',
      definition: role || '-',
    },
    {
      term: 'Relationship',
      definition: relationshipValue ? t(relationshipValue) : '-',
    },
  ]
}

export const OtherPeopleSummary = ({ params: { id }, answers }) => {
  const { t } = useTranslation()

  const peopleData = map(getDefinitions(t), answers[id])

  return (
    <>
      {peopleData.map((item, index) => (
        <DefinitionsList items={item} key={index} className={index > 0 && '-with-margin-top'} />
      ))}
    </>
  )
}
