import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import YesIfNoOption, { YES } from '../blocks/YesIfNoOption'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswerByQid } from '../TheDirective'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { WELL_SUMMARY_TYPE } from '../../constants/productTypes'
import { FTChangesPage } from './FTChangesPage'
import { GoalsOfCarePage } from './GoalsOfCarePage'
import TextAnswer from '../blocks/TextAnswer'
import { YesIfNoSummary } from '../blocks/summary/YesIfNoSummary'
import { TextAnswerSummary } from '../blocks/summary/TextAnswerSummary'
import {
  getCprHintData,
  getDialysisHintData,
  getFeedingTubeHintData,
  getMechventHintData,
} from '../goalsOfCareHintsData'

const FT_GOALS_OF_CARE_URL = 'ft-goals-of-care'
const FT_CPR_QID = 'ftcpr_crisis'
const FT_MECHVENT_QID = 'ftmechvent_crisis'
const FT_FEEDINGTUBE_QID = 'ftfeedingtube_crisis'
const FT_DIALYSIS_QID = 'ftdialysis_crisis'
const FT_YESIFNO_QIDS = [FT_CPR_QID, FT_MECHVENT_QID, FT_FEEDINGTUBE_QID, FT_DIALYSIS_QID]
const FT_OTHERWISHES_QID = 'ftotherwishes_crisis'
const FT_ALL_QIDS = [...FT_YESIFNO_QIDS, FT_OTHERWISHES_QID]

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(WELL_SUMMARY_TYPE))

const FTGoalsOfCarePage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: FT_GOALS_OF_CARE_URL,
    getPrevStep: () => ({
      page: GoalsOfCarePage(t),
      url: `${DIRECTIVE_URL}/${GoalsOfCarePage(t).slug}`,
    }),
    getNextStep: () => ({
      page: FTChangesPage(t),
      url: `${DIRECTIVE_URL}/${FTChangesPage(t).slug}`,
    }),
    setInitialValues: (answers) => {
      const initialValues = FT_ALL_QIDS.reduce((res, qid) => ({ ...res, [qid]: getAnswerByQid(qid)(answers) }), {})
      if (Object.values(initialValues).some(Boolean)) {
        return initialValues
      }
      return FT_YESIFNO_QIDS.reduce((res, qid) => ({ ...res, [qid]: { answer: YES } }), {})
    },
    serializeValues: (values) =>
      FT_ALL_QIDS.filter((qid) => values[qid] !== undefined).map((qid) => ({
        variables: { qid, val: values[qid] },
        update: updateCacheAnswers,
      })),
    validate: () => {},
    isRequired: false,
    isAnswered: (answers) => FT_YESIFNO_QIDS.every((qid) => !!getAnswerByQid(qid)(answers)),
    dashboard: {
      title: t('directive.well.page.FTGoalsOfCare.dashboardTitle'),
    },
    summary: {
      title: t('directive.well.page.FTGoalsOfCare.summaryTitle'),
      blocks: [
        {
          component: YesIfNoSummary,
          params: {
            qids: FT_YESIFNO_QIDS,
            labels: [
              t('directive.well.page.FTGoalsOfCare.summaryLabels.cpr'),
              t('directive.well.page.FTGoalsOfCare.summaryLabels.mechanicalVentilation'),
              t('directive.well.page.FTGoalsOfCare.summaryLabels.feedingTube'),
              t('directive.well.page.FTGoalsOfCare.summaryLabels.dialysis'),
            ],
            options: answersOptions,
          },
        },
        {
          component: TextAnswerSummary,
          params: {
            id: FT_OTHERWISHES_QID,
            heading: t('directive.well.page.FTGoalsOfCare.summaryLabels.other'),
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.well.page.FTGoalsOfCare.blocks.pageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.well.page.FTGoalsOfCare.blocks.pageText'),
        },
        columnRight: true,
      },
      {
        component: YesIfNoOption,
        params: {
          id: FT_CPR_QID,
          title: t('common.goalsOfCare.card.cpr.title'),
          text: t('common.goalsOfCare.card.cpr.text'),
          hint: getCprHintData(t),
          yesIf: {
            title: t('common.goalsOfCare.card.cpr.yesIf.title'),
            text: t('common.goalsOfCare.card.cpr.yesIf.text'),
            questionChoose: t('common.goalsOfCare.card.cpr.yesIf.questionChoose'),
            questionCustom: t('common.goalsOfCare.card.cpr.yesIf.questionCustom'),
            otherOptionId: answersOptions[FT_CPR_QID].otherOptionId,
            options: answersOptions[FT_CPR_QID].options,
          },
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: FT_MECHVENT_QID,
          title: t('common.goalsOfCare.card.mechvent.title'),
          text: t('common.goalsOfCare.card.mechvent.text'),
          hint: getMechventHintData(t),
          yesIf: {
            title: t('common.goalsOfCare.card.mechvent.yesIf.title'),
            text: t('common.goalsOfCare.card.mechvent.yesIf.text'),
            questionChoose: t('common.goalsOfCare.card.mechvent.yesIf.questionChoose'),
            questionCustom: t('common.goalsOfCare.card.mechvent.yesIf.questionCustom'),
            otherOptionId: answersOptions[FT_MECHVENT_QID].otherOptionId,
            options: answersOptions[FT_MECHVENT_QID].options,
          },
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: FT_FEEDINGTUBE_QID,
          title: t('common.goalsOfCare.card.feedingtube.title'),
          text: t('common.goalsOfCare.card.feedingtube.text'),
          hint: getFeedingTubeHintData(t),
          yesIf: {
            title: t('common.goalsOfCare.card.feedingtube.yesIf.title'),
            text: t('common.goalsOfCare.card.feedingtube.yesIf.text'),
            otherOptionId: answersOptions[FT_FEEDINGTUBE_QID].otherOptionId,
            options: answersOptions[FT_FEEDINGTUBE_QID].options,
          },
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: FT_DIALYSIS_QID,
          title: t('common.goalsOfCare.card.dialysis.title'),
          text: t('common.goalsOfCare.card.dialysis.text'),
          hint: getDialysisHintData(t),
          yesIf: {
            title: t('common.goalsOfCare.card.dialysis.yesIf.title'),
            text: t('common.goalsOfCare.card.dialysis.yesIf.text'),
            otherOptionId: answersOptions[FT_DIALYSIS_QID].otherOptionId,
            options: answersOptions[FT_DIALYSIS_QID].options,
          },
        },
      },
      {
        component: TextAnswer,
        params: {
          id: FT_OTHERWISHES_QID,
          title: t('directive.well.page.FTGoalsOfCare.blocks.otherWishes.title'),
          text: t('directive.well.page.FTGoalsOfCare.blocks.otherWishes.text'),
          notRemoveEmptyField: true,
          tileWrapped: true,
        },
      },
    ],
  }
}

export {
  FTGoalsOfCarePage,
  FT_GOALS_OF_CARE_URL,
  FT_CPR_QID,
  FT_MECHVENT_QID,
  FT_FEEDINGTUBE_QID,
  FT_DIALYSIS_QID,
  FT_YESIFNO_QIDS,
  FT_ALL_QIDS,
}
