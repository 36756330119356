import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import YesIfNoOption, { YES } from '../blocks/YesIfNoOption'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswerByQid } from '../TheDirective'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { WELL_SUMMARY_TYPE } from '../../constants/productTypes'
import { CCSpecificWishesSecondPage } from './CCSpecificWishesSecondPage'
import { GoalsOfCarePage } from './GoalsOfCarePage'
import { YesIfNoSummary } from '../blocks/summary/YesIfNoSummary'
import {
  getCprHintData,
  getDialysisHintData,
  getFeedingTubeHintData,
  getMechventHintData,
} from '../goalsOfCareHintsData'

const CC_SPECIFIC_WISHES_URL = 'cc-goals-of-care-1'
const CC_CPR = 'cccpr'
const CC_MECHVENT = 'ccmechvent'
const CC_FEEDINGTUBE = 'ccfeedingtube'
const CC_DIALYSIS = 'ccdialysis'
const CC_QIDS = [CC_CPR, CC_MECHVENT, CC_FEEDINGTUBE, CC_DIALYSIS]

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(WELL_SUMMARY_TYPE))

const CCSpecificWishesFirstPage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: CC_SPECIFIC_WISHES_URL,
    getPrevStep: () => ({
      page: GoalsOfCarePage(t),
      url: `${DIRECTIVE_URL}/${GoalsOfCarePage(t).slug}`,
    }),
    getNextStep: () => ({
      page: CCSpecificWishesSecondPage(t),
      url: `${DIRECTIVE_URL}/${CCSpecificWishesSecondPage(t).slug}`,
    }),
    setInitialValues: (answers) => {
      return CC_QIDS.reduce((res, qid) => ({ ...res, [qid]: getAnswerByQid(qid)(answers) }), {})
    },
    serializeValues: (values) =>
      CC_QIDS.filter((qid) => values[qid] !== undefined).map((qid) => ({
        variables: { qid, val: values[qid] },
        update: updateCacheAnswers,
      })),
    validate: () => {},
    isRequired: false,
    isAnswered: (answers) => CC_QIDS.some((qid) => !!getAnswerByQid(qid)(answers)),
    dashboard: {
      title: t('directive.well.page.CCSpecificWishes.dashboardTitle'),
    },
    summary: {
      title: t('directive.well.page.CCSpecificWishes.summaryTitle'),
      blocks: [
        {
          component: YesIfNoSummary,
          params: {
            qids: CC_QIDS,
            labels: [
              t('directive.well.page.CCSpecificWishes.summaryLabels.cpr'),
              t('directive.well.page.CCSpecificWishes.summaryLabels.mechanicalVentilation'),
              t('directive.well.page.CCSpecificWishes.summaryLabels.feedingTube'),
              t('directive.well.page.CCSpecificWishes.summaryLabels.dialysis'),
            ],
            options: answersOptions,
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.well.page.CCSpecificWishes.blocks.pageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.well.page.CCSpecificWishes.blocks.pageText'),
        },
        columnRight: true,
      },
      {
        component: YesIfNoOption,
        params: {
          id: CC_CPR,
          title: t('common.goalsOfCare.card.cpr.title'),
          text: t('common.goalsOfCare.card.cpr.text'),
          hint: getCprHintData(t),
          hiddenOptions: [YES],
          yesIf: {
            title: t('common.goalsOfCare.card.cpr.yesIf.title'),
            text: t('common.goalsOfCare.card.cpr.yesIf.text'),
            questionChoose: t('common.goalsOfCare.card.cpr.yesIf.questionChoose'),
            questionCustom: t('common.goalsOfCare.card.cpr.yesIf.questionCustom'),
            otherOptionId: answersOptions[CC_CPR].otherOptionId,
            options: answersOptions[CC_CPR].options,
          },
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: CC_MECHVENT,
          title: t('common.goalsOfCare.card.mechvent.title'),
          text: t('common.goalsOfCare.card.mechvent.text'),
          hint: getMechventHintData(t),
          hiddenOptions: [YES],
          yesIf: {
            title: t('common.goalsOfCare.card.mechvent.yesIf.title'),
            text: t('common.goalsOfCare.card.mechvent.yesIf.text'),
            questionChoose: t('common.goalsOfCare.card.mechvent.yesIf.questionChoose'),
            questionCustom: t('common.goalsOfCare.card.mechvent.yesIf.questionCustom'),
            otherOptionId: answersOptions[CC_MECHVENT].otherOptionId,
            options: answersOptions[CC_MECHVENT].options,
          },
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: CC_FEEDINGTUBE,
          title: t('common.goalsOfCare.card.feedingtube.title'),
          text: t('common.goalsOfCare.card.feedingtube.text'),
          hint: getFeedingTubeHintData(t),
          hiddenOptions: [YES],
          yesIf: {
            title: t('common.goalsOfCare.card.feedingtube.yesIf.title'),
            text: t('common.goalsOfCare.card.feedingtube.yesIf.text'),
            otherOptionId: answersOptions[CC_FEEDINGTUBE].otherOptionId,
            options: answersOptions[CC_FEEDINGTUBE].options,
          },
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: CC_DIALYSIS,
          title: t('common.goalsOfCare.card.dialysis.title'),
          text: t('common.goalsOfCare.card.dialysis.text'),
          hint: getDialysisHintData(t),
          hiddenOptions: [YES],
          yesIf: {
            title: t('directive.well.page.FTGoalsOfCare.blocks.otherWishes.title'),
            text: t('directive.well.page.FTGoalsOfCare.blocks.otherWishes.text'),
            otherOptionId: answersOptions[CC_DIALYSIS].otherOptionId,
            options: answersOptions[CC_DIALYSIS].options,
          },
        },
      },
    ],
  }
}

export { CCSpecificWishesFirstPage, CC_SPECIFIC_WISHES_URL, CC_QIDS }
