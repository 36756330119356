import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswerByQid } from '../TheDirective'
import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import TextAnswer from '../blocks/TextAnswer'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { MyValuesPage } from './MyValuesPage'
import { FutureDecisionsPage } from './FutureDecisionsPage'
import { TextAnswerSummary } from '../blocks/summary/TextAnswerSummary'

const MEDICAL_DECISIONS_URL = 'medical-decisions'
const MEDICAL_DECISIONS_QID = 'mymedicaldecisions_textbox'

const MedicalDecisionsPage = (t) => ({
  slug: MEDICAL_DECISIONS_URL,
  getPrevStep: () => ({
    page: MyValuesPage(t),
    url: `${DIRECTIVE_URL}/${MyValuesPage(t).slug}`,
  }),
  getNextStep: () => ({
    page: FutureDecisionsPage(t),
    url: `${DIRECTIVE_URL}/${FutureDecisionsPage(t).slug}`,
  }),
  setInitialValues: (answers) => ({
    [MEDICAL_DECISIONS_QID]: getAnswerByQid(MEDICAL_DECISIONS_QID)(answers),
  }),
  serializeValues: (values) => ({
    variables: {
      qid: MEDICAL_DECISIONS_QID,
      val: values[MEDICAL_DECISIONS_QID],
    },
    update: updateCacheAnswers,
  }),
  validate: () => {},
  isRequired: true,
  isAnswered: (answers) => !!getAnswerByQid(MEDICAL_DECISIONS_QID)(answers),
  dashboard: {
    title: t('directive.well.page.medicalDecisions.dashboardTitle'),
  },
  summary: {
    title: t('directive.well.page.medicalDecisions.summaryTitle'),
    blocks: [
      {
        component: TextAnswerSummary,
        params: {
          id: MEDICAL_DECISIONS_QID,
          heading: t('directive.well.page.medicalDecisions.summaryHeading'),
        },
      },
    ],
  },
  blocks: [
    {
      component: PageTitle,
      params: {
        text: t('directive.well.page.medicalDecisions.blocks.pageTitle'),
      },
    },
    {
      component: PageText,
      params: {
        text: t('directive.well.page.medicalDecisions.blocks.pageText'),
      },
    },
    {
      component: TextAnswer,
      params: {
        id: MEDICAL_DECISIONS_QID,
      },
      hideBlockError: true,
    },
  ],
})

export { MedicalDecisionsPage, MEDICAL_DECISIONS_URL, MEDICAL_DECISIONS_QID }
