import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { MyValuesPage } from './MyValuesPage'
import { getAnswerByQid } from '../TheDirective'
import { updateCacheAnswers } from '../../common/cacheUtils'
import ChooseOne from '../blocks/ChooseOne'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { WELL_SUMMARY_TYPE } from '../../constants/productTypes'

const WELCOME_URL = 'welcome'
const WELCOME_QID = 'welcome'

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(WELL_SUMMARY_TYPE))

const WelcomePage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: WELCOME_URL,
    getPrevStep: () => ({
      page: null,
      url: null,
    }),
    getNextStep: () => ({
      page: MyValuesPage(t),
      url: `${DIRECTIVE_URL}/${MyValuesPage(t).slug}`,
    }),
    setInitialValues: (answers) => ({
      [WELCOME_QID]: getAnswerByQid(WELCOME_QID)(answers),
    }),
    serializeValues: (values) => ({
      variables: {
        qid: WELCOME_QID,
        val: values[WELCOME_QID],
      },
      update: updateCacheAnswers,
    }),
    validate: () => {},
    isRequired: true,
    isAnswered: (answers) => !!getAnswerByQid(WELCOME_QID)(answers),
    dashboard: null,
    summary: null,
    fullWidthColumn: true,
    // hideSaveButton: true,
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.well.page.welcome.blocks.welcomePageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.well.page.welcome.blocks.welcomePageText'),
        },
      },
      {
        component: PageTitle,
        params: {
          text: t('directive.well.page.welcome.blocks.gettingStartedPageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.well.page.welcome.blocks.gettingStartedPageText'),
        },
      },
      {
        component: ChooseOne,
        params: {
          id: WELCOME_QID,
          className: 'hide',
          options: answersOptions[WELCOME_QID].options,
          alternativeOption: true,
        },
      },
    ],
  }
}

export { WelcomePage }
