import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import MD from './Markdown'

import { ReactComponent as ArrowRight } from '../../assets/images/arrow-corner-right.svg'
import { ReactComponent as ArrowDown } from '../../assets/images/arrow-corner-small-down.svg'
import { ReactComponent as IconTick } from '../../assets/images/icon-tick.svg'
import { ReactComponent as IconPencil } from '../../assets/images/icon-pencil.svg'

import '../../styles/blocks/tile.scss'

const TileContent = ({
  title,
  subtitle,
  to,
  href,
  onClick,
  onTitleClick,
  expandable,
  expanded,
  success,
  editLink,
  children,
}) => (
  <>
    {(onClick || to || href) && !success && !expandable &&
      <ArrowRight className="tile_icon icon -arrow-corner-right" />
    }
    {(expandable) &&
      <ArrowDown className="tile_toggle-icon icon -arrow-corner-down" />
    }
    {success &&
      <IconTick className="tile_tick-icon icon -icon-tick" />
    }
    {editLink &&
      <Link to={editLink} className="tile_edit-link">
        <IconPencil className="tile_pencil-icon icon -icon-pencil" />
      </Link>
    }
    {title &&
      <h3
        className="tile_title"
        onClick={onTitleClick}
        role={onTitleClick ? 'button' : ''}
      >
        <MD>{title}</MD>
      </h3>
    }
    {subtitle &&
      <span className="tile_subtitle">
        <MD>{subtitle}</MD>
      </span>
    }
    {children && (!expandable || (expandable && expanded)) &&
      <div className="tile_content">
        {children}
      </div>
    }
  </>
)

const Tile = (props) => { 
  const { to, href, onClick, expandable, expanded, success, white, gray, className } = props

    return (
      <div
        onClick={onClick}
        className={cn(
          'tile',
          className,
          { '-expandable': expandable },
          { '-expanded': expanded },
          { '-success': success },
          { '-white': white },
          { '-gray': gray },
        )}
        role={onClick ? 'button' : ''}
      >
        {href ? (
          <a href={href} className="tile_link-inset">
            {TileContent(props)}
          </a>
        ) : to ? (
          <Link to={to} className="tile_link-inset">
            {TileContent(props)}
          </Link>
        ) : (
          <>
            {TileContent(props)}
          </>
        )}
      </div>
    )
}

export default Tile