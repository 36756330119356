import React from 'react'
import cn from 'classnames'
import Header from './Header'
import Footer from './Footer'
import Content from './Content'

import '../../styles/blocks/layout.scss'

const Layout = ({ showProgress, progress, className, headerClassName, headerAdditionalContent, children }) => (
  <div className={cn('layout', className)}>
    <Header
      showProgress={showProgress}
      className={headerClassName}
      progress={progress}
      additionalContent={headerAdditionalContent}
    />
    <Content>{children}</Content>
    <Footer />
  </div>
)

export default Layout
