import React from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import '../../styles/blocks/progress-bar.scss'

const ProgressBar = ({ progress, className }) => {
  const { t } = useTranslation()

  return (
    <div className={cn('progress-bar', className)}>
      <span className="progress-bar_text">{t('common.progressBar.text')}</span>
      <div className="progress-bar_bar">
        <div className="progress-bar_bar-fill" style={{ width: progress + '%' }} />
      </div>
    </div>
  )
}

export default ProgressBar
