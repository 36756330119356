import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import TooltipList from '../blocks/TooltipList'
import { getAnswerByQid } from '../TheDirective'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { GuidanceToMyProxyPage } from './GuidanceToMyProxyPage'
import { ExcludedPeoplePage } from './ExcludedPeoplePage'
import OtherPeopleControl from './OtherPeopleControl'
import { OtherPeopleSummary } from '../blocks/summary/OtherPeopleSummary'

const INVOLVED_PEOPLE_URL = 'involved-people'
const INVOLVED_PEOPLE_QID = 'decisionmaker'

const InvolvedPeoplePage = (t) => ({
  slug: INVOLVED_PEOPLE_URL,
  getPrevStep: () => ({
    page: GuidanceToMyProxyPage(t),
    url: `${DIRECTIVE_URL}/${GuidanceToMyProxyPage(t).slug}`,
  }),
  getNextStep: () => ({
    page: ExcludedPeoplePage(t),
    url: `${DIRECTIVE_URL}/${ExcludedPeoplePage(t).slug}`,
  }),
  setInitialValues: (answers) => ({
    [INVOLVED_PEOPLE_QID]: getAnswerByQid(INVOLVED_PEOPLE_QID)(answers) || [{}],
  }),
  serializeValues: (values) => {
    const people = values[INVOLVED_PEOPLE_QID]
    const hasNotEmptyValues = (obj) => Object.values(obj).length && Object.values(obj).every(Boolean)
    const val = people ? people.filter(hasNotEmptyValues) : []

    return {
      variables: { qid: INVOLVED_PEOPLE_QID, val },
      update: updateCacheAnswers,
    }
  },
  validate: () => {},
  isRequired: false,
  isAnswered: (answers) => {
    const people = getAnswerByQid(INVOLVED_PEOPLE_QID)(answers)
    return !!people && people.length > 0 && people.some((person) => Object.values(person).length)
  },
  dashboard: {
    title: t('directive.well.page.involvedPeople.dashboardTitle'),
  },
  summary: {
    title: t('directive.well.page.involvedPeople.summaryTitle'),
    blocks: [
      {
        component: OtherPeopleSummary,
        params: {
          id: INVOLVED_PEOPLE_QID,
        },
      },
    ],
  },
  blocks: [
    {
      component: PageTitle,
      params: {
        text: t('directive.well.page.involvedPeople.blocks.pageTitle'),
      },
    },
    {
      component: PageText,
      params: {
        text: t('directive.well.page.involvedPeople.blocks.pageText'),
      },
    },
    {
      component: OtherPeopleControl,
      params: {
        id: INVOLVED_PEOPLE_QID,
        withRole: true,
      },
      hideBlockError: true,
    },
    {
      component: TooltipList,
      params: {
        tooltips: [
          {
            title: t('directive.well.page.involvedPeople.blocks.tooltipList.tooltips.0.title'),
            reTitle: t('directive.well.page.involvedPeople.blocks.tooltipList.tooltips.0.reTitle'),
            text: t('directive.well.page.involvedPeople.blocks.tooltipList.tooltips.0.text'),
          },
        ],
      },
      columnRight: true,
    },
  ],
})

export { InvolvedPeoplePage, INVOLVED_PEOPLE_URL, INVOLVED_PEOPLE_QID }
