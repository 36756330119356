import React from 'react'
import cn from 'classnames'
import MD from './Markdown'

import '../../styles/blocks/page-intro.scss'

const PageIntro = ({ params: { text }, className }) => (
  <div className={cn('page-intro', className)}>
    <MD allowParagraphs>{text}</MD>
  </div>
)

export default PageIntro
