import React from 'react'
import { useTranslation } from 'react-i18next'
import { getOrganizationName, getLogoFileName, getHidePartnership } from '../../../common/organizationTheme'

import '../../../styles/blocks/landing/logo-partner.scss'

const organizationName = getOrganizationName()
const logoFileName = getLogoFileName()
const hidePartnership = getHidePartnership()

const LogoPartner = () => {
  const { t } = useTranslation()

  return (
    <>
      {(!hidePartnership || logoFileName) && (
        <div className="logo-partner">
          {!hidePartnership && (
            <div className="logo-partner_text">
              {t('landing.header.partner')} <strong>{organizationName}</strong>
            </div>
          )}
          {logoFileName && <img src={logoFileName} className="logo-partner_icon icon icon_logo-health-plan" />}
        </div>
      )}
    </>
  )
}

export default LogoPartner
