import { gql } from 'apollo-boost'

//  submitDirectiveAnswer(questionIdentifier: String!, value: JSON!): SubmitDirectiveAnswerPayload

export const SUBMIT_DIRECTIVE_ANSWER = gql`
  mutation SubmitDirectiveAnswer($qid: String!, $val: JSON!) {
    submitDirectiveAnswer(questionIdentifier: $qid, value: $val) {
      answer {
        id
        questionIdentifier
        value
      }
      errors
    }
  }
`

export const CLEAR_ANSWERS = gql`
  mutation clearAnswers($qids: [String!]!) {
    clearAnswers(questionIdentifiers: $qids) {
      answers {
        id
        questionIdentifier
        value
      }
      errors
    }
  }
`

export const UPDATE_PROXY = gql`
  mutation updateProxy(
    $id: Int!
    $additionalPhone: String
    $address: String
    $city: String
    $email: String
    $firstName: String!
    $isPrimary: Boolean
    $lastName: String!
    $phone: String
    $state: String
    $zip: String
  ) {
    updateProxy(
      attributes: {
        additionalPhone: $additionalPhone
        address: $address
        city: $city
        email: $email
        firstName: $firstName
        isPrimary: $isPrimary
        lastName: $lastName
        phone: $phone
        state: $state
        zip: $zip
      }
      id: $id
    ) {
      proxy {
        id
        additionalPhone
        address
        city
        email
        firstName
        isPrimary
        lastName
        phone
        state
        zip
      }
      errors
    }
  }
`

export const CREATE_PROXY = gql`
  mutation createProxy(
    $additionalPhone: String
    $address: String
    $city: String
    $email: String
    $firstName: String!
    $isPrimary: Boolean
    $lastName: String!
    $phone: String
    $state: String
    $zip: String
  ) {
    createProxy(
      attributes: {
        additionalPhone: $additionalPhone
        address: $address
        city: $city
        email: $email
        firstName: $firstName
        isPrimary: $isPrimary
        lastName: $lastName
        phone: $phone
        state: $state
        zip: $zip
      }
    ) {
      proxy {
        id
        additionalPhone
        address
        city
        email
        firstName
        isPrimary
        lastName
        phone
        state
        zip
      }
      errors
    }
  }
`

export const DELETE_PROXY = gql`
  mutation deleteProxy($id: Int!) {
    deleteProxy(id: $id) {
      proxy {
        id
      }
      errors
    }
  }
`

export const UPDATE_MY_PROFILE = gql`
  mutation updateMyProfile(
    $email: String!
    $firstName: String!
    $lastName: String!
    $residenceState: UsState!
    $dateOfBirth: ISO8601Date
  ) {
    updateMyProfile(
      attributes: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        residenceState: $residenceState
        dateOfBirth: $dateOfBirth
      }
    ) {
      profile {
        id
        email
        firstName
        lastName
        residenceState
        dateOfBirth
      }
      errors
    }
  }
`

export const CHANGE_LOCALE = gql`
  mutation changeLocale($locale: String!) {
    changeLocale(locale: $locale) {
      user {
        locale
      }
      errors
    }
  }
`

export const CREATE_OR_UPDATE_REMINDER = gql`
  mutation createOrUpdateReminder($annuallyAtMonth: Int, $annuallyAtDay: Int, $exactlyAt: ISO8601Date) {
    createOrUpdateReminder(annuallyAtMonth: $annuallyAtMonth, annuallyAtDay: $annuallyAtDay, exactlyAt: $exactlyAt) {
      reminder {
        id
        annuallyAtMonth
        annuallyAtDay
        exactlyAt
      }
      errors
    }
  }
`

export const SEND_SCHEDULE_ESIGN_EMAIL = gql`
  mutation sendScheduleEsignEmail($fullName: String!, $phoneNumber: String!, $email: String!, $state: String!) {
    sendScheduleEsignEmail(fullName: $fullName, phoneNumber: $phoneNumber, email: $email, state: $state) {
      errors
    }
  }
`

export const SHARE_WITH_PROXY = gql`
  mutation shareWithProxy($email: String!, $proxyId: Int!, $locale: String!, $fileName: String!, $documentId: Int!) {
    shareWithProxy(email: $email, proxyId: $proxyId, locale: $locale, fileName: $fileName, documentId: $documentId) {
      result {
        createdAt
        email
        fullName
        id
        updatedAt
        fileName
      }
      errors
    }
  }
`

export const SHARE_WITH_DOCTOR = gql`
  mutation shareWithDoctor(
    $dateOfBirth: ISO8601Date!
    $email: String!
    $fullName: String!
    $locale: String!
    $fileName: String!
    $documentId: Int!
  ) {
    shareWithDoctor(dateOfBirth: $dateOfBirth, email: $email, fullName: $fullName, locale: $locale, fileName: $fileName, documentId: $documentId){
      result {
        createdAt
        email
        fullName
        id
        updatedAt
        fileName
      }
      errors
    }
  }
`

export const SHARE_WITH_ORGANIZATION = gql`
  mutation shareWithOrganization($memberId: String!, $state: UsState!) {
    shareWithOrganization(memberId: $memberId, state: $state) {
      result {
        createdAt
        id
      }
      errors
    }
  }
`

export const SHARE_WITH_OTHER = gql`
  mutation shareWithOther($email: String!, $fullName: String!, $locale: String!, $fileName: String!, $documentId: Int!) {
    shareWithOther(email: $email, fullName: $fullName, locale: $locale, fileName: $fileName, documentId: $documentId) {
      result {
        createdAt
        email
        fullName
        id
        updatedAt
        fileName
      }
      errors
    }
  }
`

export const SHARE_WITH_AD_VAULT = gql`
  mutation shareWithAdVault(
    $dateOfBirth: ISO8601Date!
  ) {
    shareWithAdVault(dateOfBirth: $dateOfBirth) {
      result {
        createdAt
        shareType
        id
        updatedAt
        status
      }
      errors
    }
  }
`
export const REMOVE_FROM_AD_VAULT = gql`
  mutation removeFromAdVault {
    removeFromAdVault {
      result {
        createdAt
        shareType
        id
        updatedAt
        status
      }
      errors
    }
  }
`

export const CREATE_DIRECT_UPLOAD = gql`
  mutation createDirectUpload($byteSize: Int!, $checksum: String!, $contentType: String!, $filename: String!, $metadata: MetadataUploadInput) {
    createDirectUpload(
      input: { byteSize: $byteSize, checksum: $checksum, contentType: $contentType, filename: $filename, metadata: $metadata }
    ) {
      directUpload {
        blobId
        headers
        signedBlobId
        url
        key
      }
    }
  }
`

export const ATTACH_SIGNATURE_TO_USER = gql`
  mutation attachSignatureToUser($blobId: String!) {
    attachSignatureToUser(blobId: $blobId) {
      user {
        id
        email
        firstName
        lastName
        residenceState
        signatureUrl
      }
      errors
    }
  }
`

export const CREATE_OR_UPDATE_USER_DOCUMENT = gql`
mutation createOrUpdateUserDocument($name: String, $blobId: String, $dType: String, $documentId: String, $executed: Boolean, $isPdf: Boolean!) {
  createOrUpdateUserDocument(name: $name, blobId: $blobId, dType: $dType, documentId: $documentId, executed: $executed, isPdf: $isPdf) {
    errors
  }
}
`

export const CREATE_AND_UPLOAD_PDF_BASED_ON_ANSWERS = gql`
mutation createAndUploadPdfBasedOnAnswers {
  createAndUploadPdfBasedOnAnswers {
    downloadURL
    errors
  }
}
`

export const CHANGE_OWN_PASSWORD = gql`
  mutation changeOwnPassword($password: String!, $passwordConfirmation: String!) {
    changeOwnPassword(password: $password, passwordConfirmation: $passwordConfirmation) {
      errors
      me {
        id
        requiresPasswordChange
      }
    }
  }
`

export const UPDATE_ACTON_DOWNLOAD_DOCUMENT = gql`
  mutation updateActonDownloadDocument($dType: String!) {
    updateActonDownloadDocument(dType: $dType) {
      errors
    }
  }
`

export const UPDATE_ACTON_EXECUTE_DIRECTIVE = gql`
  mutation updateActonExecuteDirective {
    updateActonExecuteDirective {
      errors
    }
  }
`
