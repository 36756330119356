import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import ChooseMultiple from '../blocks/ChooseMultiple'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { getAnswerByQid } from '../TheDirective'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { WELL_SUMMARY_TYPE } from '../../constants/productTypes'
import { FTGoalsOfCarePage } from './FTGoalsOfCarePage'
import { FTSelectiveMeasuresPage } from './FTSelectiveMeasuresWishesPage'
import { ValuesSummary } from '../blocks/summary/ValuesSummary'

const FT_CHANGES_URL = 'ft-changed'
const FT_CHANGES_QID = 'ft_changed'

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(WELL_SUMMARY_TYPE))

const FTChangesPage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: FT_CHANGES_URL,
    getPrevStep: () => ({
      page: FTGoalsOfCarePage(t),
      url: `${DIRECTIVE_URL}/${FTGoalsOfCarePage(t).slug}`,
    }),
    getNextStep: () => ({
      page: FTSelectiveMeasuresPage(t),
      url: `${DIRECTIVE_URL}/${FTSelectiveMeasuresPage(t).slug}`,
    }),
    setInitialValues: (answers) => ({
      [FT_CHANGES_QID]: getAnswerByQid(FT_CHANGES_QID)(answers),
    }),
    serializeValues: (values) => {
      return {
        variables: {
          qid: FT_CHANGES_QID,
          val: values[FT_CHANGES_QID],
        },
        update: updateCacheAnswers,
      }
    },
    otherOptionId: answersOptions[FT_CHANGES_QID].otherOptionId,
    validate: () => {},
    isRequired: false,
    isAnswered: (answers) => {
      const values = getAnswerByQid(FT_CHANGES_QID)(answers)
      return !!values && Object.values(values).some(Boolean)
    },
    dashboard: {
      title: t('directive.well.page.FTChanges.dashboardTitle'),
    },
    summary: {
      title: t('directive.well.page.FTChanges.summaryTitle'),
      blocks: [
        {
          component: ValuesSummary,
          params: {
            id: FT_CHANGES_QID,
            options: answersOptions[FT_CHANGES_QID].options,
            otherOptionId: answersOptions[FT_CHANGES_QID].otherOptionId,
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.well.page.FTChanges.blocks.pageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.well.page.FTChanges.blocks.pageText'),
        },
      },
      {
        component: ChooseMultiple,
        params: {
          optional: false,
          id: FT_CHANGES_QID,
          options: answersOptions[FT_CHANGES_QID].options,
          otherOptionId: answersOptions[FT_CHANGES_QID].otherOptionId,
          singleOptionId: 'never',
          alternativeOption: true,
          alternativeText: t('directive.well.page.FTChanges.blocks.chooseMultiple.alternativeText'),
          modalTitle: t('directive.well.page.FTChanges.blocks.chooseMultiple.modalTitle'),
          modalText: t('directive.well.page.FTChanges.blocks.chooseMultiple.modalText'),
        },
      },
    ],
  }
}

export { FTChangesPage, FT_CHANGES_URL, FT_CHANGES_QID }
