import React from 'react'
import cn from 'classnames'
import Select from 'react-select'
import FormControl from './FormControl'

import '../../styles/blocks/react-select.scss'
import { useTranslation } from 'react-i18next'

const FormSelect = ({ label, placeholder, /*  type, */ input, meta, white, noMargin, options /* onChange */ }) => {
  const { t } = useTranslation()

  const invalid = (meta && meta.touched && meta.error) || (meta && !!meta.submitError)
  const validationResult = invalid ? meta.error || meta.submitError : undefined
  const error = typeof validationResult === 'function' ? validationResult(t) : validationResult

  return (
    <FormControl
      className={cn('form-input', { '-white': white })}
      label={label}
      noMargin={noMargin}
      invalid={invalid}
      error={error}
    >
      <Select
        className="react-select"
        classNamePrefix="react-select"
        placeholder={placeholder}
        options={options}
        {...input}
      />
    </FormControl>
  )
}

export default FormSelect
