import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEN from './locales/en/translations.json'
import translationES from './locales/es/translations.json'
import { LOCALES } from './constants/locales'

const resources = {
  [LOCALES.en]: {
    translation: translationEN,
  },
  [LOCALES.es]: {
    translation: translationES,
  },
}

const initialValue = localStorage.getItem('lang') || LOCALES.en

i18n.use(initReactI18next).init({
  resources,
  lng: initialValue,
  fallbackLng: LOCALES.en,
  keySeparator: false,
})

export default i18n
