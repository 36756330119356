import React from 'react'
import cn from 'classnames'

import '../../../styles/blocks/landing/landing-column-left.scss'

const LandingColumnLeft = ({ className, children }) => (
  <div className={cn('landing-column-left', className)}>
    {children}
  </div>
)

export default LandingColumnLeft
