import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import MD from './Markdown'
import Modal from './Modal'
import { Field } from 'react-final-form'
import SelectorCheckbox from './SelectorCheckbox'
import FormTextarea from './FormTextarea'
import Button from './Button'

import '../../styles/blocks/choose-multiple.scss'

const CheckboxGroup = ({ form, options, singleOptionId, name }) => {
  const onChange = ({ target: { checked } }, optionId) => {
    if (!singleOptionId) {
      form.mutators.setValue(`${name}[${optionId}]`, checked)
      return
    }

    if (optionId === singleOptionId) {
      form.mutators.setValue(`${name}`, {})
      form.mutators.setValue(`${name}[${singleOptionId}]`, checked)
    } else {
      form.mutators.setValue(`${name}[${optionId}]`, checked)
      form.mutators.setValue(`${name}[${singleOptionId}]`, false)
    }
  }

  return (
    <>
      {options.map((option, index) => (
        <Field type="checkbox" name={`[${name}]${option.id}`} key={index}>
          {({ input, meta, hint }) => (
            <SelectorCheckbox
              name={option.title}
              label={option.title}
              input={{ ...input, onChange: (event) => onChange(event, option.id) }}
              meta={meta}
              hint={hint}
            />
          )}
        </Field>
      ))}
    </>
  )
}

const ChooseMultiple = (props) => {
  const { t } = useTranslation()
  const [showEditTextOption, setShowEditTextOption] = useState(undefined)
  const [textAnswerDraft, setTextAnswerDraft] = useState('')

  const { params, values, form } = props
  const {
    id,
    options,
    alternativeOption,
    alternativeText,
    modalTitle,
    modalText,
    otherOptionId,
    singleOptionId,
  } = params

  const textAnswer = values[id] && values[id][otherOptionId]

  const setTextAnswer = (value) => {
    form.mutators.setValue(`${id}[${otherOptionId}]`, value)
  }

  useEffect(() => {
    setTextAnswerDraft(textAnswer)
  }, [textAnswer])

  const onShowTextOption = () => {
    setShowEditTextOption(true)
  }

  const onHideTextAnswer = () => {
    setShowEditTextOption(false)
  }

  const clearTextValue = () => {
    setTextAnswer(undefined)
    setTextAnswerDraft('')
  }

  const handleChangeTextOption = (event) => {
    setTextAnswerDraft(event.target.value)
  }

  const onSubmitTextAnswer = () => {
    setShowEditTextOption(false)
    setTextAnswer(textAnswerDraft)
    onHideTextAnswer()
  }

  return (
    <div className={cn('choose-multiple', props.className)}>
      <CheckboxGroup form={form} name={`${id}`} options={options} singleOptionId={singleOptionId} />
      {textAnswer && (
        <>
          <SelectorCheckbox
            label={textAnswer}
            onChange={() => null}
            onCheckboxClick={clearTextValue}
            onTextClick={onShowTextOption}
            input={{ value: true, checked: true, type: 'checkbox' }}
          />
          <Field name={`[${id}]textValue`} type="hidden">
            {({ input }) => <input {...input} value={textAnswer} />}
          </Field>
        </>
      )}
      {alternativeOption && !textAnswer && (
        <div className="choose-multiple_custom">
          <div className="choose-multiple_custom-toggle" onClick={onShowTextOption} role="button">
            <span className="choose-multiple_custom-icon">+</span>
            <span className="choose-multiple_custom-text">
              {alternativeText || t('common.chooseMultiple.alternativeVariantText')}
            </span>
          </div>
        </div>
      )}

      <Modal
        title={modalTitle || t('common.chooseMultiple.modalTitle')}
        isOpen={showEditTextOption}
        onRequestClose={onHideTextAnswer}
      >
        <div className="modal_text">
          <MD>{modalText || t('common.chooseMultiple.modalText')}</MD>
          <div className="yes-if-no-option_yi-custom">
            <FormTextarea value={textAnswerDraft} onChange={handleChangeTextOption} />
            <Button className="yes-if-no-option_yi-custom-button -wide" type="button" onClick={onSubmitTextAnswer}>
              {t('common.chooseMultiple.modalSubmitButton')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ChooseMultiple
