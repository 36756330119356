import React from 'react'
import { useTranslation } from 'react-i18next'
import ReasonTile from './ReasonTile'

import '../../../styles/blocks/landing/reason-tiles.scss'

const fields = [
  {
    title: 'landing.reasons.tiles.item1',
  },
  {
    title: 'landing.reasons.tiles.item2',
  },
  {
    title: 'landing.reasons.tiles.item3',
  },
  {
    title: 'landing.reasons.tiles.item4',
  },
  {
    title: 'landing.reasons.tiles.item5',
  },
]

const ReasonTiles = () => {
  const { t } = useTranslation()

  return (
    <div className="reason-tiles">
      {fields.map((item, index) => (
        <ReasonTile title={`* ${t(item.title)}`} index={index} key={index} expandable={false} />
      ))}
    </div>
  )
}

export default ReasonTiles
