import React from 'react'
import cn from 'classnames'
import SelectorRadio from './SelectorRadio'

const ChooseOneFancy = ({ params: { id, options, onChange }, className }) => (
  <div className={cn('choose-one-fancy', className)}>
    {options.map((question, index) => (
      <SelectorRadio
        key={index}
        name={id}
        value={question.id.toString()}
        label={question.text}
        onChange={onChange}
        fancy
      />
    ))}
  </div>
)

export default ChooseOneFancy
