import { useState, useEffect } from 'react'
const BREAK_MOBILE = 768
const BREAK_PHONE = 375

export default () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return {
    width,
    isPhoneViewport: width <= BREAK_PHONE,
    isMobileViewport: width <= BREAK_MOBILE,
  }
}
