import { find } from 'ramda'
import { DIRECTIVE_URL, DASHBOARD_URL, PASSWORD_CHANGE_URL } from '../constants/UrlConstants'
import { getDirectiveSteps } from '../directive/TheDirective'

export const getInnerPageRedirectUrl = (user) => {
  if (!user) {
    return '/'
  }

  const { answers, proxies, profile, productType, requiresPasswordChange } = user

  if (requiresPasswordChange) {
    return PASSWORD_CHANGE_URL
  }

  const steps = getDirectiveSteps(productType, answers)()
  const { slug } = find((page) => page.isRequired && !page.isAnswered(answers, proxies, profile), steps) || {}
  return slug ? `${DIRECTIVE_URL}/${slug}` : DASHBOARD_URL
}
