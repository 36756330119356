import React from 'react'
import cn from 'classnames'

import '../../styles/blocks/column-left.scss'

const ColumnLeft = ({ className, children }) => (
  <div className={cn('column-left', className)}>
    {children}
  </div>
)

export default ColumnLeft
