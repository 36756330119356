import React from 'react'
import cn from 'classnames'

import '../../styles/blocks/page-separator.scss'

const PageSeparator = ({ noMargin, className }) => (
  <hr
    className={cn(
      'page-separator',
      className,
      { '-no-margin': noMargin },
    )}
  />
)

export default PageSeparator
