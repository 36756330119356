import React from 'react'
import cn from 'classnames'
import Hint from './Hint'

import '../../styles/blocks/add-entity.scss'

const AddEntity = ({ disabled, className, children, onClick, hint }) => (
  <div className={cn('add-entity', className)}>
    <span
      className={cn(
        'add-entity_button',

        { '-disabled': disabled },
      )}
      onClick={onClick}
      role="button"
    >
      {children}
    </span>
    {hint && (
      <div className="add-entity_hint">
        <Hint title={hint.title} text={hint.text} />
      </div>
    )}
  </div>
)

export default AddEntity
