import React from 'react'
import cn from 'classnames'
import MD from './Markdown'
import Modal from './Modal'

import '../../styles/blocks/hint.scss'

const getModalButton = () => (
  <span className="hint_toggle" role="button">
    <span className="hint_toggle-text">?</span>
  </span>
)

const Hint = ({ title, contents, text, className }) => (
  <div className={cn('hint', className)}>
    <Modal
      title={title}
      button={getModalButton()}
    >
      {contents &&
        <div className="modal_contents">
          <MD>{contents}</MD>
        </div>
      }
      <div className="modal_text">
        <MD allowParagraphs>{text}</MD>
      </div>
    </Modal>
  </div>
)

export default Hint
