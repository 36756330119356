import React from 'react'
import cn from 'classnames'
import Select from 'react-select'

const LangSwitch = ({ value, options, onChange, isCompact = true }) => {
  return (
    <Select
      value={value}
      className={cn('react-select', isCompact && 'react-select_compact')}
      classNamePrefix="react-select"
      options={options}
      isSearchable={false}
      onChange={onChange}
    />
  )
}

export default LangSwitch
