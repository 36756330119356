export const getCprHintData = (t) => ({
  title: t('common.goalsOfCare.hint.cpr.title'),
  contents: `\n- [${t('common.goalsOfCare.hint.cpr.whatIsCpr.title')}](#what-is-cpr)
      \n- [${t('common.goalsOfCare.hint.cpr.whatAreBenefits.title')}](#what-are-benefits)
      \n- [${t('common.goalsOfCare.hint.cpr.whatAreDangers.title')}](#what-are-dangers)
      \n- [${t('common.goalsOfCare.hint.cpr.whatWillHappen.title')}](#what-will-happen)\n\n`,
  text:
    '### <a name="what-is-cpr"></a>' +
    t('common.goalsOfCare.hint.cpr.whatIsCpr.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.cpr.whatIsCpr.text') +
    '\n\n' +
    '### <a name="what-are-benefits"></a>' +
    t('common.goalsOfCare.hint.cpr.whatAreBenefits.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.cpr.whatAreBenefits.text') +
    '\n\n' +
    '### <a name="what-are-dangers"></a>' +
    t('common.goalsOfCare.hint.cpr.whatAreDangers.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.cpr.whatAreDangers.text') +
    '\n\n' +
    '### <a name="what-will-happen"></a>' +
    t('common.goalsOfCare.hint.cpr.whatWillHappen.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.cpr.whatWillHappen.text'),
})

export const getMechventHintData = (t) => ({
  title: t('common.goalsOfCare.hint.mechvent.title'),
  contents: `\n- [${t('common.goalsOfCare.hint.mechvent.whatIs.title')}](#what-is-mechanical-ventilation)
      \n- [${t('common.goalsOfCare.hint.mechvent.whatAreBenefits.title')}](#what-are-the-benefits-of-ventilation)
      \n- [${t(
        'common.goalsOfCare.hint.mechvent.whatAreDisadvantages.title',
      )}](#what-are-the-disadvantages-or-risks-of-ventilation)
      \n- [${t('common.goalsOfCare.hint.mechvent.chooseNotToHave.title')}](#what-if-i-choose-not-to-have-ventilation)
      \n- [${t('common.goalsOfCare.hint.mechvent.allOrNothing.title')}](#is-ventilation-an-all-or-nothing-decision)`,
  text:
    '### <a name="what-is mechanical ventilation"></a>' +
    t('common.goalsOfCare.hint.mechvent.whatIs.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.mechvent.whatIs.text') +
    '\n\n' +
    '### <a name="what-are-the-benefits-of-ventilation"></a>' +
    t('common.goalsOfCare.hint.mechvent.whatAreBenefits.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.mechvent.whatAreBenefits.text') +
    '\n\n' +
    '### <a name="what-are-the-disadvantages-or-risks-of-ventilation"></a>' +
    t('common.goalsOfCare.hint.mechvent.whatAreDisadvantages.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.mechvent.whatAreDisadvantages.text') +
    '\n\n' +
    '### <a name="what-if-i-choose-not-to-have-ventilation"></a>' +
    t('common.goalsOfCare.hint.mechvent.chooseNotToHave.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.mechvent.chooseNotToHave.text') +
    '\n\n' +
    '### <a name="is-ventilation-an-all-or-nothing-decision"></a>' +
    t('common.goalsOfCare.hint.mechvent.allOrNothing.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.mechvent.allOrNothing.text'),
})

export const getFeedingTubeHintData = (t) => ({
  title: t('common.goalsOfCare.hint.feedingtube.title'),
  contents: `\n- [${t('common.goalsOfCare.hint.feedingtube.whatIs.title')}](#what-is-artificial-nutrition-and-hydration)
      \n- [${t('common.goalsOfCare.hint.feedingtube.benefits.title')}](#what-are-the-benefits-of-feeding-tubes)
      \n- [${t(
        'common.goalsOfCare.hint.feedingtube.disadvantages.title',
      )}](#what-are-the-disadvantages-or-risks-of-feeding-tubes)
      \n- [${t('common.goalsOfCare.hint.feedingtube.likeToBe.title')}](#what-is-it-like-to-be-on-a-feeding-tube)
      \n- [${t(
        'common.goalsOfCare.hint.feedingtube.chooseNotToHave.title',
      )}](#what-if-i-choose-not-to-have-a-feeding-tube)
      \n- [${t(
        'common.goalsOfCare.hint.feedingtube.allOrNothing.title',
      )}](#is-a-feeding-tube-an-all-or-nothing-decision)`,
  text:
    '### <a name="what-is-artificial-nutrition-and-hydration"></a>' +
    t('common.goalsOfCare.hint.feedingtube.whatIs.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.feedingtube.whatIs.text') +
    '\n\n' +
    '### <a name="what-are-the-benefits-of-feeding-tubes"></a>' +
    t('common.goalsOfCare.hint.feedingtube.benefits.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.feedingtube.benefits.text') +
    '\n\n' +
    '### <a name="what-are-the-disadvantages-or-risks-of-feeding-tubes"></a>' +
    t('common.goalsOfCare.hint.feedingtube.disadvantages.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.feedingtube.disadvantages.text') +
    '\n\n' +
    '### <a name="what-is-it-like-to-be-on-a-feeding-tube"></a>' +
    t('common.goalsOfCare.hint.feedingtube.likeToBe.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.feedingtube.likeToBe.text') +
    '\n\n' +
    '### <a name="what-if-i-choose-not-to-have-a-feeding-tube"></a>' +
    t('common.goalsOfCare.hint.feedingtube.chooseNotToHave.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.feedingtube.chooseNotToHave.text') +
    '\n\n' +
    '### <a name="is-a-feeding-tube-an-all-or-nothing-decision"></a>' +
    t('common.goalsOfCare.hint.feedingtube.allOrNothing.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.feedingtube.allOrNothing.text'),
})

export const getDialysisHintData = (t) => ({
  title: t('common.goalsOfCare.hint.dialysis.title'),
  contents: `\n- [${t('common.goalsOfCare.hint.dialysis.whatIs.title')}](#what-is-dialysis)
      \n- [${t('common.goalsOfCare.hint.dialysis.kinds.title')}](#what-are-the-different-kinds-of-dialysis)
      \n- [${t('common.goalsOfCare.hint.dialysis.benefits.title')}](#what-are-the-benefits-of-dialysis)
      \n- [${t(
        'common.goalsOfCare.hint.dialysis.disadvantages.title',
      )}](#what-are-the-disadvantages-or-risks-of-dialysis)
      \n- [${t('common.goalsOfCare.hint.dialysis.chooseNotToHave.title')}](#what-if-i-choose-not-to-have-dialysis)
      \n- [${t('common.goalsOfCare.hint.dialysis.kidneyTransplant.title')}](#what-about-a-kidney-transplant)`,
  text:
    '### <a name="what-is-dialysis"></a>' +
    t('common.goalsOfCare.hint.dialysis.whatIs.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.dialysis.whatIs.text') +
    '\n\n' +
    '### <a name="what-are-the-different-kinds-of-dialysis"></a>' +
    t('common.goalsOfCare.hint.dialysis.kinds.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.dialysis.kinds.text') +
    '\n\n' +
    '### <a name="what-are-the-benefits-of-dialysis"></a>' +
    t('common.goalsOfCare.hint.dialysis.benefits.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.dialysis.benefits.text') +
    '\n\n' +
    '### <a name="what-are-the-disadvantages-or-risks-of-dialysis"></a>' +
    t('common.goalsOfCare.hint.dialysis.disadvantages.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.dialysis.disadvantages.text') +
    '\n\n' +
    '### <a name="what-if-i-choose-not-to-have-dialysis"></a>' +
    t('common.goalsOfCare.hint.dialysis.chooseNotToHave.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.dialysis.chooseNotToHave.text') +
    '\n\n' +
    '### <a name="what-about-a-kidney-transplant"></a>' +
    t('common.goalsOfCare.hint.dialysis.kidneyTransplant.title') +
    '\n\n' +
    t('common.goalsOfCare.hint.dialysis.kidneyTransplant.text'),
})
