import React from 'react'
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import ApolloClient from 'apollo-boost'
import '../application/styles/scaffolding.scss'
import smoothscroll from 'smoothscroll-polyfill'
import Landing from './landing/Landing'
import ScrollToTop from '../application/inputs/ScrollToTop'
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '../application/constants/UrlConstants'
import TermsOfService from '../application/termsOfService/TermsOfService'
import PrivacyPolicy from '../application/privacyPolicy/PrivacyPolicy'
import Documents from '../application/documents/documents'

smoothscroll.polyfill()

const LandingApp = () => {
  const client = new ApolloClient()

  return (
    <Router>
      <ApolloProvider client={client}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path={TERMS_OF_SERVICE_URL} component={TermsOfService} />
          <Route exact path={PRIVACY_POLICY_URL} component={PrivacyPolicy} />
          <Route exact path="/documents" component={Documents} />
          <Redirect to="/" />
        </Switch>
      </ApolloProvider>
    </Router>
  )
}

export default LandingApp
