import React from 'react'
import cn from 'classnames'

import '../../styles/blocks/container.scss'

const Container = ({ className, keyProp, children }) => (
  <div key={keyProp} className={cn('container', className)} >
    {children}
  </div>
)


export default Container
