import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import QuestionText from '../blocks/QuestionText'
import TooltipList from '../blocks/TooltipList'
import ChooseMultiple from '../blocks/ChooseMultiple'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { multipleChoices } from '../../inputs/validators'
import { getAnswerByQid } from '../TheDirective'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { BASE_TYPE } from '../../constants/productTypes'
import { ProxyPage } from './ProxyPage'
import { GoalsOfCarePage } from './GoalsOfCarePage'
import { ValuesSummary } from '../blocks/summary/ValuesSummary'

const MY_VALUES_URL = 'my-values'
const MY_VALUES_QID = 'my_values'

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(BASE_TYPE))

const MyValuesPage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: MY_VALUES_URL,
    getPrevStep: () => ({
      page: ProxyPage(t),
      url: `${DIRECTIVE_URL}/${ProxyPage(t).slug}`,
    }),
    getNextStep: () => ({
      page: GoalsOfCarePage(t),
      url: `${DIRECTIVE_URL}/${GoalsOfCarePage(t).slug}`,
    }),
    setInitialValues: (answers) => ({
      [MY_VALUES_QID]: getAnswerByQid(MY_VALUES_QID)(answers),
    }),
    serializeValues: (values) => {
      return {
        variables: {
          qid: MY_VALUES_QID,
          val: values[MY_VALUES_QID],
        },
        update: updateCacheAnswers,
      }
    },
    otherOptionId: answersOptions[MY_VALUES_QID].otherOptionId,
    validate: (values) => {
      let errors = multipleChoices(values, MY_VALUES_QID, 1)
      const questionAnswers = values[MY_VALUES_QID]

      if (questionAnswers) {
        const length = Object.entries(values[MY_VALUES_QID]).length

        if (length < 1) {
          errors = { ...errors, formError: t('directive.base.page.myValues.validate.formError') }
        }
      } else {
        errors = { ...errors, formError: t('directive.base.page.myValues.validate.formError') }
      }

      return errors
    },
    isRequired: true,
    isAnswered: (answers) => !!getAnswerByQid(MY_VALUES_QID)(answers),
    dashboard: {
      title: t('directive.base.page.myValues.dashboardTitle'),
    },
    summary: {
      title: t('directive.base.page.myValues.summaryTitle'),
      blocks: [
        {
          component: ValuesSummary,
          params: {
            id: MY_VALUES_QID,
            options: answersOptions[MY_VALUES_QID].options,
            otherOptionId: answersOptions[MY_VALUES_QID].otherOptionId,
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.base.page.myValues.blocks.pageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.base.page.myValues.blocks.pageText'),
        },
      },
      {
        component: QuestionText,
        params: {
          heading: t('directive.base.page.myValues.blocks.questionText'),
        },
      },
      {
        component: TooltipList,
        params: {
          tooltips: [
            {
              title: t('directive.base.page.myValues.blocks.tooltipList.tooltips.0.title'),
              reTitle: t('directive.base.page.myValues.blocks.tooltipList.tooltips.0.reTitle'),
              text: t('directive.base.page.myValues.blocks.tooltipList.tooltips.0.text'),
            },
          ],
        },
        columnRight: true,
      },
      {
        component: ChooseMultiple,
        params: {
          optional: false,
          id: MY_VALUES_QID,
          options: answersOptions[MY_VALUES_QID].options,
          otherOptionId: answersOptions[MY_VALUES_QID].otherOptionId,
          alternativeOption: true,
        },
      },
    ],
  }
}

export { MyValuesPage, MY_VALUES_URL, MY_VALUES_QID }
