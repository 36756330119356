import React from 'react'
import cn from 'classnames'

import { ReactComponent as Tick } from '../../assets/images/icon-tick.svg'

import '../../styles/blocks/selector-checkbox.scss'

const SelectorCheckbox = ({ name, label, input, meta, onChange, onCheckboxClick, onTextClick, className }) => (
  <label className={cn(className, 'selector-checkbox')}>
    <input
      className="selector-checkbox_input"
      name={name}
      {...input}
      onChange={!meta && onChange ? onChange : input.onChange}
    />
    <span className="selector-checkbox_ui">
      <span className="selector-checkbox_checker" onClick={onCheckboxClick}>
        <span className="selector-checkbox_cg">
          <Tick className={cn('selector-checkbox_tick icon -icon-tick', { '-invalid': meta && meta.invalid })} />
        </span>
      </span>
      <span className="selector-checkbox_text" onClick={onTextClick}>
        {label}
      </span>
    </span>
  </label>
)

export default SelectorCheckbox
