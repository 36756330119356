import React from 'react'
import { Field } from 'react-final-form'
import FormTextarea from './FormTextarea'
import PageText from './PageText'
import Tile from './Tile'

import '../../styles/blocks/text-answer.scss'

const defaultParse = (value) => value
const identityParse = (value) => (value === undefined ? '' : value)

const TextAnswer = ({ params }) => {
  const { id, tileWrapped, notRemoveEmptyField } = params
  const parse = notRemoveEmptyField ? identityParse : defaultParse

  return tileWrapped ? (
    <Tile className="text-answer -tile" title={params.title}>
      <PageText params={params} />
      <Field name={id} parse={parse}>
        {({ input, meta }) => <FormTextarea input={input} meta={meta} noMargin white />}
      </Field>
    </Tile>
  ) : (
    <Field name={id} parse={parse}>
      {({ input, meta }) => <FormTextarea input={input} meta={meta} />}
    </Field>
  )
}

export default TextAnswer
