import { AsYouType } from 'libphonenumber-js'

export const capitalize = value =>
  !value
    ? '' // make controlled
    : value
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')

export const trim = value =>
  !value
    ? ''
    : value.trim(value) + (value[value.length - 1] === ' ' ? ' ' : '')

export const formatZip = value => {
  if (!value) {
    return null
  }

  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums.length === 5) {
    return onlyNums
  }

  if (onlyNums.length > 5) {
    return onlyNums.slice(0, 5) + '-' + onlyNums.slice(5, 9)
  }

  return onlyNums

}


export const formatPhoneNumber = value => {
  if (!value) return ''
  const ayt = value[0] === '+' ? new AsYouType() : new AsYouType('US')
  return ayt.input(value)
}


const applyChain = (value, ...fns) => {
  const [fn, ...rest] = fns
  return fn
    ? applyChain(fn(value), ...rest)
    : value
}

export const chain = (...fns) => (value) => applyChain(value, ...fns)