import React from 'react'
import { find, propEq } from 'ramda'
import QuestionText from '../QuestionText'

const removeHeading = (text) => text.replace(/### .*\n\n/, '')

export const GoalsOfCareSummary = ({ params: { id, options }, answers }) => {
  const answerOption = find(propEq('id', answers[id]), options)

  if (!answerOption) {
    return null
  }

  return (
    <QuestionText
      params={{
        heading: answerOption.title,
        text: removeHeading(answerOption.text),
      }}
    />
  )
}
