import { LOCALES } from '../constants/locales'

const textEN = `
### Is there another document you'd like to store?

Follow the same steps to upload it.


### Would you like to share your document?

You can send your document to anyone you choose. Just click the Share button under the document.


### Not ready to share?

Click My Dashboard to set a reminder to come back when it's time to review your advance directive.

`

const textES = `
### ¿Hay algún otro documento que le gustaría almacenar?

Siga los mismos pasos para subirlo.


### ¿Le gustaría compartir su documento?

Puede enviar su documento a cualquier persona que elija. Simplemente haga clic en el botón Compartir debajo del documento.


### ¿No está listo/a para compartir?

Haga clic en Mi Panel de Control para establecer un recordatorio para volver cuando sea el momento de revisar sus directiva anticipada.
`

export const WhatNowPopupTextByLocale = (locale) => {
  switch (locale) {
    case LOCALES.en:
      return textEN
    case LOCALES.es:
      return textES
    default:
      return textEN
  }
}
