import React, { useState, useEffect, useContext } from 'react'
import { useMutation } from '@apollo/react-hooks'
import ChooseOneFancy from '../blocks/ChooseOneFancy'
import Modal from '../blocks/Modal'
import ButtonGroup from '../blocks/ButtonGroup'
import Button from '../blocks/Button'
import { CLEAR_ANSWERS } from '../../common/mutations'
import { goalsOfCareQids } from './WellSummaryDirective'
import { UserContext } from '../../hoc'
import { clearCacheAnswers } from '../../common/cacheUtils'
import { useTranslation } from 'react-i18next'

const GoalsOfCareControl = (props) => {
  const { t } = useTranslation()

  const { params, values, form } = props
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [previousValue, setPreviousValue] = useState('')
  const [choosedValue, setChoosedValue] = useState('')
  const { answers } = useContext(UserContext)
  const [clearAnswers] = useMutation(CLEAR_ANSWERS)

  const goalsOfCareAnswers = answers.filter(
    ({ questionIdentifier, value }) => goalsOfCareQids.includes(questionIdentifier) && !!value,
  )

  useEffect(() => {
    const initialValue = values[params.id]
    if (initialValue) {
      setPreviousValue(initialValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCloseModal = () => {
    setModalIsOpen(false)
    setChoosedValue('')
  }

  const onConfirm = () => {
    setModalIsOpen(false)
    form.mutators.setValue(params.id, choosedValue)
    resetAnswers()
  }

  const resetAnswers = () => {
    const qids = goalsOfCareAnswers.map(({ questionIdentifier }) => questionIdentifier)
    clearAnswers({ variables: { qids }, update: clearCacheAnswers })
  }

  const onChange = ({ target: { value } }) => {
    if (previousValue && previousValue !== value && goalsOfCareAnswers.length) {
      setChoosedValue(value)
      setModalIsOpen(true)
    } else {
      form.mutators.setValue(params.id, value)
    }
  }

  const chooseOneFancyProps = {
    ...props,
    params: {
      ...props.params,
      onChange,
    },
  }

  return (
    <>
      <ChooseOneFancy {...chooseOneFancyProps} />
      <Modal isOpen={modalIsOpen} title={t('common.goalsOfCare.changeModal.title')} onRequestClose={onCloseModal}>
        <div className="modal_text">
          <p>{t('common.goalsOfCare.changeModal.text')}</p>
          <p>{t('common.goalsOfCare.changeModal.instructions')}</p>
        </div>
        <ButtonGroup className="-flex -with-margin-top">
          <Button type="button" className="-secondary" onClick={onCloseModal}>
            {t('common.goalsOfCare.changeModal.cancelButton')}
          </Button>
          <Button type="button" onClick={onConfirm}>
            {t('common.goalsOfCare.changeModal.submitButton')}
          </Button>
        </ButtonGroup>
      </Modal>
    </>
  )
}

export default GoalsOfCareControl
