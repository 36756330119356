import React from 'react'
import cn from 'classnames'
import '../../styles/blocks/button-group.scss'

const ButtonGroup = props => (
  <div className={cn('button-group', props.className)} id={props.id}>
    {props.children}
  </div>
)

export default ButtonGroup
