import { find, groupBy, compose, mapObjIndexed } from 'ramda'
import { WelcomePage } from './WelcomePage'
import { MyValuesPage } from './MyValuesPage'
import { MedicalDecisionsPage } from './MedicalDecisionsPage'
import { FutureDecisionsPage } from './FutureDecisionsPage'
import { GoalsOfCarePage } from './GoalsOfCarePage'
import { ProxyDesignationPage } from './ProxyDesignationPage'
import { GuidanceToMyProxyPage } from './GuidanceToMyProxyPage'
import { SMSpecificWishesPage, SM_ALL_QIDS } from './SMSpecificWishesPage'
import { SMChangesPage, SM_CHANGES_QID } from './SMChangesPage'
import { SMComfortCareWishesFirstPage, SM_CC_QIDS } from './SMComfortCareWishesFirstPage'
import { SMComfortCareWishesSecondPage, SM_CC_ALL_QIDS } from './SMComfortCareWishesSecondPage'
import { CCSpecificWishesFirstPage, CC_QIDS } from './CCSpecificWishesFirstPage'
import { CCSpecificWishesSecondPage, CC_ALL_QIDS } from './CCSpecificWishesSecondPage'
import { FTChangesPage, FT_CHANGES_QID } from './FTChangesPage'
import { FTSelectiveMeasuresPage, FT_SM_ALL_QIDS } from './FTSelectiveMeasuresWishesPage'
import { FTGoalsOfCarePage, FT_ALL_QIDS } from './FTGoalsOfCarePage'
import { InvolvedPeoplePage } from './InvolvedPeoplePage'
import { ExcludedPeoplePage } from './ExcludedPeoplePage'

const getWellSummaryDirectiveAllSteps = (t) => [
  WelcomePage(t),
  MyValuesPage(t),
  MedicalDecisionsPage(t),
  FutureDecisionsPage(t),
  GoalsOfCarePage(t),
  SMSpecificWishesPage(t),
  SMChangesPage(t),
  SMComfortCareWishesFirstPage(t),
  SMComfortCareWishesSecondPage(t),
  CCSpecificWishesFirstPage(t),
  CCSpecificWishesSecondPage(t),
  FTGoalsOfCarePage(t),
  FTChangesPage(t),
  FTSelectiveMeasuresPage(t),
  ProxyDesignationPage(t),
  GuidanceToMyProxyPage(t),
  InvolvedPeoplePage(t),
  ExcludedPeoplePage(t),
]

const goalsOfCareQids = [
  ...SM_ALL_QIDS,
  SM_CHANGES_QID,
  ...SM_CC_QIDS,
  ...SM_CC_ALL_QIDS,
  ...CC_QIDS,
  ...CC_ALL_QIDS,
  ...FT_ALL_QIDS,
  FT_CHANGES_QID,
  ...FT_SM_ALL_QIDS,
]

const getWellSummaryDirectiveSteps = (answers) => (t = (v) => v) => {
  const steps = []
  const values = compose(
    mapObjIndexed((arr) => arr[0].value),
    groupBy(({ questionIdentifier }) => questionIdentifier),
  )(answers)

  const firstStep = find((step) => step.getPrevStep(answers).page === null, getWellSummaryDirectiveAllSteps(t))

  if (firstStep) {
    steps.push(firstStep)
    let currentStep = firstStep ? firstStep.getNextStep(values).page : null

    while (currentStep) {
      steps.push(currentStep)
      currentStep = currentStep.getNextStep(values).page
    }
  }

  return steps
}

export { getWellSummaryDirectiveAllSteps, goalsOfCareQids, getWellSummaryDirectiveSteps }
