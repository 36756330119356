import { gql } from 'apollo-boost'

export const GET_ME = gql`
  {
    me {
      id
      documents {
        id
        dType
        name
        url
        downloadURL
        documentVersions {
          documentId
          executed
          updatedAt
          executedVersion {
            createdAt
            key
          }
          draft {
            createdAt
          }
        }
      }
      productType
      requiresPasswordChange
      proxies {
        id
        additionalPhone
        address
        city
        email
        firstName
        isPrimary
        lastName
        phone
        state
        zip
      }
      answers {
        id
        questionIdentifier
        value
      }
      profile {
        id
        email
        firstName
        lastName
        residenceState
        dateOfBirth
        signatureUrl
        locale
      }
      reminder {
        annuallyAtDay
        annuallyAtMonth
        exactlyAt
        id
      }
      sharedWithAdVault {
        createdAt
        id
        updatedAt
        shareType
        status
      }
      sharedWithProxies {
        createdAt
        email
        fullName
        id
        updatedAt
        fileName
      }
      sharedWithDoctors {
        createdAt
        email
        fullName
        id
        updatedAt
        fileName
      }
      sharedWithOrganization {
        createdAt
        id
      }
      sharedWithOthers {
        createdAt
        email
        fullName
        id
        updatedAt
        fileName
      }
    }
    currentOrganization {
      adVaultEnabled
    }
  }
`
