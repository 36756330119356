import React from 'react'

import '../../styles/blocks/for-whom.scss'

const ForWhom = ({ children }) => (
  <div className="for-whom">
    {children}
  </div>
)

export default ForWhom
