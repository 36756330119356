import React from 'react'
import cn from 'classnames'
import MD from '../../directive/blocks/Markdown'

import { ReactComponent as IconTick } from '../../assets/images/icon-tick.svg'
import { ReactComponent as IconMinus } from '../../assets/images/icon-minus.svg'

import '../../styles/blocks/tick-list.scss'

const ForWhom = ({ dull, items, className }) => (
  <ul
    className={cn(
      'tick-list',
      className,
      { '-dull': dull },
    )}
  >
    {items.map((item, index) =>
      <li className="tick-list_item" key={index}>
        {item.icon === 'tick' &&
          <IconTick className="tile-list_icon icon -icon-tick"/>
        }
        {item.icon === 'minus' &&
          <IconMinus className="tile-list_icon icon -icon-minus"/>
        }
        <MD>{item.text}</MD>
      </li>,
    )}
  </ul>
)

export default ForWhom
