import React, { Fragment } from 'react'
import cn from 'classnames'
import MD from '../../directive/blocks/Markdown'

import '../../styles/blocks/definitions-list.scss'

const ForWhom = ({ items, className }) => (
  <dl className={cn('definitions-list', className)}>
    {items.map((item, index) => (
      <Fragment key={index}>
        <dt className="definitions-list_term">
          <MD>{item.term}</MD>
        </dt>
        <dd className="definitions-list_definition">
          <MD>{item.definition}</MD>
        </dd>
      </Fragment>
    ))}
  </dl>
)

export default ForWhom
