import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import GoalsOfCareControl from './GoalsOfCareControl'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswerByQid } from '../TheDirective'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { FutureDecisionsPage } from './FutureDecisionsPage'
import { WELL_SUMMARY_TYPE } from '../../constants/productTypes'
import { SMSpecificWishesPage } from './SMSpecificWishesPage'
import { CCSpecificWishesFirstPage } from './CCSpecificWishesFirstPage'
import { ProxyDesignationPage } from './ProxyDesignationPage'
import { FTGoalsOfCarePage } from './FTGoalsOfCarePage'
import { GoalsOfCareSummary } from '../blocks/summary/GoalsOfCareSummary'

const GOALS_OF_CARE_URL = 'goals-of-care'
const GOALS_OF_CARE_QID = 'lwscarepathwayoption'

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(WELL_SUMMARY_TYPE))

const GoalsOfCarePage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: GOALS_OF_CARE_URL,
    getPrevStep: () => ({
      page: FutureDecisionsPage(t),
      url: `${DIRECTIVE_URL}/${FutureDecisionsPage(t).slug}`,
    }),
    getNextStep: (values) => {
      const value = values[GOALS_OF_CARE_QID]
      switch (value) {
        case '1':
          return {
            page: FTGoalsOfCarePage(t),
            url: `${DIRECTIVE_URL}/${FTGoalsOfCarePage(t).slug}`,
          }
        case '2':
          return {
            page: SMSpecificWishesPage(t),
            url: `${DIRECTIVE_URL}/${SMSpecificWishesPage(t).slug}`,
          }
        case '3':
          return {
            page: CCSpecificWishesFirstPage(t),
            url: `${DIRECTIVE_URL}/${CCSpecificWishesFirstPage(t).slug}`,
          }
        default:
          return {
            page: ProxyDesignationPage(t),
            url: `${DIRECTIVE_URL}/${ProxyDesignationPage(t).slug}`,
          }
      }
    },
    setInitialValues: (answers) => ({
      [GOALS_OF_CARE_QID]: getAnswerByQid(GOALS_OF_CARE_QID)(answers),
    }),
    serializeValues: (values) => ({
      variables: {
        qid: GOALS_OF_CARE_QID,
        val: values[GOALS_OF_CARE_QID],
      },
      update: updateCacheAnswers,
    }),
    validate: (values) => {
      return values[GOALS_OF_CARE_QID] ? {} : { formError: t('directive.well.page.goalsOfCare.validate.formError') }
    },
    isRequired: true,
    isAnswered: (answers) => !!getAnswerByQid(GOALS_OF_CARE_QID)(answers),
    dashboard: {
      title: t('directive.well.page.goalsOfCare.dashboardTitle'),
    },
    summary: {
      title: t('directive.well.page.goalsOfCare.summaryTitle'),
      blocks: [
        {
          component: GoalsOfCareSummary,
          params: {
            id: GOALS_OF_CARE_QID,
            options: answersOptions[GOALS_OF_CARE_QID].options,
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.well.page.goalsOfCare.blocks.pageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.well.page.goalsOfCare.blocks.pageText'),
        },
        columnRight: true,
      },
      {
        component: GoalsOfCareControl,
        params: {
          id: GOALS_OF_CARE_QID,
          options: answersOptions[GOALS_OF_CARE_QID].options,
        },
      },
    ],
  }
}

export { GoalsOfCarePage, GOALS_OF_CARE_URL, GOALS_OF_CARE_QID }
