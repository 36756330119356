import React from 'react'
import cn from 'classnames'
import SelectorRadio from './SelectorRadio'

const ChooseOne = ({ params: { id, options, className } }) => (
  <div className={cn('choose-one', className)}>
    {options.map((option, index) => (
      <SelectorRadio key={index} name={id} value={option.id} label={option.title} />
    ))}
  </div>
)

export default ChooseOne
