import React from 'react'
import cn from 'classnames'
import Container from '../Container'
import LogoHeader from '../LogoHeader'
import LogoPartner from './LogoPartner'
import TopLogin from './TopLogin'
import TopLang from '../TopLang'

import '../../../styles/blocks/landing/header-landing.scss'

const HeaderLanding = ({ className }) => {
  return (
    <header className={cn('header-landing', className)}>
      <Container className="header-landing_container">
        <div className="header-landing_row">
          <div className="header-landing_logos">
            <LogoHeader />
            <LogoPartner />
          </div>
          <div className="header-landing_controls">
            <div className="header-landing_lang-control">
              <TopLang />
            </div>
            <div className="header-landing_login-desktop">
              <TopLogin />
            </div>
          </div>
        </div>
        <div className="header-landing_login-mobile">
          <TopLogin />
        </div>
      </Container>
    </header>
  )
}

export default HeaderLanding
