import React from 'react'
import cn from 'classnames'
import MD from './Markdown'

import '../../styles/blocks/question-text.scss'

const QuestionText = ({ params: { heading, text }, className }) => (
  <div className={cn('question-text', className)}>
    {heading && (
      <h4 className="question-text_heading">
        <MD>{heading}</MD>
      </h4>
    )}
    {text && <MD allowParagraphs>{text}</MD>}
  </div>
)

export default QuestionText
