import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../Container'
import Button from '../Button'
import { LandingContext } from '../../../landing/LandingContext'

import { ReactComponent as PicHero } from '../../../assets/images/pic-hero.svg'

import '../../../styles/blocks/landing/landing-hero.scss'

const LandingHero = () => {
  const { t } = useTranslation()

  const { startLink, useExternalLinks } = useContext(LandingContext)
  const buttonProps = { [useExternalLinks ? 'href' : 'to']: startLink }

  return (
    <div className="landing-hero">
      <PicHero className="landing-hero_pic pic -pic-hero" />
      <Container className="landing-hero_container -size-landing">
        <h1 className="landing-hero_heading">{t('landing.hero.heading')}</h1>
        <div className="landing-hero_intro">{t('landing.hero.intro')}</div>
        <Button className="landing-hero_cta -desktop-big" {...buttonProps}>
          {t('landing.hero.cta')}
        </Button>
      </Container>
    </div>
  )
}

export default LandingHero
