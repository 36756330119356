import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { CREATE_DIRECT_UPLOAD, CREATE_OR_UPDATE_USER_DOCUMENT } from '../common/mutations'
import { useTranslation } from 'react-i18next'
import Button from '../directive/blocks/Button'
import ButtonGroup from '../directive/blocks/ButtonGroup'
import { UserContext } from '../hoc'
import { useFilePicker } from 'react-sage'
import Container from '../directive/blocks/Container'
import Select from 'react-select'
import { calculateChecksum } from '../common/checksumUtils'
import { PUT } from '../common/requests'
import RadioInput from '../directive/blocks/RadioInput'
import * as Helpers from './helpers'
import { ReactComponent as IconDelete } from '../assets/images/icon-delete.svg'
import { showError } from '../common/toastUtils'


const DocumentUploader = (props) => {
  const { t } = useTranslation()
  const [uploadDisabled, setUploadDisabled] = useState(true)
  const [radioButtonDisabled, setRadioButtonDisabled] = useState(false)
  const [isPdf, setIsPdf] = useState(true)
  const [allFiles, setAllFiles] = useState([])
  const [images, setImages] = useState([])
  const [nextPageNumber, setNextPageNumber] = useState(1)
  const [customNameForImages, setCustomNameForImages] = useState('')
  const [pdfIsGenerating, setPdfIsGenerating] = useState(false)


  const [createDirectUpload] = useMutation(CREATE_DIRECT_UPLOAD)
  const [createOrUpdateUserDocument] = useMutation(CREATE_OR_UPDATE_USER_DOCUMENT)

  const { files, onClick, HiddenFileInput } = useFilePicker({})

  const me = useContext(UserContext)
  const { firstName, lastName } = me.profile

  const submitFiles = async () => {
    setPdfIsGenerating(true)
    if (isPdf) submitPDF(files)
    else {
      const pdf = generatePdfFromImages()
      const name = `${firstName}${lastName}Document`
      pdf.name = customNameForImages || name

      submitPDF(pdf)
    }
  }

  const handleImageUpload = useCallback(
    (files) => {
      const fileList = files
      const fileArray = fileList ? Array.from(fileList) : []
      const fileToImagePromises = fileArray.map(Helpers.fileToImageURL)

      Promise.all(fileToImagePromises).then(data => {
        setImages(prevImages => [...prevImages, ...data])
      })
    },
    [],
  )

  const cleanUpfilesToUpload = useCallback(() => {
    setImages([])
    images.forEach((image) => {
      URL.revokeObjectURL(image.src)
    })
  }, [setImages, images])

  const generatePdfFromImages = useCallback(() => {
    const pdf = Helpers.generatePdfFromImages(images)
    cleanUpfilesToUpload()
    return pdf
  }, [images, cleanUpfilesToUpload])

  const uploadFiles = async (file, uploadInput) => {
    const {
      data: {
        createDirectUpload: { directUpload },
      },
    } = await createDirectUpload({
      variables: uploadInput,
    })

    const { url, headers, signedBlobId, key } = directUpload
    const uploadBlob = file.blob || file

    await PUT('', uploadBlob, url, JSON.parse(headers))()

    return { signedBlobId, key }
  }

  const submitPDF = async (pdf) => {
    if (Array.isArray(pdf)) pdf = pdf[0]

    setUploadDisabled(true)

    const checksum = await calculateChecksum(pdf)

    const file = {
      name: pdf.name,
      size: pdf.size,
      blob: pdf,
      checksum: checksum,
      contentType: 'application/pdf',
    }

    const uploadInput = await Helpers.getUploadInput(file)

    // Upload the images and attach the keys as metadata
    if (!isPdf) {
      const imageKeys = []

      for (const allFile of allFiles) {
        const tempUploadInput = await Helpers.getUploadInput(allFile)
        const { key } = await uploadFiles(allFile, tempUploadInput)
        imageKeys.push(key)
      }

      uploadInput.metadata = { images: imageKeys }
    }

    const { signedBlobId } = await uploadFiles(file, uploadInput)

    const res = await createOrUpdateUserDocument({
      variables: {
        name: file.name,
        dType: props.documentType,
        documentId: file.documentId,
        blobId: signedBlobId,
        executed: true,
        isPdf: isPdf,
      },
    })
    const errors = res.data.createOrUpdateUserDocument.errors
    if (errors.length > 0) {
        showError(t('documents.createDocumentError'))
        return errors
    }

    setRadioButtonDisabled(false)
    setPdfIsGenerating(false)
    setUploadDisabled(false)
    props.setIsSigned(false)
    window.location.reload()
  }

  const removeFile = (index) => {
    if (allFiles.length === 1) setRadioButtonDisabled(false)

    if (!isPdf) {
      setImages(prevImages => prevImages.filter((img, i) => i !== index))
      setNextPageNumber(prevNumber => prevNumber - 1)
    }

    setAllFiles(prevFiles => prevFiles.filter((img, i) => i !== index))
  }

  const testCustomFileName = event => {
    if (event.target.value === '') setCustomNameForImages('')

    const regex = new RegExp(/^[a-z0-9]+$/, 'i') // only accept alphanumeric for custom filename

    if (!regex.test(event.target.value)) {
      event.preventDefault()
      showError(t('documents.incorrectFileNameError'))
      return false
    } else {
      setCustomNameForImages(event.target.value, customNameForImages)
    }
  }

  useEffect(() => {
    if (files.length === 0) return

    setUploadDisabled(false)
    setRadioButtonDisabled(true)

    if (isPdf) setAllFiles(files)
    else {
      setAllFiles(prevFiles => [...prevFiles, ...files])
      handleImageUpload(files)
      setNextPageNumber(prevNumber => prevNumber + files.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, handleImageUpload])

  return (
    <div id="file-selector">
      {/* Overview of uploaded images */}
      {images.length > 0 &&
        <div className="images-container">
          {images.length > 0 ? (
            images.map(image => (
              <img key={image.currentSrc} src={image.src} className="uploaded-image" />
            ))
          ) : (
            <p>{t('documents.imagePlaceholder')}</p>
          )}
        </div>
      }
      {!isPdf && <HiddenFileInput accept={'.jpeg, .jpg, .png'} multiple={true} />}
      {isPdf && <HiddenFileInput accept={'.pdf'} multiple={false} />}
      {!radioButtonDisabled && <div className='radio-container'>
        <h4 className="radio-input-header">{t('documents.radioLabel')}</h4>
        <RadioInput label={t('documents.radioLabel.pdf')} value={true} checked={isPdf} disabled={radioButtonDisabled} setter={setIsPdf} className="radio-input" />
        <RadioInput label={t('documents.radioLabel.images')} value={false} checked={isPdf} disabled={radioButtonDisabled} setter={setIsPdf} className="radio-input" />
      </div>
      }
      {!isPdf && <span><div className='filename-wrapper'>
        <label><h4>{t('form.customFilename')}</h4></label>
        <input
          className='form-input_input form-control_input'
          id='custom-file-name'
          placeholder={t('form.customFilenamePlaceholder')}
          maxLength="50"
          type="text"
          value={customNameForImages}
          onChange={e => testCustomFileName(e)}
        />
      </div>
      </span>
      }
      <Select id="upload-new-directive" onMenuOpen={onClick} isReadOnly placeholder={t('documents.selectDropdown')} />
      {allFiles.map((f, i) => (<Container key={f.name} keyProp={f.name}>
        <span><button className="small-icons" onClick={e => removeFile(i)}><IconDelete /></button></span>
        {!isPdf && t('documents.page')} {!isPdf && i + 1}{!isPdf && ':'} {f.name}
      </Container>))}
      <ButtonGroup className="-space-around" >
        {!isPdf && <Button className="-inside-container" onClick={onClick}>
          {t('documents.selectNextPageButton')} {nextPageNumber}
        </Button>
        }
        <Button
          className="-inside-container"
          onClick={submitFiles}
          disabled={uploadDisabled}
          isLoading={pdfIsGenerating}
        >
          {t('documents.uploadButton')}
        </Button>
      </ButtonGroup>
    </div>
  )
}
export default DocumentUploader
