import React from 'react'
import cn from 'classnames'

import '../../../styles/blocks/landing/landing-column-right.scss'

const LandingColumnRight = ({ className, children }) => (
  <div className={cn('landing-column-right', className)}>
    {children}
  </div>
)


export default LandingColumnRight
