import { find, reduce } from 'ramda'

import { getBaseDirectiveSteps } from './baseDirective/BaseDirective'
import {
  getWellSummaryDirectiveAllSteps,
  getWellSummaryDirectiveSteps,
} from './wellSummaryDirective/WellSummaryDirective'
import { BASE_TYPE, WELL_SUMMARY_TYPE } from '../constants/productTypes'

const getPageBySlug = (slug) => (directive) => find((page) => page.slug === slug, directive)

const getAnswerByQid = (qid) => (answers) => (find((answer) => answer.questionIdentifier === qid, answers) || {}).value

const groupAnswersByQid = (answers) =>
  reduce(
    (acc, answer) => ({
      ...acc,
      [answer.questionIdentifier]: answer && answer.value,
    }),
    {},
    answers,
  )

const getCurrentDirective = (productType) => (t) => {
  switch (productType) {
    case BASE_TYPE:
      return getBaseDirectiveSteps(t)
    case WELL_SUMMARY_TYPE:
      return getWellSummaryDirectiveAllSteps(t)
    default:
      return []
  }
}

const getDirectiveSteps = (productType, answers) => (t) => {
  switch (productType) {
    case BASE_TYPE:
      return getBaseDirectiveSteps(t)
    case WELL_SUMMARY_TYPE:
      return getWellSummaryDirectiveSteps(answers)(t)
    default:
      return []
  }
}

export { getCurrentDirective, getPageBySlug, getAnswerByQid, groupAnswersByQid, getDirectiveSteps }
