import { LOCALES } from '../constants/locales'

const textEN = `
### Uploading Your Documents

You can upload a scanned PDF of your documents. Or, you may take images of each page of your document to upload; our system will attach the images together for you and create a PDF out of them.


### To Upload a PDF:

Scan your signed and witnessed Advance Directive or other document to your computer or phone. Choose the PDF you’re uploading. Click “Upload”.


### To Upload your documents as Images:

Select the Images button.
Give your document a title. We suggest including your name and the kind of document you are uploading. For example: “JohnSmithAdvanceDirective”

Select the images you would like to upload. Make sure they are in the correct order. You can remove any image by selecting the red x next to that image before uploading.

Once you have selected the images you want in the correct order, click “Upload”.

`

const textES = `
### Cargar Sus Documentos

Puede subir un PDF escaneado de sus documentos. O bien, puede tomar imágenes de cada página de su documento para subirlas; nuestro sistema adjuntará las imágenes juntas para usted y creará un PDF con ellas.


### Para subir un PDF:

Escanee su Directiva Anticipada firmada y atestiguada o cualquier otro documento a su ordenador o teléfono. Elija el PDF que va a subir. Haga clic en Cargar.


### Para subir sus documentos como imágenes:

Seleccione el botón Imágenes.
Ponga un título a su documento. Le sugerimos que incluys su nombre y el tipo de documento que esube. Por ejemplo: "JohnSmithAdvanceDirective"

Seleccione las imágenes que desea subir. Asegúrese de que están en el orden correcto. Puede eliminar cualquier imagen seleccionando la x roja junto a esa imagen antes de subirla.

Una vez que haya seleccionado las imágenes que desea en el orden correcto, haga clic en "Cargar".
`

export const HowToPopupTextByLocale = (locale) => {
  switch (locale) {
    case LOCALES.en:
      return textEN
    case LOCALES.es:
      return textES
    default:
      return textEN
  }
}
