import React, { useContext } from 'react'
import Container from '../Container'
import { useTranslation } from 'react-i18next'
// import PartnerBlock from './PartnerBlock'
import LandingTitle from './LandingTitle'
import LandingColumns from './LandingColumns'
import LandingColumnLeft from './LandingColumnLeft'
import Button from '../Button'
import { LandingContext } from '../../../landing/LandingContext'

import { ReactComponent as PicTime } from '../../../assets/images/pic-time.svg'

import '../../../styles/blocks/landing/time.scss'

const Time = () => {
  const { t } = useTranslation()

  const { startLink, useExternalLinks } = useContext(LandingContext)
  const buttonProps = { [useExternalLinks ? 'href' : 'to']: startLink }

  return (
    <div className="time">
      <Container className="time_container -size-landing">
        {/*<PartnerBlock />*/}
        <LandingColumns className="time_columns">
          <LandingColumnLeft>
            <LandingTitle title={t('landing.time.title')} />
            <div className="time_text">
              <p>{t('landing.time.paragraph1')}</p>
              <p>{t('landing.time.paragraph2')}</p>
              <Button className="time_cta -desktop-big" {...buttonProps}>
                {t('landing.time.cta')}
              </Button>
              <PicTime className="time_pic pic -pic-time" />
            </div>
          </LandingColumnLeft>
        </LandingColumns>
      </Container>
    </div>
  )
}

export default Time
