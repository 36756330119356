import React from 'react'
import { Link } from 'react-router-dom'
import PulseLoader from 'react-spinners/PulseLoader'
import cn from 'classnames'

import '../../styles/blocks/button.scss'

const Button = props => (
  <>
    {props.href && !props.disabled ? (
      <a className={cn('button', props.className)} href={props.href} onClick={props.onClick}>
        {props.children}
      </a>
    ) : props.to && !props.disabled ? (
      <Link className={cn('button', props.className)} to={props.to} onClick={props.onClick}>
        {props.children}
      </Link>
    ) : (
      <button
        className={cn('button', props.className)}
        data-modal={props.modal}
        onClick={props.onClick}
        disabled={props.disabled || props.isLoading}
        type={props.type}
      >
        {props.isLoading
          ? (<div className="loader-wrapper">
            <PulseLoader color={props.loaderColor || 'white'} size={12} />
          </div>)
          : props.children
          }
      </button>
    )}
  </>
)

export default Button
