import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../Container'
import LandingTitle from './LandingTitle'
import { ReactComponent as PicProxy } from '../../../assets/images/pic-what-is-proxy.svg'
import { ReactComponent as PicWill } from '../../../assets/images/pic-what-is-will.svg'

import '../../../styles/blocks/landing/what-is.scss'

const WhatIsComponent = () => {
  const { t } = useTranslation()

  return (
    <div className="what-is">
      <Container className="what-is_container -size-landing">
        <LandingTitle title={t('landing.whatIs.title')} subtitle={t('landing.whatIs.subtitle')} />
        <ul className="what-is_items">
          <li className="what-is_item">
            <PicProxy className="what-is_pic pic -pic-what-is-proxy" />
            <strong className="what-is_name">{t('landing.whatIs.proxy.title')}</strong>
            <span className="what-is_text">{t('landing.whatIs.proxy.text')}</span>
          </li>
          <li className="what-is_item">
            <PicWill className="what-is_pic pic -pic-what-is-will" />
            <strong className="what-is_name">{t('landing.whatIs.directive.title')}</strong>
            <span className="what-is_text">{t('landing.whatIs.directive.text')}</span>
          </li>
        </ul>
      </Container>
    </div>
  )
}

export default WhatIsComponent
