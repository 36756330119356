import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import YesIfNoOption from '../blocks/YesIfNoOption'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswerByQid } from '../TheDirective'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { WELL_SUMMARY_TYPE } from '../../constants/productTypes'
import { GoalsOfCarePage } from './GoalsOfCarePage'
import { SMChangesPage } from './SMChangesPage'
import TextAnswer from '../blocks/TextAnswer'
import { YesIfNoSummary } from '../blocks/summary/YesIfNoSummary'
import { TextAnswerSummary } from '../blocks/summary/TextAnswerSummary'
import {
  getCprHintData,
  getMechventHintData,
  getFeedingTubeHintData,
  getDialysisHintData,
} from '../goalsOfCareHintsData'

const SM_SPECIFIC_WISHES_URL = 'sm-goals-of-care'
const SM_CPR = 'smcpr'
const SM_MECHVENT = 'smmechvent'
const SM_FEEDINGTUBE = 'smfeedingtube'
const SM_DIALYSIS = 'smdialysis'
const SM_SPECIFIC_WISHES_QIDS = [SM_CPR, SM_MECHVENT, SM_FEEDINGTUBE, SM_DIALYSIS]
const SM_OTHERWISHES_QID = 'smothertmttextbox'
const SM_ALL_QIDS = [...SM_SPECIFIC_WISHES_QIDS, SM_OTHERWISHES_QID]

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(WELL_SUMMARY_TYPE))

const SMSpecificWishesPage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: SM_SPECIFIC_WISHES_URL,
    getPrevStep: () => ({
      page: GoalsOfCarePage(t),
      url: `${DIRECTIVE_URL}/${GoalsOfCarePage(t).slug}`,
    }),
    getNextStep: () => ({
      page: SMChangesPage(t),
      url: `${DIRECTIVE_URL}/${SMChangesPage(t).slug}`,
    }),
    setInitialValues: (answers) => {
      return SM_ALL_QIDS.reduce((res, qid) => ({ ...res, [qid]: getAnswerByQid(qid)(answers) }), {})
    },
    serializeValues: (values) =>
      SM_ALL_QIDS.filter((qid) => values[qid] !== undefined).map((qid) => ({
        variables: { qid, val: values[qid] },
        update: updateCacheAnswers,
      })),
    validate: () => {},
    isRequired: false,
    isAnswered: (answers) => SM_ALL_QIDS.some((qid) => !!getAnswerByQid(qid)(answers)),
    dashboard: {
      title: t('directive.well.page.SMSpecificWishes.dashboardTitle'),
    },
    summary: {
      title: t('directive.well.page.SMSpecificWishes.summaryTitle'),
      blocks: [
        {
          component: YesIfNoSummary,
          params: {
            qids: SM_SPECIFIC_WISHES_QIDS,
            labels: [
              t('directive.well.page.SMSpecificWishes.summaryLabels.cpr'),
              t('directive.well.page.SMSpecificWishes.summaryLabels.mechanicalVentilation'),
              t('directive.well.page.SMSpecificWishes.summaryLabels.feedingTube'),
              t('directive.well.page.SMSpecificWishes.summaryLabels.dialysis'),
            ],
            options: answersOptions,
          },
        },
        {
          component: TextAnswerSummary,
          params: {
            id: SM_OTHERWISHES_QID,
            heading: t('directive.well.page.SMSpecificWishes.summaryLabels.other'),
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.well.page.SMSpecificWishes.blocks.pageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.well.page.SMSpecificWishes.blocks.pageText'),
        },
        columnRight: true,
      },
      {
        component: YesIfNoOption,
        params: {
          id: SM_CPR,
          title: t('common.goalsOfCare.card.cpr.title'),
          text: t('common.goalsOfCare.card.cpr.text'),
          hint: getCprHintData(t),
          yesIf: {
            title: t('common.goalsOfCare.card.cpr.yesIf.title'),
            text: t('common.goalsOfCare.card.cpr.yesIf.text'),
            questionChoose: t('common.goalsOfCare.card.cpr.yesIf.questionChoose'),
            questionCustom: t('common.goalsOfCare.card.cpr.yesIf.questionCustom'),
            otherOptionId: answersOptions[SM_CPR].otherOptionId,
            options: answersOptions[SM_CPR].options,
          },
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: SM_MECHVENT,
          title: t('common.goalsOfCare.card.mechvent.title'),
          text: t('common.goalsOfCare.card.mechvent.text'),
          yesIf: {
            title: t('common.goalsOfCare.card.mechvent.yesIf.title'),
            text: t('common.goalsOfCare.card.mechvent.yesIf.text'),
            otherOptionId: answersOptions[SM_MECHVENT].otherOptionId,
            options: answersOptions[SM_MECHVENT].options,
            questionChoose: t('common.goalsOfCare.card.mechvent.yesIf.questionChoose'),
            questionCustom: t('common.goalsOfCare.card.mechvent.yesIf.questionCustom'),
          },
          hint: getMechventHintData(t),
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: SM_FEEDINGTUBE,
          title: t('common.goalsOfCare.card.feedingtube.title'),
          text: t('common.goalsOfCare.card.feedingtube.text'),
          yesIf: {
            title: t('common.goalsOfCare.card.feedingtube.yesIf.title'),
            text: t('common.goalsOfCare.card.feedingtube.yesIf.text'),
            otherOptionId: answersOptions[SM_FEEDINGTUBE].otherOptionId,
            options: answersOptions[SM_FEEDINGTUBE].options,
          },
          hint: getFeedingTubeHintData(t),
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: SM_DIALYSIS,
          title: t('common.goalsOfCare.card.dialysis.title'),
          text: t('common.goalsOfCare.card.dialysis.text'),
          yesIf: {
            title: t('common.goalsOfCare.card.dialysis.yesIf.title'),
            text: t('common.goalsOfCare.card.dialysis.yesIf.text'),
            otherOptionId: answersOptions[SM_DIALYSIS].otherOptionId,
            options: answersOptions[SM_DIALYSIS].options,
          },
          hint: getDialysisHintData(t),
        },
      },
      {
        component: TextAnswer,
        params: {
          id: SM_OTHERWISHES_QID,
          title: t('directive.well.page.SMSpecificWishes.blocks.otherWishes.title'),
          text: t('directive.well.page.SMSpecificWishes.blocks.otherWishes.text'),
          notRemoveEmptyField: true,
          tileWrapped: true,
        },
      },
    ],
  }
}

export {
  SMSpecificWishesPage,
  SM_SPECIFIC_WISHES_URL,
  SM_ALL_QIDS,
  SM_CPR,
  SM_MECHVENT,
  SM_FEEDINGTUBE,
  SM_DIALYSIS,
  SM_SPECIFIC_WISHES_QIDS,
}
