import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../Container'
import LandingTitle from './LandingTitle'
import LandingColumns from './LandingColumns'
import LandingColumnLeft from './LandingColumnLeft'
import LandingColumnRight from './LandingColumnRight'
import Button from '../Button'
import { LandingContext } from '../../../landing/LandingContext'

import { ReactComponent as PicPeople } from '../../../assets/images/pic-people-pair.svg'

import '../../../styles/blocks/landing/share.scss'

const Share = () => {
  const { t } = useTranslation()

  const { startLink, useExternalLinks } = useContext(LandingContext)
  const buttonProps = { [useExternalLinks ? 'href' : 'to']: startLink }

  return (
    <div className="share">
      <Container className="share_container -size-landing">
        <div className="share_tile">
          <LandingColumns className="share_columns">
            <LandingColumnLeft>
              <PicPeople className="share_pic pic -pic-people" />
            </LandingColumnLeft>
            <LandingColumnRight className="-short">
              <LandingTitle title={t('landing.share.title')} />
              <div className="share_text">
                <p>{t('landing.share.text')}</p>
              </div>
              <Button className="share_cta -desktop-big" {...buttonProps}>
                {t('landing.share.cta')}
              </Button>
              {/* <div className="share_note">~10 minutes to complete, no card required</div> */}
            </LandingColumnRight>
          </LandingColumns>
        </div>
      </Container>
    </div>
  )
}

export default Share
