import React from 'react'
import cn from 'classnames'
import HeaderLanding from './HeaderLanding'
import Footer from '../Footer'
import Content from '../Content'

import '../../../styles/blocks/landing/layout-landing.scss'

const LayoutLanding = ({ className, children }) => (
  <div className={cn('layout-landing', className)}>
    <HeaderLanding />
    <Content className="-landing">
      {children}
    </Content>
    <Footer className="-landing" />
  </div>
)

export default LayoutLanding
