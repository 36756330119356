import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import TooltipList from '../blocks/TooltipList'
import { getAnswerByQid } from '../TheDirective'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { DIRECTIVE_URL, EXECUTE_DIRECTIVE_URL } from '../../constants/UrlConstants'
import { InvolvedPeoplePage } from './InvolvedPeoplePage'
import OtherPeopleControl from './OtherPeopleControl'
import { OtherPeopleSummary } from '../blocks/summary/OtherPeopleSummary'
import ExecuteDirective from '../ExecuteDirective'

const EXCLUDED_PEOPLE_URL = 'excluded-people'
const EXCLUDED_PEOPLE_QID = 'hcp_blacklist'

const ExcludedPeoplePage = (t) => ({
  slug: EXCLUDED_PEOPLE_URL,
  getPrevStep: () => ({
    page: InvolvedPeoplePage(t),
    url: `${DIRECTIVE_URL}/${InvolvedPeoplePage(t).slug}`,
  }),
  getNextStep: () => ({
    page: null,
    url: `${EXECUTE_DIRECTIVE_URL}`,
  }),
  setInitialValues: (answers) => ({
    [EXCLUDED_PEOPLE_QID]: getAnswerByQid(EXCLUDED_PEOPLE_QID)(answers) || [{}],
  }),
  serializeValues: (values) => {
    const people = values[EXCLUDED_PEOPLE_QID]
    const hasNotEmptyValues = (obj) => Object.values(obj).length && Object.values(obj).every(Boolean)
    const val = people ? people.filter(hasNotEmptyValues) : []

    return {
      variables: { qid: EXCLUDED_PEOPLE_QID, val },
      update: updateCacheAnswers,
    }
  },
  validate: () => {},
  isRequired: false,
  isAnswered: (answers) => {
    const people = getAnswerByQid(EXCLUDED_PEOPLE_QID)(answers)
    return !!people && people.length > 0 && people.some((person) => Object.values(person).length)
  },
  dashboard: {
    title: t('directive.well.page.excludedPeople.dashboardTitle'),
  },
  summary: {
    title: t('directive.well.page.excludedPeople.summaryTitle'),
    blocks: [
      {
        component: OtherPeopleSummary,
        params: {
          id: EXCLUDED_PEOPLE_QID,
        },
      },
    ],
  },
  blocks: [
    {
      component: PageTitle,
      params: {
        text: t('directive.well.page.excludedPeople.blocks.pageTitle'),
      },
    },
    {
      component: PageText,
      params: {
        text: t('directive.well.page.excludedPeople.blocks.pageText'),
      },
    },
    {
      component: OtherPeopleControl,
      params: {
        id: EXCLUDED_PEOPLE_QID,
      },
      hideBlockError: true,
    },
    {
      component: TooltipList,
      params: {
        tooltips: [
          {
            title: t('directive.well.page.excludedPeople.blocks.tooltipList.tooltips.0.title'),
            reTitle: t('directive.well.page.excludedPeople.blocks.tooltipList.tooltips.0.reTitle'),
            text: t('directive.well.page.excludedPeople.blocks.tooltipList.tooltips.0.text'),
          },
        ],
      },
      columnRight: true,
    },
  ],
})

export { ExcludedPeoplePage, EXCLUDED_PEOPLE_URL, EXCLUDED_PEOPLE_QID }
