import React from 'react'
import { find, map, propEq } from 'ramda'
import { useMutation } from '@apollo/react-hooks'
import LangSwitch from './LangSwitch'
import { CHANGE_LOCALE } from '../../common/mutations'
import { LOCALE_OPTIONS } from '../../constants/locales'
import { updateProfileLocaleCache } from '../../common/cacheUtils'
import useViewport from '../../common/useViewport'
import i18n from '../../i18n'
import '../../styles/blocks/top-lang.scss'

const TopLang = () => {
  const { isMobileViewport } = useViewport()

  const [changeLocale] = useMutation(CHANGE_LOCALE)

  const formattedOptions = isMobileViewport
    ? map((option) => ({ ...option, label: option.label.substr(0, 2) }))(LOCALE_OPTIONS)
    : LOCALE_OPTIONS
  const value = find(propEq('value', i18n.language))(formattedOptions)

  const onLanguageChange = ({ value }) => {
    changeLocale({ variables: { locale: value }, update: updateProfileLocaleCache })
    i18n.changeLanguage(value)
    localStorage.setItem('lang', value)
  }

  return (
    <div className="top-lang">
      <LangSwitch value={value} options={formattedOptions} onChange={onLanguageChange} />
    </div>
  )
}

export default TopLang
