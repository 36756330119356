import React, { useState } from 'react'
import cn from 'classnames'
import MD from './Markdown'
import Container from './Container'
import ReactModal from 'react-modal'
import { ReactComponent as IconCross } from '../../assets/images/icon-cross.svg'

import '../../styles/blocks/modal.scss'

ReactModal.setAppElement('#root-app')

const Modal = ({ onRequestClose, isOpen, button, size, title, children, scrollable, id }) => {
  const [modalShown, setModalShown] = useState(false)

  const showModal = () => {
    setModalShown(true)
  }

  const closeModal = () => {
    if (onRequestClose) {
      onRequestClose()
    } else {
      setModalShown(false)
    }
  }

  return (
    <>
      {button &&
        React.cloneElement(button, { onClick: showModal, role: 'button' })
      }
      <ReactModal
        isOpen={modalShown || isOpen}
        onRequestClose={closeModal}
        portalClassName="modal"
        overlayClassName="modal_overlay"
        className={cn(
          'modal_data',
          { [`modal_data_size_${size}`]: size },
        )}
        bodyOpenClassName="-modal-open"
        htmlOpenClassName=""
        id={id}
      >
        <span className="modal_close" onClick={closeModal} role="button">
          <IconCross className="modal_close-icon icon -cross" />
          <span className="modal_close-text">Close</span>
        </span>

        <div className="modal_header">
          <Container className="container_size_sm">
            {title &&
            <h1 className="modal_header-title">
              <MD>{title}</MD>
            </h1>
            }
          </Container>
        </div>

        <div
          className={cn(
            'modal_body',
            { '-scrollable': scrollable },
          )}
        >
          <Container>
            {children}
          </Container>
        </div>
      </ReactModal>
    </>
  )
}

export default Modal
