import React from 'react'
import cn from 'classnames'

import '../../styles/blocks/tile-group.scss'

const TileGroup = ({ white, className, children }) => (
  <div
    className={cn(
      'tile-group',
      className,
      // { '-white': white },
    )}
  >
    {children}
  </div>
)

export default TileGroup
