import React from 'react'
import HelpModal from './HelpModal'

import { ReactComponent as IconChat } from '../../assets/images/icon-chat.svg'

import '../../styles/blocks/top-help.scss'

const HelpButton = ({ onClick }) => (
  <div className="top-help" role="button" onClick={onClick}>
    <IconChat className="icon icon_chat" />
  </div>
)

const TopHelp = () => <HelpModal button={<HelpButton />} />

export default TopHelp
