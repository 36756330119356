import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as IconUser } from '../../assets/images/icon-user.svg'

import '../../styles/blocks/top-user.scss'

const TopUser = ({ profile }) => (
  <Link to="/profile" className="top-user" role="button">
    <div className="top-user_name">{`${profile.firstName} ${profile.lastName}`}</div>
    <IconUser className="icon -desktop-hide" />
  </Link>
)

export default TopUser
