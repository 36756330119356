import React from 'react'
import cn from 'classnames'

import '../../styles/blocks/column-top.scss'

const ColumnTop = ({ className, children }) => (
  <div className={cn('column-top', className)}>
    {children}
  </div>
)

export default ColumnTop
