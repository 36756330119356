import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LandingContext } from '../../../landing/LandingContext'

import '../../../styles/blocks/landing/top-login.scss'

const TopLogin = () => {
  const { t } = useTranslation()
  const { loginLink, useExternalLinks } = useContext(LandingContext)

  return (
    <div className="top-login">
      {useExternalLinks ? (
        <a className="top-login_btn" role="button" href={loginLink}>
          {t('landing.header.loginButton')}
        </a>
      ) : (
        <Link className="top-login_btn" role="button" to={loginLink}>
          {t('landing.header.loginButton')}
        </Link>
      )}
    </div>
  )
}

export default TopLogin
