import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import ChooseOneFancy from '../blocks/ChooseOneFancy'
import TooltipList from '../blocks/TooltipList'
import { getAnswerByQid } from '../TheDirective'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { DIRECTIVE_URL, EXECUTE_DIRECTIVE_URL } from '../../constants/UrlConstants'
import { BASE_TYPE } from '../../constants/productTypes'
import { MedicalInterventionsPage } from './MedicalInterventionsPage'
import { ChooseOneSummary } from '../blocks/summary/ChooseOneSummary'
import ExecuteDirective from '../ExecuteDirective'

const GUIDANCE_URL = 'guidance-to-your-proxy'
const GUIDANCE_QID = 'hcrguidance'

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(BASE_TYPE))

const GuidanceToMyProxyPage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: GUIDANCE_URL,
    getPrevStep: () => ({
      page: MedicalInterventionsPage(t),
      url: `${DIRECTIVE_URL}/${MedicalInterventionsPage(t).slug}`,
    }),
    getNextStep: () => ({
      page: null,
      url: `${EXECUTE_DIRECTIVE_URL}`,
    }),
    setInitialValues: (answers) => ({
      [GUIDANCE_QID]: getAnswerByQid(GUIDANCE_QID)(answers),
    }),
    serializeValues: (values) => ({
      variables: {
        qid: GUIDANCE_QID,
        val: values[GUIDANCE_QID],
      },
      update: updateCacheAnswers,
    }),
    validate: (values) => {
      return values[GUIDANCE_QID] ? {} : { formError: t('directive.base.page.guidanceToProxy.validate.formError') }
    },
    isRequired: true,
    isAnswered: (answers) => !!getAnswerByQid(GUIDANCE_QID)(answers),
    dashboard: {
      title: t('directive.base.page.guidanceToProxy.dashboardTitle'),
    },
    summary: {
      title: t('directive.base.page.guidanceToProxy.summaryTitle'),
      blocks: [
        {
          component: ChooseOneSummary,
          params: {
            id: GUIDANCE_QID,
            options: answersOptions[GUIDANCE_QID].options,
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.base.page.guidanceToProxy.blocks.pageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.base.page.guidanceToProxy.blocks.pageText'),
        },
      },
      {
        component: ChooseOneFancy,
        params: {
          id: GUIDANCE_QID,
          options: answersOptions[GUIDANCE_QID].options,
        },
      },
      {
        component: TooltipList,
        params: {
          tooltips: [
            {
              title: t('directive.base.page.guidanceToProxy.blocks.tooltipList.tooltips.0.title'),
              reTitle: t('directive.base.page.guidanceToProxy.blocks.tooltipList.tooltips.0.reTitle'),
              text: t('directive.base.page.guidanceToProxy.blocks.tooltipList.tooltips.0.text'),
            },
          ],
        },
        columnRight: true,
      },
    ],
  }
}

export { GuidanceToMyProxyPage, GUIDANCE_URL, GUIDANCE_QID }
