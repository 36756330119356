import React from 'react'
import { compose, find, join, map, filter, keys, propEq, propOr } from 'ramda'
import QuestionText from '../QuestionText'

const getTitleById = (id) => compose(propOr('', 'title'), find(propEq('id', id)))

export const ValuesSummary = ({ params: { id, heading, options, otherOptionId }, answers }) => {
  const values = answers[id]

  const getValueText = (optionId) =>
    '- ' + (optionId === otherOptionId ? values[optionId] : getTitleById(optionId)(options))

  const myValuesText = compose(
    join('\n'),
    map(getValueText),
    filter((optionId) => values[optionId]),
  )(keys(values))

  return myValuesText ? <QuestionText params={{ heading, text: myValuesText }} /> : null
}
