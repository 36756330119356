import React from 'react'
import cn from 'classnames'
import Option from './Option'

const OptionsList = ({ options, className, onOptionSelect }) => (
  <div className={cn('option-list', className)}>
    {options.map(({ id, text }) => (
      <Option key={id} text={text} onClick={onOptionSelect({ id, text })} />
    ))}
  </div>
)

export default OptionsList
