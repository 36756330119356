import AL from '../../assets/images/signing-instructions/AL.PNG'
import CA from '../../assets/images/signing-instructions/CA.PNG'
import MS from '../../assets/images/signing-instructions/MS.PNG'
import NE from '../../assets/images/signing-instructions/NE.PNG'
import OH from '../../assets/images/signing-instructions/OH.PNG'
import TN from '../../assets/images/signing-instructions/TN.PNG'
import UT from '../../assets/images/signing-instructions/UT.PNG'
import WI from '../../assets/images/signing-instructions/WI.PNG'
import WY from '../../assets/images/signing-instructions/WY.PNG'
import SCNotary from '../../assets/images/signing-instructions/SCNotary.PNG'
import SCWitness from '../../assets/images/signing-instructions/SCWitness.PNG'
import Notary from '../../assets/images/signing-instructions/2WitANDNotary-NOTARY.PNG'
import Witness from '../../assets/images/signing-instructions/2WitANDNotary-WITNESS.PNG'
import NotaryRec from '../../assets/images/signing-instructions/2WitNotaryRec.PNG'

export const getInstructionImage = state => {
  return matchStateWithImage[state]
}

const matchStateWithImage = {
  // Individual states - 10
  'AL': [AL],
  'CA': [CA],
  'MS': [MS],
  'NE': [NE],
  'OH': [OH],
  'SC': [SCNotary, SCWitness],
  'TN': [TN],
  'UT': [UT],
  'WI': [WI],
  'WY': [WY],

  // Two witnesses and notary states - 3
  'MO': [Witness, Notary],
  'NC': [Witness, Notary],
  'WV': [Witness, Notary],

  // Two witnesses, notary recommended states - 37
  'AK': [NotaryRec],
  'AR': [NotaryRec],
  'AZ': [NotaryRec],
  'CO': [NotaryRec],
  'CT': [NotaryRec],
  'DC': [NotaryRec],
  'DE': [NotaryRec],
  'FL': [NotaryRec],
  'GA': [NotaryRec],
  'HI': [NotaryRec],
  'IA': [NotaryRec],
  'ID': [NotaryRec],
  'IL': [NotaryRec],
  'IN': [NotaryRec],
  'KS': [NotaryRec],
  'KY': [NotaryRec],
  'LA': [NotaryRec],
  'MA': [NotaryRec],
  'MD': [NotaryRec],
  'ME': [NotaryRec],
  'MI': [NotaryRec],
  'MN': [NotaryRec],
  'MT': [NotaryRec],
  'ND': [NotaryRec],
  'NH': [NotaryRec],
  'NJ': [NotaryRec],
  'NM': [NotaryRec],
  'NV': [NotaryRec],
  'NY': [NotaryRec],
  'OK': [NotaryRec],
  'OR': [NotaryRec],
  'PA': [NotaryRec],
  'RI': [NotaryRec],
  'SD': [NotaryRec],
  'TX': [NotaryRec],
  'VA': [NotaryRec],
  'VT': [NotaryRec],
  'WA': [NotaryRec],
}
