import React, { useContext, useState, useMemo } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Form, Field } from 'react-final-form'
import cn from 'classnames'


import FormFields from '../directive/blocks/FormFields'
import FormSelect from '../directive/blocks/FormSelect'
import SurveyNav from './blocks/SurveyNav'
import { required } from '../inputs/validators'
import { getStatesOptions } from '../constants/UsStates'
import { formatPhoneNumber } from '../inputs/formatters'


import Modal from './blocks/Modal'
import Layout from './blocks/Layout'
import Container from './blocks/Container'
import Button from './blocks/Button'
import TileGroup from './blocks/TileGroup'
import Tile from './blocks/Tile'
import Columns from './blocks/Columns'
import ColumnTop from './blocks/ColumnTop'
import ColumnLeft from './blocks/ColumnLeft'

import { forAuth, UserContext } from '../hoc'
import { useTranslation } from 'react-i18next'
import PageText from './blocks/PageText'
import PageTitle from './blocks/PageTitle'
import { statesWithESign } from '../constants/statesWithESign'
import { SEND_SCHEDULE_ESIGN_EMAIL, UPDATE_MY_PROFILE, CREATE_AND_UPLOAD_PDF_BASED_ON_ANSWERS, UPDATE_ACTON_EXECUTE_DIRECTIVE } from '../common/mutations'
import ButtonGroup from './blocks/ButtonGroup'
import { ReactComponent as ArrowBack } from '../assets/images/arrow-back.svg'
import { isPresignedURLExpired } from './hooks/isPresignedUrlExpired'
import { showError } from '../common/toastUtils'

import { getInstructionImage } from './hooks/getInstructionImage'


const ConfirmState = (props) => {
    const { t } = useTranslation()
    const { profile } = useContext(UserContext)
    const [updateProfile] = useMutation(UPDATE_MY_PROFILE)
    const [createAndUploadPdfBasedOnAnswers] = useMutation(CREATE_AND_UPLOAD_PDF_BASED_ON_ANSWERS)


    const { state, setState, setStateIsConfirmed, setDownloadURL } = props
    const statesOptions = useMemo(() => getStatesOptions(t), [t])
    const [pdfIsGenerating, setPdfIsGenerating] = useState(false)
    const profileState = profile.residenceState


    const handleClick = async (value) => {
        setPdfIsGenerating(true)

        const selectedState = value.residenceState.value
        setState(selectedState)

        if (selectedState !== profileState) {
            const values = {
                variables: {
                    email: profile.email,
                    firstName: profile.firstName,
                    lastName: profile.lastName,
                    residenceState: selectedState,
                    dateOfBirth: profile.dateOfBirth ? profile.dateOfBirth : null,
                },
            }

            const response = await updateProfile(values)
            const errors = response.data.updateMyProfile.errors

            if (errors.length > 0) {
                showError(t('executeAD.updateStateError'))
                return errors
            }
        }
        const res = await createAndUploadPdfBasedOnAnswers()
        const errors = res.data.createAndUploadPdfBasedOnAnswers.errors

        if (errors.length > 0) {
            showError(t('executeAD.createPdfError'))
            return errors
        }

        setDownloadURL(res.data.createAndUploadPdfBasedOnAnswers.downloadURL)
        setTimeout(() => {
            setPdfIsGenerating(false)
            setStateIsConfirmed(true)
        }, 2000)
    }


    return (
        <div className="page-confirm-state">
            <Container>
                <ColumnTop>
                    <PageTitle params={{ text: t('confirmState.title') }} />
                </ColumnTop>
                <Columns>
                    <ColumnLeft>
                        <PageText params={{ text: t('confirmState.congratulations') }} />
                        <PageText params={{ text: t('confirmState.makeLegalText') }} />
                        <PageText params={{ text: t('confirmState.explanation') }} />
                        <TileGroup white >
                            <Tile
                                title={t('confirmState.selectStateTitle')}
                                white
                            >
                                <PageText params={{ text: t('confirmState.selectStateText') }} />

                                <Form
                                    onSubmit={handleClick}
                                    render={({ submitting, handleSubmit }) => (
                                        <form
                                            onSubmit={handleSubmit}
                                        >
                                            <FormFields>
                                                <Field
                                                    name="residenceState"
                                                    initialValue={state}
                                                    validate={required}
                                                >
                                                    {({ input, meta }) => (
                                                        <FormSelect
                                                            label={t('confirmState.dropdownLabel')}
                                                            options={statesOptions}
                                                            placeholder={t('form.statePlaceholder')}
                                                            white
                                                            input={input}
                                                            meta={meta}
                                                        />
                                                    )}
                                                </Field>
                                                <ButtonGroup>
                                                    <Button
                                                        className="full-size"
                                                        isLoading={pdfIsGenerating}
                                                        loaderColor="gray"
                                                    >
                                                        <span className="button_text">{t('confirmState.confirmed')}</span>
                                                    </Button>
                                                </ButtonGroup>
                                            </FormFields>
                                        </form>
                                    )}
                                />
                            </Tile>
                        </TileGroup>
                    </ColumnLeft>
                </Columns>
            </Container>
        </div >
    )
}


const InstructionsForExecutionOfflineComponent = (props) => {
    const { state, downloadURL } = props
    const { t } = useTranslation()
    const [updateActonExecuteDirective] = useMutation(UPDATE_ACTON_EXECUTE_DIRECTIVE)
    const [signingInstructionsModalOpen, setSigningInstructionsModalOpen] = useState(false)
    const currentDocumentType = 'AdvanceDirective'

    const signingInstructionsImages = getInstructionImage(state)

    const downloadCurrentAD = async (url) => {
        if (isPresignedURLExpired(url)) {
            showError(t('executeAD.urlExpiredError'))
        } else {
            updateActonExecuteDirective(
                {
                    variables: { dType: currentDocumentType },
                })
            window.location.href = url
        }
    }
    return (
        <div>
            <Button
                className="full-size"
                onClick={() => downloadCurrentAD(downloadURL)}
            >
                {t('executeAD.inWritingDownload')}
            </Button>
            <PageText params={{ text: t('executeAD.inWritingMidText') }} />
            <Button className="-link-flex" to={''} onClick={() => setSigningInstructionsModalOpen(true)} >
                <span className="button_text">{t('executeAD.inWritingSigningRequirements')}{t(`constants.states.${state}`)}</span>
            </Button>
            <PageText params={{ text: t('executeAD.inWritingBottomText') }} />
            {signingInstructionsModalOpen &&
                <Modal
                    onRequestClose={() => setSigningInstructionsModalOpen(false)}
                    isOpen={true}
                    id='modal-image'
                >
                    {signingInstructionsImages.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt="Instructions for signing Directive"
                            className='instruction-image'
                        />
                    ))}
                </Modal>
            }
        </div>
    )
}

const _ExecuteDirective = () => {
    const { t } = useTranslation()

    const me = useContext(UserContext)

    const [executeInWritingExpanded, setExecuteInWritingExpanded] = useState(false)
    const [executeByESignExpanded, setExecuteByESignExpanded] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [state, setState] = useState('')
    const [downloadURL, setDownloadURL] = useState('')
    const [stateIsConfirmed, setStateIsConfirmed] = useState(false)
    const [showEmailIsSentModal, setShowEmailIsSentModal] = useState(false)
    const [sendScheduleEsignEmail] = useMutation(SEND_SCHEDULE_ESIGN_EMAIL)

    const canESign = statesWithESign.includes(state)

    const onExecuteInWritingExpand = () => {
        setExecuteInWritingExpanded(!executeInWritingExpanded)
    }
    const onExecuteByESignExpand = () => {
        setExecuteByESignExpanded(!executeByESignExpanded)
    }

    const handleSchedulingEmailButton = async (e) => {
        const { firstName, lastName } = me.profile
        const fullName = `${firstName} ${lastName}`

        const res = await sendScheduleEsignEmail({
            variables: { fullName: fullName, phoneNumber: phoneNumber, state: state, email: me.profile.email },
        })
        const errors = res.data.sendScheduleEsignEmail.errors
        if (errors.length > 0) {
            showError(t('executeAD.sendEmailError'))
            return errors
        }
        setShowEmailIsSentModal(true)
    }




    return (
        <Layout>
            {!stateIsConfirmed &&
                <ConfirmState state={state} setState={setState} setStateIsConfirmed={setStateIsConfirmed} setDownloadURL={setDownloadURL} />
            }
            {stateIsConfirmed &&
                <div className="page-execute-ad">
                    <Container>
                        <ColumnTop>
                            <PageTitle params={{ text: t('executeAD.title') }} />
                        </ColumnTop>
                        <Columns>
                            <ColumnLeft>
                                {canESign ? t('executeAD.eSignHeader') : t('executeAD.inWritingHeader')}
                                {!canESign &&
                                    <TileGroup white>
                                        <Tile white>
                                            <InstructionsForExecutionOfflineComponent state={state} downloadURL={downloadURL} />
                                        </Tile>
                                    </TileGroup>
                                }

                                {canESign &&
                                    <TileGroup white >
                                        <Tile
                                            title={t('executeAD.inWriting')}
                                            expandable
                                            expanded={executeInWritingExpanded}
                                            onTitleClick={onExecuteInWritingExpand}
                                            white
                                        >
                                            <PageText params={{ text: t('executeAD.inWritingTopText') }} />
                                            <InstructionsForExecutionOfflineComponent state={state} downloadURL={downloadURL} />
                                        </Tile>
                                        <Tile
                                            title={t('executeAD.eSign')}
                                            expandable
                                            expanded={executeByESignExpanded}
                                            onTitleClick={onExecuteByESignExpand}
                                            white
                                        >
                                            <PageText params={{ text: t('executeAD.eSignTopText') }} />
                                            <PageText params={{ text: t('executeAD.eSignMidText') }} />
                                            <PageText params={{ text: t('executeAD.eSignBottomText') }} />
                                            <Form
                                                onSubmit={handleSchedulingEmailButton}
                                                render={({ handleSubmit }) => (
                                                    <form
                                                        onSubmit={handleSubmit}
                                                    >
                                                        <FormFields>

                                                            <Field name={'[phone]'} format={formatPhoneNumber}>
                                                                {({ phoneNumber }) => (
                                                                    <div className='telephone-input-wrapper'>
                                                                        <label className='form-control_label'>{t('form.mainPhoneLabel')}</label>
                                                                        <input
                                                                            className='telephone-input'
                                                                            placeholder={t('form.mainPhonePlaceholder')}
                                                                            maxLength="14"
                                                                            type="tel"
                                                                            value={phoneNumber}
                                                                            onChange={e => setPhoneNumber(e.target.value, phoneNumber)}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Field>
                                                            <ButtonGroup>
                                                                <Button className="-inside-container">
                                                                    {t('executeAD.eSignScheduleCall')}
                                                                </Button>
                                                            </ButtonGroup>
                                                        </FormFields>
                                                    </form>
                                                )}
                                            />
                                        </Tile>
                                    </TileGroup>
                                }
                            </ColumnLeft>
                        </Columns>
                    </Container>
                    <SurveyNav>
                        <Container className="survey-nav_container">
                            <ButtonGroup className={cn('-survey-nav')}>
                                <ButtonGroup className="-survey-next-btns">
                                    <Button to="/documents">
                                        {t('executeAD.inWritingGoToDocuments')}
                                    </Button>
                                </ButtonGroup>
                                <Button
                                    className="-link"
                                    onClick={() => setStateIsConfirmed(false)}
                                >
                                    <span className="button_icon-wrap -arrow-back">
                                        <ArrowBack className="button_icon icon -arrow-back" />
                                    </span>
                                    <span className="button_text">{t('common.directive.navigation.previousStep')}</span>
                                </Button>
                            </ButtonGroup>
                        </Container>
                    </SurveyNav>
                    {showEmailIsSentModal &&
                        <Modal
                            title={t('executeAD.eSignEmailSent')}
                            onRequestClose={() => setShowEmailIsSentModal(false)}
                            isOpen={true}
                        />
                    }
                </div>
            }
        </Layout>
    )
}
const ExecuteDirective = forAuth(_ExecuteDirective)
export default ExecuteDirective
