import React from 'react'
import cn from 'classnames'
import FormControl from './FormControl'
import { useTranslation } from 'react-i18next'

const FormInput = ({ label, placeholder, value, input, meta, white, noMargin, onChange }) => {
  const { t } = useTranslation()

  const invalid = (meta && meta.touched && meta.error) || (meta && !!meta.submitError)
  const validationResult = invalid ? meta.error || meta.submitError : undefined
  const error = typeof validationResult === 'function' ? validationResult(t) : validationResult

  return (
    <FormControl
      className={cn('form-textarea', { '-white': white })}
      label={label}
      noMargin={noMargin}
      invalid={invalid}
      error={error}
    >
      <textarea
        className="form-input_textarea form-control_textarea"
        value={value}
        {...input}
        placeholder={placeholder}
        onChange={!meta && onChange ? onChange : input.onChange}
      />
    </FormControl>
  )
}

export default FormInput
