import React from 'react'
import { map, filter, compose, reject, join, either, isNil, isEmpty } from 'ramda'
import DefinitionsList from '../DefinitionsList'
import { useTranslation } from 'react-i18next'

const joinNotEmpty = compose(join(', '), reject(either(isNil, isEmpty)))

const getProxyDefinitions = (t) => ({ firstName, lastName, email, address, city, state, zip }) => [
  {
    term: t('form.firstNameLabel'),
    definition: firstName,
  },
  {
    term: t('form.lastNameLabel'),
    definition: lastName,
  },
  {
    term: t('form.emailLabel'),
    definition: email || '-',
  },
  {
    term: t('form.addressLabel'),
    definition: address || '-',
  },
  {
    term: t('form.cityStateZipLabel'),
    definition: joinNotEmpty([city, state && state.value, zip]) || '-',
  },
]

export const ProxiesSummary = ({ answers }) => {
  const { t } = useTranslation()

  const { primary, secondary } = answers
  const proxies = filter(Boolean, [primary, secondary])
  const proxiesData = map(getProxyDefinitions(t), proxies)

  return (
    <>
      {proxiesData.map((proxy, index) => (
        <DefinitionsList items={proxy} key={index} className={index > 0 && '-with-margin-top'} />
      ))}
    </>
  )
}
