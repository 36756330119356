import PageTitle from '../blocks/PageTitle'
import PageIntro from '../blocks/PageIntro'
import PageText from '../blocks/PageText'
import PageSeparator from '../blocks/PageSeparator'
import QuestionText from '../blocks/QuestionText'
import ChooseOne from '../blocks/ChooseOne'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { BASE_TYPE } from '../../constants/productTypes'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswerByQid } from '../TheDirective'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { ProxyPage } from './ProxyPage'

const WELCOME_URL = 'welcome'
const WELCOME_QID = 'welcome'

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(BASE_TYPE))

const WelcomePage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: WELCOME_URL,
    getPrevStep: () => ({
      page: null,
      url: null,
    }),
    getNextStep: () => ({
      page: ProxyPage(t),
      url: `${DIRECTIVE_URL}/${ProxyPage(t).slug}`,
    }),
    setInitialValues: (answers) => ({
      [WELCOME_QID]: getAnswerByQid(WELCOME_QID)(answers),
    }),
    serializeValues: (values) => ({
      variables: {
        qid: WELCOME_QID,
        val: values[WELCOME_QID],
      },
      update: updateCacheAnswers,
    }),
    validate: (values) => {
      return values[WELCOME_QID] ? {} : { formError: t('directive.base.page.welcome.validate.formError') }
    },
    isRequired: false,
    isAnswered: (answers) => !!getAnswerByQid(WELCOME_QID)(answers),
    summary: null,
    dashboard: null,
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.base.page.welcome.blocks.pageTitle'),
        },
      },
      {
        component: PageIntro,
        params: {
          text: t('directive.base.page.welcome.blocks.pageIntro'),
        },
      },
      {
        component: PageText,
        params: {
          text:
            `__*${t('directive.base.page.welcome.blocks.pageText.heading')}*__\n\n` +
            `1. ${t('directive.base.page.welcome.blocks.pageText.item1')}\n` +
            `2. ${t('directive.base.page.welcome.blocks.pageText.item2')}\n` +
            `3. ${t('directive.base.page.welcome.blocks.pageText.item3')}\n`,
        },
        columnRight: true,
      },
      {
        component: PageSeparator,
      },
      {
        component: QuestionText,
        params: {
          heading: t('directive.base.page.welcome.blocks.questionText.heading'),
          text: t('directive.base.page.welcome.blocks.questionText.text'),
        },
      },
      {
        component: ChooseOne,
        params: {
          id: WELCOME_QID,
          options: answersOptions[WELCOME_QID].options,
          alternativeOption: true,
        },
      },
    ],
  }
}

export { WelcomePage }
