import React from 'react'
import Container from '../Container'
import LandingTitle from './LandingTitle'
import LandingColumns from './LandingColumns'
import LandingColumnLeft from './LandingColumnLeft'
import LandingColumnRight from './LandingColumnRight'

import { ReactComponent as PicPeople } from '../../../assets/images/pic-people.svg'

import '../../../styles/blocks/landing/why.scss'
import { useTranslation } from 'react-i18next'

const WhyComponent = () => {
  const { t } = useTranslation()

  return (
    <div className="why">
      <Container className="why_container -size-landing">
        <div className="why_tile">
          <LandingColumns className="why_columns">
            <LandingColumnLeft>
              <LandingTitle title={t('landing.why.title')} />
              <div className="why_text">
                <p>{t('landing.why.text')}</p>
              </div>
            </LandingColumnLeft>
            <LandingColumnRight>
              <PicPeople className="why_pic pic -pic-people" />
            </LandingColumnRight>
          </LandingColumns>
        </div>
      </Container>
    </div>
  )
}

export default WhyComponent
