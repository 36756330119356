import React from 'react'
import cn from 'classnames'

import '../../../styles/blocks/landing/landing-columns.scss'

const LandingColumns = ({ className, children }) => (
  <div className={cn('landing-columns', className)}>
    {children}
  </div>
)

export default LandingColumns
