import { assocPath, append, reject, propEq, path, find } from 'ramda'
import { GET_ME } from './queries'

const updateCacheCreateArrayItem = (mutationName, queryFieldName, mutationFieldName = 'result') => (
  store,
  { data },
) => {
  const queryData = store.readQuery({ query: GET_ME })
  const mutationResult = path([mutationName, mutationFieldName], data)

  if (mutationResult) {
    store.writeQuery({
      query: GET_ME,
      data: assocPath(
        ['me', queryFieldName],
        append(mutationResult, path(['me', queryFieldName], queryData)),
        queryData,
      ),
    })
  }
}

const updateCacheCreateOrUpdateArrayItem = (mutationName, queryFieldName, mutationFieldName = 'result') => (
  store,
  { data },
) => {
  const queryData = store.readQuery({ query: GET_ME })
  store.writeQuery({
    query: GET_ME,
    data: assocPath(
      ['me', queryFieldName],
      append(
        path([mutationName, mutationFieldName], data),
        reject(
          propEq('id', path([mutationName, mutationFieldName, 'id'], data)),
          path(['me', queryFieldName], queryData),
        ),
      ),
      queryData,
    ),
  })
}

const updateCacheRemoveArrayItem = (mutationName, queryFieldName, mutationFieldName = 'proxy') => (store, { data }) => {
  const queryData = store.readQuery({ query: GET_ME })

  store.writeQuery({
    query: GET_ME,
    data: assocPath(
      ['me', queryFieldName],
      reject(
        propEq('id', path([mutationName, mutationFieldName, 'id'], data)),
        path(['me', queryFieldName], queryData),
      ),
    )(queryData),
  })
}

const updateCacheCreateOrUpdateItem = (mutationName, queryFieldName, mutationFieldName = 'result') => (
  store,
  { data },
) => {
  const queryData = store.readQuery({ query: GET_ME })
  store.writeQuery({
    query: GET_ME,
    data: assocPath(['me', queryFieldName], path([mutationName, mutationFieldName], data), queryData),
  })
}

export const clearCacheAnswers = (store, { data }) => {
  const queryData = store.readQuery({ query: GET_ME })
  const storeAnswers = queryData.me.answers
  const clearedAnswers = data.clearAnswers.answers

  const isClearedAnswer = ({ questionIdentifier }) =>
    find(propEq('questionIdentifier', questionIdentifier), clearedAnswers)
  const resultAnswers = reject(isClearedAnswer, storeAnswers)

  store.writeQuery({
    query: GET_ME,
    data: assocPath(['me', 'answers'], resultAnswers, queryData),
  })
}

export const updateProfileLocaleCache = (store, { data }) => {
  const queryData = store.readQuery({ query: GET_ME })

  store.writeQuery({
    query: GET_ME,
    data: assocPath(['me', 'profile', 'locale'], data.changeLocale.user.locale, queryData),
  })
}

// Share mutation generator
// `mutationName` and `queryFieldName` is passed from caller, `mutationFieldName` is default to `result`
export const updateCacheSharedWith = updateCacheCreateArrayItem

// `createProxy` mutation responds with `proxy` payload
// proxies is stored in `me.proxies`
export const updateCacheAddProxy = updateCacheCreateArrayItem('createProxy', 'proxies', 'proxy')

export const updateCacheUpdateProxy = updateCacheCreateOrUpdateItem('updateProxy', 'proxies', 'proxy')

// `deleteProxy` mutation responds with `proxy` payload
// proxies is stored in `me.proxies`
export const updateCacheDeleteProxy = updateCacheRemoveArrayItem('deleteProxy', 'proxies', 'proxy')

export const updateCacheReminder = updateCacheCreateOrUpdateItem('createOrUpdateReminder', 'reminder', 'reminder')

export const updateCacheAnswers = updateCacheCreateOrUpdateArrayItem('submitDirectiveAnswer', 'answers', 'answer')
