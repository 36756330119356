export const BASE_URL = '/'
export const DIRECTIVE_URL = '/directive'
export const DIRECTIVE_SUMMARY_URL = '/directive/summary'
export const EXECUTE_DIRECTIVE_URL = '/directive/execute'
export const DIRECTIVE_SLUG_URL = '/directive/:slug'
export const SIGNIN_URL = '/login'
export const SIGNUP_URL = '/signup'
export const PASSWORD_RESET_URL = '/password/reset'
export const PASSWORD_RECOVERY_URL = '/password/recovery'
export const PASSWORD_CHANGE_URL = '/password/change'
export const TERMS_OF_SERVICE_URL = '/terms'
export const PRIVACY_POLICY_URL = '/privacy'
export const DASHBOARD_URL = '/dashboard'
export const SHARE_URL = '/share'
export const PROFILE_URL = '/profile'
export const PROFILE_EDIT_URL = '/profile/edit'
export const DOCUMENTS_URL = '/documents'
