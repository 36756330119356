import React from 'react'
import cn from 'classnames'
import MD from '../Markdown'

import '../../../styles/blocks/landing/landing-title.scss'

const PageTitle = ({ title, subtitle, className }) => (
    <div className={cn('landing-title', className)}>
      <h1 className="landing-title_heading">
        <MD>{title}</MD>
      </h1>
      {subtitle &&
        <p className="landing-title_subtitle">
          <MD>{subtitle}</MD>
        </p>
      }
    </div>
  )

export default PageTitle
