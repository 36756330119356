import React, { createContext } from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import PulseLoader from 'react-spinners/PulseLoader'
import { startsWith } from 'ramda'

import Overlay from './directive/blocks/Overlay'
import { GET_ME } from './common/queries'
import { getAuthToken, clearStorage, REQUESTS, POST, redirectToLogin, setAuthToken, isPublicPage } from './common/requests'
import { PASSWORD_CHANGE_URL } from './constants/UrlConstants'
import i18n from './i18n'
import { pathnameClearedFromPrefix } from './common/organizationTheme'

export const UserContext = createContext(null)

const Authenticated = ({ children }) => {
  const { loading, data } = useQuery(GET_ME)

  if (loading) {
    return (
      <Overlay className="-fixed">
        <PulseLoader color="#4155bc" size={26} margin={4} />
      </Overlay>
    )
  }

  const isPasswordChangePage = startsWith(pathnameClearedFromPrefix(), PASSWORD_CHANGE_URL)
  const requiresPasswordChange = data.me.requiresPasswordChange && !isPasswordChangePage

  i18n.changeLanguage(data.me.profile.locale)
  localStorage.setItem('lang', data.me.profile.locale)

  return (
    <UserContext.Provider value={{ ...data.me, currentOrganization: data.currentOrganization }}>
      {requiresPasswordChange ? <Redirect to={PASSWORD_CHANGE_URL} /> : children}
    </UserContext.Provider>
  )
}

export const forAuth = (WrappedComponent) => {
  const hoc = (props) => {
    const token = getAuthToken()

    const validateToken = async (token) => { 
      if ((!token || token === undefined) && !isPublicPage()) {
        clearStorage()
        redirectToLogin()
      } else {
        const data = await POST(REQUESTS.VALIDATE_TOKEN, {
        token:token,
      })()
      if (data.message === 'Unauthorized') {
        clearStorage()
        redirectToLogin()
      }else if (data.message !== 'false') {
        setAuthToken(data.data)
      }
      }
      
    }
    validateToken(token)
    return (
        <Authenticated>
          <WrappedComponent {...props} />
        </Authenticated>
        )
  }
  return hoc
}
