import { parseISO, addSeconds } from 'date-fns'


export const isPresignedURLExpired = url => {
  // Get query parameters
  const params = new Proxy(new URLSearchParams(url), {
      get: (searchParams, prop) => searchParams.get(prop),
  })
  const creationDate = parseISO(params['X-Amz-Date'])
  const expiresInSecs = Number(params['X-Amz-Expires'])

  const expiryDate = addSeconds(creationDate, expiresInSecs)
  const isExpired = expiryDate < new Date()
  return isExpired
}
