import React from 'react'
import { useTranslation } from 'react-i18next'

import Layout from '../directive/blocks/Layout'
import PageTitle from '../directive/blocks/PageTitle'
import Container from '../directive/blocks/Container'
import { TERMS_OF_SERVICE_URL } from '../constants/UrlConstants'
import ScrollToHash from '../inputs/ScrollToHash'

import PrivacyText from './PrivacyText'

import '../styles/blocks/common-page-text.scss'

const PrivacyPolicy = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <Container>
        <ScrollToHash />
        <PageTitle params={{ text: t('privacyPolicy.title') }} />

        <div className="common-page-text">
          <PrivacyText termsUrl={TERMS_OF_SERVICE_URL} />
        </div>
      </Container>
    </Layout>
  )
}

export default PrivacyPolicy
