import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import ChooseOneFancy from '../blocks/ChooseOneFancy'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswerByQid } from '../TheDirective'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { BASE_TYPE } from '../../constants/productTypes'
import { MyValuesPage } from './MyValuesPage'
import { MedicalInterventionsPage } from './MedicalInterventionsPage'
import { GoalsOfCareSummary } from '../blocks/summary/GoalsOfCareSummary'

const GOALS_OF_CARE_URL = 'goals-of-care'
const GOALS_OF_CARE_QID = 'carepathwayoption'

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(BASE_TYPE))

const GoalsOfCarePage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: GOALS_OF_CARE_URL,
    getPrevStep: () => ({
      page: MyValuesPage(t),
      url: `${DIRECTIVE_URL}/${MyValuesPage(t).slug}`,
    }),
    getNextStep: () => ({
      page: MedicalInterventionsPage(t),
      url: `${DIRECTIVE_URL}/${MedicalInterventionsPage(t).slug}`,
    }),
    setInitialValues: (answers) => ({
      [GOALS_OF_CARE_QID]: getAnswerByQid(GOALS_OF_CARE_QID)(answers),
    }),
    serializeValues: (values) => ({
      variables: {
        qid: GOALS_OF_CARE_QID,
        val: values[GOALS_OF_CARE_QID],
      },
      update: updateCacheAnswers,
    }),
    validate: (values) => {
      return values[GOALS_OF_CARE_QID] ? {} : { formError: t('directive.base.page.goalsOfCare.validate.formError') }
    },
    isRequired: true,
    isAnswered: (answers) => !!getAnswerByQid(GOALS_OF_CARE_QID)(answers),
    dashboard: {
      title: t('directive.base.page.goalsOfCare.dashboardTitle'),
    },
    summary: {
      title: t('directive.base.page.goalsOfCare.summaryTitle'),
      blocks: [
        {
          component: GoalsOfCareSummary,
          params: {
            id: GOALS_OF_CARE_QID,
            options: answersOptions[GOALS_OF_CARE_QID].options,
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.base.page.goalsOfCare.blocks.pageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.base.page.goalsOfCare.blocks.pageText'),
        },
        columnRight: true,
      },
      {
        component: ChooseOneFancy,
        params: {
          id: GOALS_OF_CARE_QID,
          options: answersOptions[GOALS_OF_CARE_QID].options,
        },
      },
    ],
  }
}

export { GoalsOfCarePage, GOALS_OF_CARE_URL, GOALS_OF_CARE_QID }
