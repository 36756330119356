import React from 'react'

const Block = ({ className, block, block: { component: BlockComponent }, handleSubmit, submitting, values, form }) => (
  <BlockComponent
    className={className}
    params={block.params}
    handleSubmit={handleSubmit}
    submitting={submitting}
    values={values}
    form={form}
  />
)

export default Block
