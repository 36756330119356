import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToHash() {
  const { hash } = useLocation()
  const element = hash
    ? document.querySelector(hash)
    : null

  useEffect(() => {
    if (element) {
      element.scrollIntoView(true, { behavior: 'smooth' })
    }

  }, [element])

  return null
}
