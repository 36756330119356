import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../../assets/images/logo-mlv.svg'

import '../../styles/blocks/logo-header.scss'
import { UserContext } from '../../hoc'
import { getInnerPageRedirectUrl } from '../../common/innerPageRedirect'

const LogoHeader = () => {
  const me = useContext(UserContext)
  const url = me ? getInnerPageRedirectUrl(me) : '/'

  return (
    <div className="logo-header">
      <Link to={url} className="logo-header_image">
        <Logo className="logo-header_icon icon icon_logo" />
      </Link>
    </div>
  )
}

export default LogoHeader
