import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import YesIfNoOption, { YES, NO } from '../blocks/YesIfNoOption'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswerByQid } from '../TheDirective'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { BASE_TYPE } from '../../constants/productTypes'
import { GoalsOfCarePage } from './GoalsOfCarePage'
import { GuidanceToMyProxyPage } from './GuidanceToMyProxyPage'
import { YesIfNoSummary } from '../blocks/summary/YesIfNoSummary'
import {
  getCprHintData,
  getDialysisHintData,
  getFeedingTubeHintData,
  getMechventHintData,
} from '../goalsOfCareHintsData'

const MEDICAL_INTERVENTIONS_URL = 'medical-interventions'
const MEDICAL_INTERVENTIONS_QIDS = ['cpr', 'mechvent', 'feedingtube', 'dialysis']

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(BASE_TYPE))

const MedicalInterventionsPage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: MEDICAL_INTERVENTIONS_URL,
    getPrevStep: () => ({
      page: GoalsOfCarePage(t),
      url: `${DIRECTIVE_URL}/${GoalsOfCarePage(t).slug}`,
    }),
    getNextStep: () => ({
      page: GuidanceToMyProxyPage(t),
      url: `${DIRECTIVE_URL}/${GuidanceToMyProxyPage(t).slug}`,
    }),
    setInitialValues: (answers) => {
      const getInitialValue = (qid) => {
        const answer = getAnswerByQid(qid)(answers)
        if (answer) {
          return answer
        }

        const goalsOfCareAnswer = getAnswerByQid('carepathwayoption')(answers)
        if (goalsOfCareAnswer) {
          switch (goalsOfCareAnswer) {
            case '1':
              return { answer: YES }
            case '3':
              return { answer: NO }
          }
        }
      }

      return MEDICAL_INTERVENTIONS_QIDS.reduce((res, qid) => ({ ...res, [qid]: getInitialValue(qid) }), {})
    },
    serializeValues: (values) =>
      MEDICAL_INTERVENTIONS_QIDS.map((qid) => ({
        variables: { qid, val: values[qid] },
        update: updateCacheAnswers,
      })),
    validate: (values) => {
      const invalidQuestionIds = MEDICAL_INTERVENTIONS_QIDS.filter((qid) => !values[qid])
      return invalidQuestionIds.reduce(
        (errors, qid) => ({ ...errors, [qid]: t('directive.base.page.medicalInterventions.validate.formError') }),
        {},
      )
    },
    isRequired: true,
    isAnswered: (answers) => MEDICAL_INTERVENTIONS_QIDS.every((qid) => !!getAnswerByQid(qid)(answers)),
    dashboard: {
      title: t('directive.base.page.medicalInterventions.dashboardTitle'),
    },
    summary: {
      title: t('directive.base.page.medicalInterventions.summaryTitle'),
      blocks: [
        {
          component: YesIfNoSummary,
          params: {
            qids: MEDICAL_INTERVENTIONS_QIDS,
            labels: [
              t('directive.base.page.medicalInterventions.summary.cprLabel'),
              t('directive.base.page.medicalInterventions.summary.mechanicalVentilationLabel'),
              t('directive.base.page.medicalInterventions.summary.feedingTubeLabel'),
              t('directive.base.page.medicalInterventions.summary.dialysisLabel'),
            ],
            options: answersOptions,
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.base.page.medicalInterventions.blocks.pageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.base.page.medicalInterventions.blocks.pageText'),
        },
        columnRight: true,
      },
      {
        component: YesIfNoOption,
        params: {
          id: 'cpr',
          title: t('common.goalsOfCare.card.cpr.title'),
          text: t('common.goalsOfCare.card.cpr.text'),
          hint: getCprHintData(t),
          yesIf: {
            title: t('common.goalsOfCare.card.cpr.yesIf.title'),
            text: t('common.goalsOfCare.card.cpr.yesIf.text'),
            questionChoose: t('common.goalsOfCare.card.cpr.yesIf.questionChoose'),
            questionCustom: t('common.goalsOfCare.card.cpr.yesIf.questionCustom'),
            otherOptionId: answersOptions.cpr.otherOptionId,
            options: answersOptions.cpr.options,
          },
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: 'mechvent',
          title: t('common.goalsOfCare.card.mechvent.title'),
          text: t('common.goalsOfCare.card.mechvent.text'),
          yesIf: {
            title: t('common.goalsOfCare.card.mechvent.yesIf.title'),
            text: t('common.goalsOfCare.card.mechvent.yesIf.text'),
            otherOptionId: answersOptions.mechvent.otherOptionId,
            options: answersOptions.mechvent.options,
            questionChoose: t('common.goalsOfCare.card.mechvent.yesIf.questionChoose'),
            questionCustom: t('common.goalsOfCare.card.mechvent.yesIf.questionCustom'),
          },
          hint: getMechventHintData(t),
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: 'feedingtube',
          title: t('common.goalsOfCare.card.feedingtube.title'),
          text: t('common.goalsOfCare.card.feedingtube.text'),
          yesIf: {
            title: t('common.goalsOfCare.card.feedingtube.yesIf.title'),
            text: t('common.goalsOfCare.card.feedingtube.yesIf.text'),
            otherOptionId: answersOptions.feedingtube.otherOptionId,
            options: answersOptions.feedingtube.options,
          },
          hint: getFeedingTubeHintData(t),
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: 'dialysis',
          title: t('common.goalsOfCare.card.dialysis.title'),
          text: t('common.goalsOfCare.card.dialysis.text'),
          yesIf: {
            title: t('common.goalsOfCare.card.dialysis.yesIf.title'),
            text: t('common.goalsOfCare.card.dialysis.yesIf.text'),
            otherOptionId: answersOptions.dialysis.otherOptionId,
            options: answersOptions.dialysis.options,
          },
          hint: getDialysisHintData(t),
        },
      },
    ],
  }
}

export { MedicalInterventionsPage, MEDICAL_INTERVENTIONS_URL, MEDICAL_INTERVENTIONS_QIDS }
