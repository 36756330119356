import React from 'react'
import cn from 'classnames'

import '../../styles/blocks/form-fields.scss'

const FormFields = ({ className, children }) => (
  <div className={cn('form-fields', className)}>
    {children}
  </div>
)

export default FormFields
