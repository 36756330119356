import React from 'react'
import MD from './Markdown'
import Modal from './Modal'

import '../../styles/blocks/tooltip.scss'

const getModalButton = (title) => (
  <span className="tooltip_toggle" role="button">
    <span className="tooltip_toggle-text">
      <MD>{title}</MD>
    </span>
  </span>
)

const Tooltip = ({ tooltip: { reTitle, title, text } }) => (
  <div className="tooltip">
    <Modal
      title={reTitle}
      button={getModalButton(title)}
    >
      <div className="modal_text">
        <MD allowParagraphs>{text}</MD>
      </div>
    </Modal>
  </div>
)

export default Tooltip
