import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/react-hooks'
import Tile from '../directive/blocks/Tile'
import TileGroup from '../directive/blocks/TileGroup'
import '../styles/blocks/documents.scss'
import Button from '../directive/blocks/Button'
import { showError } from '../common/toastUtils'
import { UPDATE_ACTON_DOWNLOAD_DOCUMENT } from '../common/mutations'
import { isPresignedURLExpired } from '../directive/hooks/isPresignedUrlExpired'




const DocumentArchive = (props) => {
    const { t } = useTranslation()

    const { docs } = props

    const [updateActonDownloadDocument] = useMutation(UPDATE_ACTON_DOWNLOAD_DOCUMENT)

    let currentDocumentVersion = {}
    let previousDocumentVersions = []

    if (docs.length > 0) {
        currentDocumentVersion = docs[docs.length - 1]
        previousDocumentVersions = docs.slice(0, -1)
        previousDocumentVersions.reverse()
    }

    const documentProps = {
        documentId: currentDocumentVersion.id,
        documentType: currentDocumentVersion.dType,
        fileName: currentDocumentVersion.name,
    }

    const americanizeDate = date => {
        const dateArr = date.split('-')
        const newDate = [dateArr[1], dateArr[2], dateArr[0]]
        return newDate.join('/')
    }

    const downloadCurrentDocument = async (url) => {
        if (isPresignedURLExpired(url)) {
            showError(t('executeAD.urlExpiredError'))
        } else {
            updateActonDownloadDocument(
                {
                    variables: { dType: currentDocumentVersion.dType },
                })
            window.location.href = url
        }
    }

    return (
        <div >
            {Object.keys(currentDocumentVersion).length !== 0 && <TileGroup className="activeDocument" white >
                <Tile
                    white
                >
                    {<div>
                        <div className="documentInfo" key={currentDocumentVersion.id}>
                            <div className='documentNameAndDate'>
                                <h4 className='documentName makeGreen'>{currentDocumentVersion.name}</h4>
                                <br></br>
                                <h5 className="documentUpdated">{t('documents.updated')}: {americanizeDate(currentDocumentVersion.documentVersions[0].updatedAt)}</h5>
                            </div>
                            <ul className='documentActionLinks makeGreen'>
                                <a
                                    href={currentDocumentVersion.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="documentActionLink"
                                > {t('documents.view')} |
                                </a>
                                <button
                                    onClick={() => downloadCurrentDocument(currentDocumentVersion.downloadURL)}
                                    className='documentActionLink makeGreen'
                                    id="downloadADButton"
                                >
                                    {t('documents.download')}
                                </button>

                            </ul>
                        </div>
                        <Button
                            type="button"
                            to={{
                                pathname: '/share',
                                state: documentProps,
                            }}
                            className="-inside-container"
                        > {t('documents.share')}
                        </Button>
                    </div>
                    }
                </Tile>
            </TileGroup>
            }
            {previousDocumentVersions.length > 0 && <TileGroup className="activeDocument" white >
                <Tile
                    title={t('documents.archive')}
                    white
                    className="makeGreen"
                >
                    {previousDocumentVersions.map(d => (<div className="documentDisplay" key={`versions-${d.id}`}>
                        <div className="documentInfo documentArchiveInfo">
                            <h4 className="documentName">{d.name}</h4>
                            <h5 className="documentUpdated">{t('documents.updated')}: {americanizeDate(d.documentVersions[0].updatedAt)}</h5>
                        </div>
                        <div className="documentActions">
                            <ul className="documentActionLinks">
                                <a
                                    href={d.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="documentActionLink makeGreen"
                                >{t('documents.view')}
                                </a>
                            </ul>
                        </div>
                    </div>),
                    )}
                </Tile>
            </TileGroup>
            }
        </div>
    )
}
export default DocumentArchive
