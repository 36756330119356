import { WelcomePage } from './WelcomePage'
import { ProxyPage } from './ProxyPage'
import { MyValuesPage } from './MyValuesPage'
import { GoalsOfCarePage } from './GoalsOfCarePage'
import { MedicalInterventionsPage } from './MedicalInterventionsPage'
import { GuidanceToMyProxyPage } from './GuidanceToMyProxyPage'

const getBaseDirectiveSteps = (t = (v) => v) => [
  WelcomePage(t),
  ProxyPage(t),
  MyValuesPage(t),
  GoalsOfCarePage(t),
  MedicalInterventionsPage(t),
  GuidanceToMyProxyPage(t),
]

export { getBaseDirectiveSteps }
