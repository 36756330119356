import { reject, isNil } from 'ramda'
import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import TooltipList from '../blocks/TooltipList'
import IdentifyProxyPage from '../IdentifyProxyPage'
import { getStatesOptions } from '../../constants/UsStates'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { WelcomePage } from './WelcomePage'
import { MyValuesPage } from './MyValuesPage'
import { ProxiesSummary } from '../blocks/summary/ProxiesSummary'

const PROXY_URL = 'identify-proxy'
const DEFAULT_PROXY_VALUES = {
  additionalPhone: null,
  address: null,
  city: null,
  email: null,
  firstName: null,
  isPrimary: null,
  lastName: null,
  phone: null,
  state: null,
  zip: null,
}

const ProxyPage = (t) => {
  const statesOptions = getStatesOptions(t)

  return {
    slug: PROXY_URL,
    getPrevStep: () => ({
      page: WelcomePage(t),
      url: `${DIRECTIVE_URL}/${WelcomePage(t).slug}`,
    }),
    getNextStep: () => ({
      page: MyValuesPage(t),
      url: `${DIRECTIVE_URL}/${MyValuesPage(t).slug}`,
    }),
    setInitialValues: (answers, proxies = []) => {
      const sorted = reject(isNil)(proxies.sort((a, b) => (b.isPrimary ? 1 : -1)))
      const length = sorted.length > 2 ? 2 : sorted.length

      switch (length) {
        case 1:
          return {
            primary: {
              ...sorted[0],
              ...{
                state: statesOptions.find((option) => option.value === sorted[0].state),
              },
            },
            secondary: null,
          }
        case 2:
          return {
            primary: {
              ...sorted[0],
              ...{
                state: statesOptions.find((option) => option.value === sorted[0].state),
              },
            },
            secondary: {
              ...sorted[1],
              ...{
                state: statesOptions.find((option) => option.value === sorted[1].state),
              },
            },
          }
        default:
          return {
            primary: null,
            secondary: null,
          }
      }
    },
    serializeValues: (values) => {
      if (values.primary && values.secondary) {
        return {
          serializedProxies: [
            {
              ...DEFAULT_PROXY_VALUES,
              ...values.primary,
              ...{ isPrimary: true },
              ...{ state: values.primary.state && values.primary.state.value },
            },
            {
              ...DEFAULT_PROXY_VALUES,
              ...values.secondary,
              ...{ isPrimary: false },
              ...{
                state: values.secondary.state && values.secondary.state.value,
              },
            },
          ],
        }
      }

      if (values.primary) {
        return {
          serializedProxies: [
            {
              ...DEFAULT_PROXY_VALUES,
              ...values.primary,
              ...{ isPrimary: true },
              ...{ state: values.primary.state && values.primary.state.value },
            },
          ],
        }
      }
    },
    isRequired: true,
    isAnswered: (answers, proxies) => !!proxies.length,
    dashboard: {
      title: t('directive.base.page.proxy.dashboardTitle'),
    },
    summary: {
      title: t('directive.base.page.proxy.summaryTitle'),
      blocks: [
        {
          component: ProxiesSummary,
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.base.page.proxy.blocks.pageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.base.page.proxy.blocks.pageText'),
        },
        columnRight: true,
      },
      {
        component: TooltipList,
        params: {
          tooltips: [
            {
              title: t('common.proxy.tooltipList.tooltips.0.title'),
              reTitle: t('common.proxy.tooltipList.tooltips.0.reTitle'),
              text: t('common.proxy.tooltipList.tooltips.0.text'),
            },
            {
              title: t('common.proxy.tooltipList.tooltips.1.title'),
              reTitle: t('common.proxy.tooltipList.tooltips.1.reTitle'),
              text: t('common.proxy.tooltipList.tooltips.1.text'),
            },
            {
              title: t('common.proxy.tooltipList.tooltips.2.title'),
              reTitle: t('common.proxy.tooltipList.tooltips.2.reTitle'),
              text: t('common.proxy.tooltipList.tooltips.2.text'),
            },
          ],
        },
        columnRight: true,
      },
      {
        component: IdentifyProxyPage,
        params: {
          id: 'question_2',
        },
      },
    ],
  }
}

export { ProxyPage, PROXY_URL }
