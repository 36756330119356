import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LOCALES } from '../constants/locales'

const PrivacyTextEN = ({ termsUrl = '/terms' }) => {
  const { origin } = document.location

  const LinkToHome = () => <Link to="/">{origin}</Link>
  const LinkToTerms = () => <Link to={termsUrl}>{origin + termsUrl}</Link>

  return (
    <Fragment>
      <p className="common-text_paragraph">
        Welcome! Your use of the Vital Decision, LLC website located at&nbsp;
        <LinkToHome /> and the services made available through the same (collectively, the “Website”) constitutes your
        acceptance of this Privacy Notice, which incorporates by reference our Terms and Conditions of Use located
        at&nbsp;
        <LinkToTerms />. If you do not understand or have questions about the Privacy Notice, immediately stop all use
        of the Website. If you do not accept this Privacy Notice, you may not use the Website. If at any time you do not
        agree to this Privacy Notice, you must cease your use of this Website.
      </p>
      <p className="common-text_paragraph">
        The Company (&quot;we&quot; or &quot;us&quot; or &quot;our&quot;) understands that individuals with whom we
        conduct business and who share their information with us value their privacy. We are committed to taking
        reasonable steps to protect your privacy online and manage your information responsibly. This Privacy Notice is
        intended to notify you of our online privacy practices, the type of information that may be collected and
        stored, how the information is used, with whom the information may be shared, what choices are available to you
        regarding the collection, use and distribution of the information, what kind of security procedures are in place
        to protect the loss, misuse or alteration of information under our control, and who to contact with questions or
        concerns.
      </p>
      <p className="common-text_paragraph">
        This Privacy Notice does not apply to other websites to which we may otherwise link, or to other companies,
        third-party vendors who provide services to us, or entities who might be listed as third-party contacts on this
        Website, each of which may be governed by their own privacy policies. By using the Website and/or by contacting
        us and providing any personal information, you consent to the collection, transfer, storage, disclosure and use
        of information by us in accordance with this Privacy Notice. If we decide to change our Privacy Notice, we will
        post those changes on this page.
      </p>
      <div id="WHY_WE_COLLECT_INFORMATION"></div>
      <h2 className="common-header_h2">Why We Collect Information</h2>
      <p className="common-text_paragraph">
        We collect personal information because it helps us provide and improve our products and services. In addition,
        your personal information helps us communicate to you our latest products, special offers, and information and
        events about which you might like to hear. We may also collect your personal information to help us design and
        deliver our Website, and to deliver targeted marketing to you based on your interests and previous purchases. We
        also may correlate your personal information with information from other sources. For example, we may
        cross-check the personal information you provide to us against information that might be otherwise available
        through other entities in order to prevent or detect fraud.
      </p>
      <div id="HOW_WE_COLLECT_INFORMATION"></div>
      <h2 className="common-header_h2">How We Collect Information</h2>
      <p className="common-text_paragraph">We collect information in three primary ways:</p>
      <p className="common-text_paragraph">
        <b>1. You Give Us Information: </b>We collect information from you when you submit it to us through our Website.
      </p>
      <p className="common-text_paragraph">
        <b>2. We Collect Information Automatically: </b>We automatically collect certain types of information when you
        visit our Website. For example, we automatically collect various types of technical information when you use our
        Website as otherwise described in this Privacy Notice.
      </p>
      <p className="common-text_paragraph">
        <b>3. We Collect Information from Other Sources: </b>We may obtain information about you from outside sources.
        For example, we may obtain commercially available information about you from third parties or purchase e-mail
        lists from third parties for advertising and marketing purposes. We may also receive information from
        third-parties who provide services for us through web-beacons and other technologies as otherwise discussed in
        this Privacy Notice.
      </p>
      <div id="WHAT_INFORMATION_WE_COLLECT"></div>
      <h2 className="common-header_h2">What Information We Collect</h2>
      <p className="common-text_paragraph">We may collect, store and use the following kinds of personal data:</p>
      <ul className="common-text-list">
        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Information required by the Company to provide and deliver its services and products to you.
          </p>
        </li>

        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Information that you provide to us for the purpose of registering with us, and/or subscribing to our
            Website.
          </p>
        </li>

        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Information about your visits to and use of this Website, and information you provide to us via the Website.
          </p>
        </li>
      </ul>
      <p className="common-text_paragraph">
        Even if you do not submit personal information through the Website, we may gather navigational information about
        where visitors go on the Website and information about the technical efficiencies of our Website and services
        (i.e., time to connect to the Website, time to download pages, etc.). Collecting this information allows us to
        see which areas of our Website are most visited and helps us better understand the user experience.
        Additionally, collecting this information helps us improve the quality of the Website by recognizing and
        delivering more of the features, areas, and services our visitors prefer. In this process of gathering
        information, we may collect personally identifiable and non-personally identifiable information (for example,
        domain type, browser type and version, service provider and IP address, referring/exit pages, operating system,
        date/time stamp, and click-stream data). We may also create and use electronic records to compile statistics
        about how our visitors collectively interact with our Website.
      </p>
      <p className="common-text_paragraph">
        <b>Use of Cookies:</b>
      </p>
      <p className="common-text_paragraph">
        Cookies are bits of electronic information that a website may transfer to a visitor’s computer to identify
        specific information about the visitor’s visits to the website. The Website may use a browser feature known as a
        cookie, which assigns a unique identification to your computer. Cookies are typically stored on your computer’s
        hard drive and are used to help track clicks as you go through the pages within our Website. We may also use
        cookies to tell us whether you have previously visited the Website. We also use cookies which may be set by
        third-parties with whom we have entered into agreements which may enable us to obtain analytics information
        about the use of our Website. You can set your browser not to accept cookies or to notify you when you are sent
        a cookie, giving you the opportunity to decide whether or not to accept it. You may also use commonly available
        tools in your browser to remove cookies which may have been placed onto your computer. Shared local objects
        (commonly referred to as “FLASH cookies”) are cookies that are placed on your computer that are not removed
        through normal browser management tools. If we use FLASH cookies, we will only do so in a manner consistent with
        the purposes for which they were designed unless you are specifically notified that we intend to use them for a
        specific service.
      </p>
      <p className="common-text_paragraph">
        <b>Use of Web Beacons, Clear-GIFs, Pixel Tags and JavaScript:</b>
      </p>
      <p className="common-text_paragraph">
        We also may use &quot;pixel tags&quot; (also called &quot;action pixels,&quot; &quot;web beacons&quot; or
        &quot;clear gifs&quot;) and/or JavaScript plug-ins, placed on our Website and in our emails to you. These web
        beacons and plug-ins are small graphic images (typically that you cannot see) or code on a website or in an
        email message which are used for such things as recording webpages and advertisements clicked-on by a user, or
        for tracking the performance of email marketing campaigns. These devices help us analyze our users’ online
        behavior and measure the effectiveness of our website and our marketing. We may also work with third-party
        service providers that help us track, collect, and analyze this information. Third-party entities with whom we
        have agreements may place these devices on the website and/or in emails to use information obtained from them
        such as pages viewed, items purchased, emails opened and items upon which you may click in emails. These
        third-party providers may also place cookies onto your computer. Those third-party cookies may enable us to
        obtain aggregate demographic information and user statistics about you and your preferences from these
        third-party sources as well as our information we have about you.
      </p>
      <p className="common-text_paragraph">
        <b>Server Logs:</b>
      </p>
      <p className="common-text_paragraph">
        A web “server log” is a record of activity created by a computer that delivers certain webpages to your browser.
        Certain activities that you perform on our Website may record information in server logs. For example, if you
        enter a search term into a search box located on the Website, the server log may record the search term, the
        link you clicked on to bring you to our Website and/or information about your browser, such as your IP address
        and the cookies set on your browser.
      </p>
      <p className="common-text_paragraph">
        <b> Search Queries on Our Website:</b>
      </p>
      <p className="common-text_paragraph">
        We may provide you with options to search for information on our site. If you enter information in a search
        query box, we may store that information and we may aggregate that information with other information we may
        have about the browser and/or IP address from which the search query originated.
      </p>
      <div id="HOW_WE_USE_THE_INFORMATION_WE_COLLECT"></div>
      <h2 className="common-header_h2">How We Use the Information We Collect</h2>
      <p className="common-text_paragraph">
        We use the information we collect in a variety of ways. Generally, using all of the above mentioned, and similar
        type tools, we may collect, aggregate, and use de-identified information from or about you such as data about
        the type of browser and operating system used, your geographical location, which webpages you view, the time and
        duration of your visits to our site, the search queries you may use on this site and any other submissions you
        may make, whether you clicked on any items or links on the site, whether you have clicked on any links in any
        emails sent from us, or third-parties on our behalf, whether you have chosen to opt-out of certain services or
        information sharing, and whether you have viewed or ordered certain products or services.
      </p>
      <p className="common-text_paragraph">
        We may use the information we collect internally in our business for many business purposes, such as to: (i)
        provide the products or services you may order, including processing orders and returns; (ii) analyze trends and
        conduct research, including providing aggregate data and trends to our customers; (iii) provide support and
        respond to questions from our users, Website visitors and customers; (iv) improve our Website, products or
        services; (v) learn about users’ and customers’ needs; (vi) contact users for research, informational, and
        marketing purposes, including customizing our Website for you based upon your information and browsing habits;
        (vii) track traffic patterns and site usage; (viii) provide customer service and technical support; (ix)
        correlate information with other commercially available information to identify demographics and preferences to
        assist us and our customers in our research and marketing efforts; (x) provide specific relevant marketing,
        promotional, or other information to you; (xi) address information security and/or privacy practices, network
        functioning, engineering, and troubleshooting issues; (xii) investigate claims and/or legal actions, violations
        of law or agreements, and compliance with relevant applicable laws and legal process; (xiii) comply with law, or
        based on our good faith belief that it is necessary to conform or comply with the law, or otherwise to disclose
        information to prevent fraud to reduce credit risks, to cooperate with police and other governmental
        authorities, or to protect the rights, property or safety of visitors to the Website, our partners or customers,
        or the public; and (xiv) process or engage in a sale of all or part of our business, or if we go through a
        reorganization or merger.
      </p>
      <p className="common-text_paragraph">
        We may also share personally identifiable information and non-personally identifiable information externally
        with our affiliates and business partners, as well as with other third-party service providers who help us
        provide operational services for the site and our business, which might include, but is not necessarily limited
        to: business entities that provide e-mail address management and communication contact services, retargeting
        services, network equipment and application management providers and hosting entities, credit and debit card
        payment gateways and processors and the issuing and acquiring banks involved in the funds settlement procedures
        necessary to charge your cards or financial accounts, entities which obtain information such as demographic
        information which might be aggregated with information we have about you, entities that allow us to personally
        identify visitors to the website, judicial, administrative and/or legal or financial accounting providers in the
        event that information must be reviewed or released in response to civil and/or criminal investigations, claims,
        lawsuits, or if we are subject to judicial or administrative process (such as a subpoena) to release your
        information or to prosecute or defend legal actions, and other service providers which may be involved in the
        other types of services and activities otherwise discussed in this Privacy Notice.
      </p>
      <p className="common-text_paragraph">
        We may also use your personally identifiable information to contact you in ways other than e-mail or regular
        mail, such as via telephone contact or facsimile. All use of personally identifiable information will be as
        permitted by applicable law.
      </p>
      <div id="BUISINESS_TRANSFERS"></div>
      <h2 className="common-header_h2">Business Transfers</h2>
      <p className="common-text_paragraph">
        If the Company or substantially all of its assets were acquired, or in the unlikely event that the Company goes
        out of business or enters bankruptcy, user information would be one of the assets that is transferred or
        acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of the Company
        may continue to use your personal information as set forth in this Privacy Notice.
      </p>
      <div id="YOUR_CHOISES_ABOUT_THE_INFORMATION_WE_COLLECT"></div>
      <h2 className="common-header_h2">Your Choices about the Information We Collect</h2>
      <p className="common-text_paragraph">
        If you do not consent to the way in which we may use your personal information, please do not submit any
        personal information to us. Please note that if you do not provide us with certain personal information the
        Company will be unable to provide you with its services and products.
      </p>
      <p className="common-text_paragraph">
        If you do not wish to receive emails about special offers and other promotions from us, click the unsubscribe
        link located in our emails or opt out in your account profile. If you do not wish to receive other marketing
        materials from us and/or if you do not want us to share your personal information with other entities as stated
        in this Privacy Notice, please provide us with your exact name and address and advise us that you wish to
        opt-out for information sharing or receiving information from us or both as the case may be. Please note that
        de-identified and aggregate data including your personal information may still be shared, but it will not be
        identified or identifiable to you. Please direct your opt-out request to the following contacts:
      </p>
      <p className="common-text_paragraph">
        <b>E-mail Address:</b>

         {/* eslint-disable-next-line react/no-unknown-property */}
        <b address="" email="" here="" insert="" className="text-content">
          privacy@vitaldecisions.net
        </b>
      </p>
      <p className="common-text_paragraph">
        <b>Postal Address:</b>

         {/* eslint-disable-next-line react/no-unknown-property */}
        <b address="" here="" insert="" postal="" className="text-content">
          Security Officer, 379 Thornall Street, 3rd Floor, Edison NJ 08837
        </b>
      </p>
      <div id="PRIVCY_OF_CHILDREN_WHO_VISIT_THE_WEBSITE"></div>
      <h2 className="common-header_h2">Privacy of Children Who Visit the Website</h2>
      <p className="common-text_paragraph">
        We recognize the importance of children&apos;s safety and privacy. The Website is not designed to attract
        children, and is not intended for use by any children under the age of 18. We do not request, or knowingly
        collect, any personally identifiable information from children under the age of 18.
      </p>
      <div id="YOUR_CALIFORNIA_PRIVACY_RIGHTS"></div>
      <h2 className="common-header_h2">Your California Privacy Rights</h2>
      <p className="common-text_paragraph">
        We collect various types of personal information about you during the course of your relationship with us. Under
        California law, if you are a resident of California, you may make a written request to us about how we have
        shared your information with third parties for their direct marketing purposes. In response to your written
        request, we are allowed to provide you with a notice describing the cost-free means to opt-out of our sharing
        your information with third parties with whom we do not share the same brand name, if the third party will use
        such information for its direct marketing purposes.
      </p>
      <p className="common-text_paragraph">
        If you would like to exercise your rights under California law, please send your written request to the e-mail
        address or postal address below. Please include your postal address in your request. Within thirty (30) days of
        receiving your written request, we will provide you with a Third Party Direct Marketing Opt-Out Form so you may
        request that your personal information not be disclosed to third parties for their direct marketing purposes.
      </p>
      <p className="common-text_paragraph">
        <b>E-mail Address:</b>

         {/* eslint-disable-next-line react/no-unknown-property */}
        <b address="" email="" here="" insert="" className="text-content">
          privacy@vitaldecisions.net
        </b>
      </p>
      <p className="common-text_paragraph">
        <b>Postal Address:</b>

         {/* eslint-disable-next-line react/no-unknown-property */}
        <b address="" here="" insert="" postal="" className="text-content">
          Security Officer, 379 Thornall Street, 3rd Floor, Edison NJ 08837
        </b>
      </p>
      <div id="VISITORS_TO_THE_WEBSITE_FROM_OUTSIDE_OF_THE_UNITED_STATES"></div>
      <h2 className="common-header_h2">Visitors to the Website From Outside of the United States</h2>
      <p className="common-text_paragraph">
        If you are visiting the Website from a location outside of the United States, your connection may be through and
        to servers located in the United States. Please note that the data protection laws in the United States and
        other countries might not be the same as those in your country. By using this Website and/or submitting
        information to us, you specifically consent to the transfer of your information to the United States to the
        facilities and servers we use, and to those with whom we may share your information.
      </p>
      <div id="UPDATING_PERSONAL_INFORMATION"></div>
      <h2 className="common-header_h2">Updating Personal Information</h2>
      <p className="common-text_paragraph">
        We prefer to keep your personal information accurate and up-to-date. If you would like to change your contact
        information, please update your account information.
      </p>
      <div id="LINKS"></div>
      <h2 className="common-header_h2">Links</h2>
      <p className="common-text_paragraph">
        For your convenience, the Website may contain links to other websites. We are not responsible for the privacy
        practices, advertising, products, or the content of such other websites. None of the links should be deemed to
        imply that we endorse or have any affiliation with the links.
      </p>
      <div id="SECURITY"></div>
      <h2 className="common-header_h2">Security</h2>
      <p className="common-text_paragraph">
        We believe in providing a safe and secure experience for all of our online visitors. To that end, we have
        implemented security measures to protect the information collected from you. We maintain reasonable physical and
        electronic safeguards designed to limit unauthorized access to your personally identifiable information, and to
        protect you against the criminal misuse of that information.
      </p>
      <p className="common-text_paragraph">
        While we use the foregoing security measures to protect your information, please note that no data transmitted
        over the Internet or stored and utilized for business purposes can be guaranteed to be completely secure. No
        security measures are perfect or impenetrable. We cannot guarantee that only authorized persons will view your
        information. We cannot ensure that information you share on the Website will not become publicly available. You
        can reduce these risks by using common sense security practices such as choosing a strong password, using
        different passwords for different services, and using up to date antivirus software.
      </p>
      <div id="QUESTIONS_CHANGES_IN_NOTES"></div>
      <h2 className="common-header_h2">Questions / Changes in Notice</h2>
      <p className="common-text_paragraph">
        If you have questions or concerns with respect to our Privacy Notice, you may contact us at
        info@mylivingvoice.com. We may elect to change or amend our Privacy Notice; in such event, we will post the
        notice changes in our Privacy Notice on the Website.
      </p>
    </Fragment>
  )
}

const PrivacyTextES = ({ termsUrl = '/terms' }) => {
  const { origin } = document.location

  const LinkToHome = () => <Link to="/">{origin}</Link>
  const LinkToTerms = () => <Link to={termsUrl}>{origin + termsUrl}</Link>

  return (
    <Fragment>
      <p className="common-text_paragraph">
        ¡Bienvenido! Su uso del sitio web de Vital Decision, LLC ubicado en&nbsp;
        <LinkToHome /> y los servicios puestos a disposición a través del mismo (colectivamente, el &quot;Sitio Web&quot;)
        constituye su aceptación de este Aviso de Privacidad, que incorpora por referencia nuestros Términos y Condiciones de Uso ubicados en&nbsp;
        <LinkToTerms />. Si no comprende o tiene preguntas sobre el Aviso de Privacidad, detenga inmediatamente
        todo uso del Sitio Web. Si no acepta este Aviso de Privacidad, no podrá utilizar el Sitio Web. Si en algún
        momento no está de acuerdo con este Aviso de Privacidad, debe dejar de usar este Sitio Web.
      </p>
      <p className="common-text_paragraph">
        La Empresa (&quot;nosotros&quot; o &quot;nos&quot; o &quot;nuestro&quot;) comprende que las personas con las
        que hacemos negocios y que comparten su información con nosotros valoran su privacidad. Estamos comprometidos
        a tomar medidas razonables para proteger su privacidad en línea y gestionar su información de manera responsable.
        Este Aviso de Privacidad está destinado a notificarle sobre nuestras prácticas de privacidad en línea, el tipo
        de información que se puede recopilar y almacenar, como se utiliza la información, con quien se puede compartir
        la información, que opciones están disponibles para usted con respecto a la recopilación, uso y distribución
        de la información, que tipo de procedimientos de seguridad existen para proteger la pérdida, mal uso o alteración
        de la información bajo nuestro control, y a quien contactar si tiene preguntas o inquietudes.
      </p>
      <p className="common-text_paragraph">
        Este Aviso de Privacidad no aplica a otros sitios web a los que de otra manera podríamos vincularnos,
        o a otras empresas, proveedores externos que nos brindan servicios, o entidades que podrían aparecer como
        contactos de terceros en este Sitio Web, cada uno de los cuales puede regirse por sus propias políticas
        de privacidad. Al utilizar el Sitio Web y/o al contactarnos y proporcionar cualquier información personal,
        usted da su consentimiento para la recopilación, transferencia, almacenamiento, divulgación y uso de información
        por nuestra parte de acuerdo con este Aviso de Privacidad. Si decidimos cambiar nuestro Aviso de Privacidad,
        publicaremos esos cambios en esta página.
      </p>
      <div id="WHY_WE_COLLECT_INFORMATION"></div>
      <h2 className="common-header_h2">Por Qué Recopilamos Información</h2>
      <p className="common-text_paragraph">
        Recopilamos información personal porque nos ayuda a proporcionar y mejorar nuestros productos y servicios.
        Además, su información personal nos ayuda a comunicarle nuestros últimos productos, ofertas especiales
        e información y eventos sobre los que le gustaría escuchar. También podemos recopilar su información personal
        para ayudarnos a diseñar y ofrecer nuestro Sitio Web, y para brindarle marketing dirigido en función de sus
        intereses y compras anteriores. También podemos correlacionar su información personal con información de otras fuentes.
        Por ejemplo, podemos cotejar la información personal que nos proporciona con la información que de otro modo
        podría estar disponible a través de otras entidades para prevenir o detectar fraudes.
      </p>
      <div id="HOW_WE_COLLECT_INFORMATION"></div>
      <h2 className="common-header_h2">Cómo Recopilamos la Información</h2>
      <p className="common-text_paragraph">Recopilamos información de tres formas principales:</p>
      <p className="common-text_paragraph">
        <b>1. Usted Nos Brinda Información: </b>Recopilamos información sobre usted cuando nos la envía a través de nuestro Sitio Web.
      </p>
      <p className="common-text_paragraph">
        <b>2. Recopilamos Información Automáticamente: </b>Recopilamos automáticamente ciertos tipos de información
        cuando visita nuestro Sitio Web. Por ejemplo, recopilamos automáticamente varios tipos de información técnica
        cuando utiliza nuestro Sitio Web como se describe en este Aviso de Privacidad.
      </p>
      <p className="common-text_paragraph">
        <b>3. Recopilamos Información de Otras Fuentes: </b>Podemos obtener información sobre usted de fuentes externas.
        Por ejemplo, podemos obtener información comercial sobre usted de terceros o comprar listas de correo electrónico
        de terceros con fines publicitarios y de marketing. También podemos recibir información de terceros que nos
        brindan servicios a través de balizas web y otras tecnologías, como se describe en este Aviso de Privacidad.
      </p>
      <div id="WHAT_INFORMATION_WE_COLLECT"></div>
      <h2 className="common-header_h2">Qué Información Recopilamos</h2>
      <p className="common-text_paragraph">Podemos recopilar, almacenar y utilizar los siguientes tipos de datos personales:</p>
      <ul className="common-text-list">
        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Información requerida por la Empresa para brindarle y proporcionarle sus servicios y productos.
          </p>
        </li>

        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Información que nos proporciona con el fin de registrarse con nosotros y/o suscribirse a nuestro Sitio Web.
          </p>
        </li>

        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Información sobre sus visitas y uso de este Sitio Web, y la información que nos proporciona a través del Sitio Web.
          </p>
        </li>
      </ul>
      <p className="common-text_paragraph">
        Incluso si no envía información personal a través del Sitio Web, podemos recopilar información de navegación
        sobre adónde van los visitantes en el Sitio Web e información sobre las eficiencias técnicas de nuestro Sitio Web
        y nuestros servicios, (es decir, tiempo para conectarse al Sitio Web, tiempo para descargar páginas, etc.).
        La recopilación de esta información nos permite ver que áreas de nuestro Sitio Web son las más visitadas
        y nos ayuda a comprender mejor la experiencia del usuario. Además, la recopilación de esta información nos ayuda
        a mejorar la calidad del Sitio Web al reconocer y ofrecer más funciones, áreas y servicios que prefieren
        nuestros visitantes. En este proceso de obtención de información, podemos recopilar información
        de identificación personal y no identificable personalmente (por ejemplo, tipo de dominio, tipo y versión del navegador,
        proveedor de servicios y dirección IP, páginas de referencia/salida, sistema operativo, sello de fecha/hora
        y datos de flujo de clics). También podemos crear y utilizar registros electrónicos para compilar estadísticas
        sobre como nuestros visitantes interactúan colectivamente con nuestro Sitio Web.
      </p>
      <p className="common-text_paragraph">
        <b>Uso de Cookies:</b>
      </p>
      <p className="common-text_paragraph">
        Las cookies son fragmentos de información electrónica que un sitio web puede transferir a la computadora
        de un visitante para identificar información específica sobre las visitas del visitante al sitio web.
        El Sitio Web puede utilizar una función del navegador conocida como cookie, que asigna una identificación única
        a su computadora. Las cookies generalmente se almacenan en el disco duro de su computadora y se utilizan
        para ayudar a rastrear los clics a medida que recorre las páginas de nuestro Sitio Web. También podemos utilizar
        cookies para decirnos si ha visitado anteriormente el Sitio Web. También utilizamos cookies que pueden ser
        instaladas por terceros con los que hemos celebrado acuerdos que pueden permitirnos obtener información analítica
        sobre el uso de nuestro Sitio Web. Puede configurar su navegador para que no acepte cookies o para que le notifique
        cuando se le envía una cookie, dándole la oportunidad de decidir si la acepta o no. También puede utilizar herramientas
        comúnmente disponibles en su navegador para eliminar las cookies que pueden haber sido colocadas en su computadora.
        Los objetos locales compartidos (comúnmente denominados &quot;cookies FLASH&quot;) son cookies que se colocan en su computadora y
        que no se eliminan a través de las herramientas normales de gestión del navegador. Si usamos cookies FLASH, solo lo haremos
        de manera coherente con los fines para los que fueron diseñadas, a menos que se le notifique específicamente que tenemos
        la intención de usarlas para un servicio específico.
      </p>
      <p className="common-text_paragraph">
        <b>Uso de Balizas Web, GIF transparentes, Etiquetas de Píxeles y JavaScript:</b>
      </p>
      <p className="common-text_paragraph">
        También podemos usar &quot;pixel tags&quot; (también llamadas &quot;píxeles de acción&quot;, &quot;balizas web&quot; o
        &quot;gifs transparentes&quot;) y/o complementos de JavaScript, colocados en nuestro Sitio Web y en nuestros correos
        electrónicos para usted. Estas balizas web y complementos son pequeñas imágenes gráficas
        (por lo general, que no puede ver) o código en un sitio web o en un mensaje de correo electrónico que
        se utilizan para cosas como grabar páginas web y anuncios en los que un usuario hace clic, o para realizar
        un seguimiento del desempeño de las campañas de marketing por correo electrónico. Estos dispositivos nos ayudan
        a analizar el comportamiento en línea de nuestros usuarios y medir la efectividad de nuestro sitio web y nuestro marketing.
        También podemos trabajar con proveedores de servicios externos que nos ayuden a rastrear, recopilar y analizar
        esta información. Las entidades de terceros con las que tenemos acuerdos pueden colocar estos dispositivos
        en el sitio web y/o en correos electrónicos para usar la información obtenida de ellos, tales como
        las páginas visitadas, artículos comprados, correos electrónicos abiertos y artículos en los que puede hacer
        clic en los correos electrónicos. Estos proveedores externos también pueden colocar cookies en su computadora.
        Esas cookies de terceros pueden permitirnos obtener información demográfica agregada y estadísticas de usuario
        sobre usted y sus preferencias de estas fuentes de terceros, así como nuestra información que tenemos sobre usted.
      </p>
      <p className="common-text_paragraph">
        <b>Registros del Servidor:</b>
      </p>
      <p className="common-text_paragraph">
        Un &quot;registro del servidor&quot; web es un registro de actividad creado por una computadora que envía ciertas páginas
        web a su navegador. Ciertas actividades que realiza en nuestro Sitio Web pueden registrar información en los
        registros del servidor. Por ejemplo, si ingresa un término de búsqueda en una casilla de búsqueda ubicada
        en el Sitio Web, el registro del servidor puede registrar el término de búsqueda, el enlace en el que hizo clic
        para llevarlo a nuestro Sitio Web y/o información sobre su navegador, como su dirección IP y las cookies configuradas en su navegador.
      </p>
      <p className="common-text_paragraph">
        <b> Consultas de Búsqueda en Nuestro Sitio Web:</b>
      </p>
      <p className="common-text_paragraph">
        Podemos ofrecerle opciones para buscar información en nuestro sitio. Si ingresa información en una casilla
        de consulta de búsqueda, podemos almacenar esa información y podemos agregar esa información con otra información
        que podamos tener sobre el navegador y/o la dirección IP desde la que se originó la consulta de búsqueda.
      </p>
      <div id="HOW_WE_USE_THE_INFORMATION_WE_COLLECT"></div>
      <h2 className="common-header_h2">Cómo Usamos la Información que Recopilamos</h2>
      <p className="common-text_paragraph">
        Usamos la información que recopilamos de diversas formas. Por lo general, utilizando todas las herramientas
        mencionadas anteriormente y de tipo similar, podemos recopilar, agregar y usar información no identificada
        de usted o sobre usted, como datos sobre el tipo de navegador y sistema operativo utilizado, su ubicación geográfica,
        las páginas web que ve, la hora y la duración de sus visitas a nuestro sitio, las consultas de búsqueda que puede utilizar
        en este sitio y cualquier otro envío que pueda realizar, si hizo clic en algún elemento o enlace en el sitio,
        si ha hecho clic en algún enlace en cualquier correo electrónico que le enviemos, o terceros en nuestro nombre,
        si ha optado por no recibir ciertos servicios o compartir información, y si ha visto u ordenado determinados productos o servicios.
      </p>
      <p className="common-text_paragraph">
        Podemos utilizar la información que recopilamos internamente en nuestro negocio para muchos fines comerciales,
        como para: (i) proporcionar los productos o servicios que puede solicitar, incluido el procesamiento de pedidos y devoluciones;
        (ii) analizar tendencias y realizar investigaciones, incluido el suministro de datos y tendencias agregados a nuestros clientes;
        (iii) brindar soporte y responder a las preguntas de nuestros usuarios, visitantes del Sitio Web y clientes;
        (iv) mejorar nuestro Sitio Web, productos o servicios; (v) conocer las necesidades de los usuarios y los clientes;
        (vi) contactar a los usuarios con fines de investigación, informativos y de marketing, incluida la personalización
        de nuestro Sitio Web para usted en función de su información y hábitos de navegación; (vii) rastrear patrones de tráfico
        y uso del sitio; (viii) proporcionar servicio al cliente y soporte técnico; (ix) correlacionar la información con
        otra información disponible comercialmente para identificar datos demográficos y preferencias para ayudarnos
        a nosotros y a nuestros clientes en nuestros esfuerzos de investigación y marketing; (x) proporcionarle información
        de marketing, promocional u otra información relevante y específica; (xi) abordar las prácticas de seguridad y privacidad
        de la información, el funcionamiento de la red, la ingeniería y los asuntos de resolución de problemas;
        (xii) investigar reclamaciones y/o acciones legales, violaciones de la ley o acuerdos, y cumplimiento
        de las leyes y procesos legales pertinentes; (xiii) cumplir con la ley, o basándonos en nuestra creencia
        de buena fe de que es necesario ajustarse o cumplir con la ley, o de otra manera para divulgar información
        para prevenir el fraude para reducir los riesgos crediticios, para cooperar con la policía y otras autoridades
        gubernamentales, o para proteger los derechos, propiedad o seguridad de los visitantes del Sitio Web, nuestros
        socios o clientes, o el público; y (xiv) procesar o participar en una venta de todo o parte de nuestro negocio,
        o si pasamos por una reorganización o fusión.
      </p>
      <p className="common-text_paragraph">
        También podemos compartir información de identificación personal e información de identificación no personal
        externamente con nuestras afiliadas y socios comerciales, así como con otros proveedores de servicios externos
        que nos ayudan a proporcionar servicios operativos para el sitio y nuestro negocio, que puede incluir,
        pero no necesariamente se limita a: entidades comerciales que brindan gestión de direcciones de correo electrónico
        y servicios de contacto de comunicación, servicios de reposicionamiento, proveedores de gestión de aplicaciones
        y equipos de red y entidades de alojamiento, pasarelas de pago y procesadores de tarjetas de crédito y débito
        y los bancos emisores y adquirentes involucrados en los procedimientos de liquidación de fondos necesarios para
        cargar sus tarjetas o cuentas financieras, entidades que obtienen información como información demográfica que
        podría agregarse con la información que tenemos sobre usted, entidades que nos permiten identificar personalmente
        a los visitantes del sitio web, judicial, proveedores de contabilidad administrativa y/o legal o financiera en caso
        de que la información deba ser revisada o divulgada en respuesta a investigaciones civiles y/o criminales, reclamos,
        demandas o si estamos sujetos a un proceso judicial o administrativo (como una citación) para divulgar su información
        o para enjuiciar o defender acciones legales, y otros proveedores de servicios que puedan estar involucrados en los
        otros tipos de servicios y actividades que de otra manera se tratan en este Aviso de Privacidad.
      </p>
      <p className="common-text_paragraph">
        También podemos utilizar su información de identificación personal para comunicarnos con usted de otras formas
        que no sean el correo electrónico o el correo postal, como por teléfono o fax. Todo uso de la información
        de identificación personal será conforme a lo permitido por la ley aplicable.
      </p>
      <div id="BUISINESS_TRANSFERS"></div>
      <h2 className="common-header_h2">Transferencias Comerciales</h2>
      <p className="common-text_paragraph">
        Si la Empresa o sustancialmente todos sus activos fueran adquiridos, o en el improbable caso de que la Empresa
        cierre el negocio o entre en quiebra, la información del usuario sería uno de los activos que es transferido
        o adquirido por un tercero. Usted reconoce que tales transferencias pueden ocurrir y que cualquier adquirente
        de la Empresa puede continuar usando su información personal como se establece en este Aviso de Privacidad.
      </p>
      <div id="YOUR_CHOISES_ABOUT_THE_INFORMATION_WE_COLLECT"></div>
      <h2 className="common-header_h2">Sus Opciones sobre la Información que Recopilamos</h2>
      <p className="common-text_paragraph">
        Si no acepta la forma en que podemos utilizar su información personal, por favor no nos envíe ninguna
        información personal. Por favor tenga en cuenta que si no nos proporciona cierta información personal,
        la Empresa no podrá brindarle sus servicios y productos.
      </p>
      <p className="common-text_paragraph">
        Si no desea recibir correos electrónicos sobre ofertas especiales y otras promociones de nuestra parte,
        haga clic en el enlace para cancelar la suscripción que se encuentra en nuestros correos electrónicos u opte
        por no participar en el perfil de su cuenta. Si no desea recibir otros materiales de marketing de nuestra parte
        y/o si no desea que compartamos su información personal con otras entidades como se indica en este Aviso de Privacidad,
        por favor indíquenos su nombre y dirección exactos y avísenos que desea optar por no compartir o recibir información
        nuestra o ambos, según sea el caso. Por favor tenga en cuenta que los datos no identificados y agregados,
        incluida su información personal, aún pueden compartirse, pero no serán identificados ni identificables para usted.
        Por favor dirija su solicitud de exclusión voluntaria a los siguientes contactos:
      </p>
      <p className="common-text_paragraph">
        <b>Dirección de Correo Electrónico:</b>

         {/* eslint-disable-next-line react/no-unknown-property */}
        <b address="" email="" here="" insert="" className="text-content">
          privacy@vitaldecisions.net
        </b>
      </p>
      <p className="common-text_paragraph">
        <b>Dirección Postal</b>

         {/* eslint-disable-next-line react/no-unknown-property */}
        <b address="" here="" insert="" postal="" className="text-content">
          Security Officer, 379 Thornall Street, 3rd Floor, Edison NJ 08837
        </b>
      </p>
      <div id="PRIVCY_OF_CHILDREN_WHO_VISIT_THE_WEBSITE"></div>
      <h2 className="common-header_h2">Privacidad de los Niños que Visitan el Sitio Web</h2>
      <p className="common-text_paragraph">
        Reconocemos la importancia de la seguridad y privacidad de los niños. El Sitio Web no está diseñado para atraer
        a niños y no está destinado a ser utilizado por niños menores de 18 años. No solicitamos, ni recopilamos a sabiendas,
        ninguna información de identificación personal de niños menores de 18 años.
      </p>
      <div id="YOUR_CALIFORNIA_PRIVACY_RIGHTS"></div>
      <h2 className="common-header_h2">Sus Derechos de Privacidad en California</h2>
      <p className="common-text_paragraph">
        Recopilamos varios tipos de información personal sobre usted durante el curso de su relación con nosotros.
        Según la ley de California, si es residente de California, puede hacernos una solicitud por escrito sobre
        como hemos compartido su información con terceros para sus fines de marketing directo. En respuesta a su solicitud
        por escrito, se nos permite proporcionarle un aviso que describa los medios gratuitos para optar por no compartir
        su información con terceros con los que no compartimos el mismo nombre de marca, si el tercero utilizará dicha
        información para sus fines de marketing directo.
      </p>
      <p className="common-text_paragraph">
        Si desea ejercer sus derechos conforme a la ley de California, envíe su solicitud por escrito a la dirección
        de correo electrónico o la dirección postal que se muestra a continuación. Por favor incluya su dirección
        postal en su solicitud. Dentro de los treinta (30) días posteriores a la recepción de su solicitud por escrito,
        le proporcionaremos un Formulario de Exclusión Voluntaria de Marketing Directo de Terceros para que pueda solicitar
        que su información personal no se divulgue a terceros para sus fines de marketing directo.
      </p>
      <p className="common-text_paragraph">
        <b>Dirección de Correo Electrónico:</b>

         {/* eslint-disable-next-line react/no-unknown-property */}
        <b address="" email="" here="" insert="" className="text-content">
          privacy@vitaldecisions.net
        </b>
      </p>
      <p className="common-text_paragraph">
        <b>Dirección Postal:</b>

         {/* eslint-disable-next-line react/no-unknown-property */}
        <b address="" here="" insert="" postal="" className="text-content">
          Security Officer, 379 Thornall Street, 3rd Floor, Edison NJ 08837
        </b>
      </p>
      <div id="VISITORS_TO_THE_WEBSITE_FROM_OUTSIDE_OF_THE_UNITED_STATES"></div>
      <h2 className="common-header_h2">Visitantes del Sitio Web Desde Fuera de los Estados Unidos</h2>
      <p className="common-text_paragraph">
        Si visita el Sitio Web desde una ubicación fuera de los Estados Unidos, su conexión puede ser a través y con
        servidores ubicados en los Estados Unidos. Por favor tenga en cuenta que las leyes de protección de datos
        en los Estados Unidos y otros países pueden no ser las mismas que las de su país. Al usar este Sitio Web y/o
        enviarnos información, usted da su consentimiento específicamente para la transferencia de su información
        a los Estados Unidos a las instalaciones y servidores que usamos, y a aquellos con quienes podemos compartir su información.
      </p>
      <div id="UPDATING_PERSONAL_INFORMATION"></div>
      <h2 className="common-header_h2">Actualización de Información Personal</h2>
      <p className="common-text_paragraph">
        Preferimos mantener su información personal precisa y actualizada. Si desea cambiar su información de contacto,
        actualice la información de su cuenta.
      </p>
      <div id="LINKS"></div>
      <h2 className="common-header_h2">Enlaces</h2>
      <p className="common-text_paragraph">
        Para su comodidad, el Sitio Web puede contener enlaces a otros sitios web. No somos responsables de las prácticas
        de privacidad, la publicidad, los productos o el contenido de esos otros sitios web. No se debe considerar que
        ninguno de los enlaces implica que respaldamos o tenemos alguna afiliación con los enlaces.
      </p>
      <div id="SECURITY"></div>
      <h2 className="common-header_h2">Seguridad</h2>
      <p className="common-text_paragraph">
        Creemos en brindar una experiencia segura para todos nuestros visitantes en línea. Con ese fin, hemos implementado
        medidas de seguridad para proteger la información recopilada de usted. Mantenemos medidas de seguridad físicas
        y electrónicas razonables diseñadas para limitar el acceso no autorizado a su información de identificación personal
        y para protegerle contra el uso indebido delictivo de esa información.
      </p>
      <p className="common-text_paragraph">
        Si bien utilizamos las medidas de seguridad anteriores para proteger su información, por favor tenga en cuenta
        que no se puede garantizar que los datos transmitidos a través de Internet o almacenados y utilizados con
        fines comerciales sean completamente seguros. Ninguna medida de seguridad es perfecta o impenetrable.
        No podemos garantizar que solo las personas autorizadas verán su información. No podemos garantizar que
        la información que comparte en el Sitio Web no esté disponible públicamente. Puede reducir estos riesgos mediante
        el uso de prácticas de seguridad de sentido común, como elegir una contraseña segura, usar diferentes contraseñas
        para diferentes servicios y usar un software antivirus actualizado.
      </p>
      <div id="QUESTIONS_CHANGES_IN_NOTES"></div>
      <h2 className="common-header_h2">Preguntas / Cambios en el Aviso</h2>
      <p className="common-text_paragraph">
        Si tiene preguntas o inquietudes con respecto a nuestro Aviso de Privacidad, puede comunicarse con nosotros
        en info@mylivingvoice.com. Podemos optar por cambiar o enmendar nuestro Aviso de Privacidad;
        En tal caso, publicaremos los cambios en el aviso en nuestro Aviso de Privacidad en el Sitio Web.
      </p>
    </Fragment>
  )
}

const PrivacyText = ({ termsUrl = '/terms' }) => {
  const { i18n } = useTranslation()

  switch (i18n.language) {
    case LOCALES.en:
      return <PrivacyTextEN termsUrl={termsUrl} />

    case LOCALES.es:
      return <PrivacyTextES termsUrl={termsUrl} />

    default:
      return <PrivacyTextEN termsUrl={termsUrl} />
  }
}

export default PrivacyText
