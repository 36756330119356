import React from 'react'
import { Field } from 'react-final-form'
import cn from 'classnames'
import MD from './Markdown'

import '../../styles/blocks/selector-radio.scss'

const SelectorRadio = ({ name, value, label, onChange, fancy, className }) => (
  <label className={cn(className, 'selector-radio', { '-fancy': fancy })}>
    <Field name={name} type="radio" value={value}>
      {({ input }) => (
        <input
          className="selector-radio_input"
          {...input}
          onChange={(event) => (onChange ? onChange(event) : input.onChange(event))}
        />
      )}
    </Field>
    <span className="selector-radio_ui">
      <span className="selector-radio_text">
        <MD>{label}</MD>
      </span>
      <span className="selector-radio_checker">
        <span className="selector-radio_cg" />
      </span>
    </span>
  </label>
)

export default SelectorRadio
