import { reject, isNil } from 'ramda'
import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import TooltipList from '../blocks/TooltipList'
import IdentifyProxyPage from '../IdentifyProxyPage'
import { getStatesOptions } from '../../constants/UsStates'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { GuidanceToMyProxyPage } from './GuidanceToMyProxyPage'
import { getAnswerByQid } from '../TheDirective'
import { GOALS_OF_CARE_QID, GoalsOfCarePage } from './GoalsOfCarePage'
import { FTSelectiveMeasuresPage } from './FTSelectiveMeasuresWishesPage'
import { SMComfortCareWishesSecondPage } from './SMComfortCareWishesSecondPage'
import { CCSpecificWishesSecondPage } from './CCSpecificWishesSecondPage'
import TextAnswer from '../blocks/TextAnswer'
import QuestionText from '../blocks/QuestionText'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { ProxiesSummary } from '../blocks/summary/ProxiesSummary'
import { TextAnswerSummary } from '../blocks/summary/TextAnswerSummary'

const PROXY_DESIGNATION_URL = 'identify-proxy'
const PROXY_QUALITIES_QID = 'lwshcp_qualities'
const DEFAULT_PROXY_VALUES = {
  additionalPhone: null,
  address: null,
  city: null,
  email: null,
  firstName: null,
  isPrimary: null,
  lastName: null,
  phone: null,
  state: null,
  zip: null,
}

const ProxyDesignationPage = (t) => {
  const statesOptions = getStatesOptions(t)

  return {
    slug: PROXY_DESIGNATION_URL,
    getPrevStep: (answers) => {
      const value = getAnswerByQid(GOALS_OF_CARE_QID)(answers)
      switch (value) {
        case '1':
          return {
            page: FTSelectiveMeasuresPage(t),
            url: `${DIRECTIVE_URL}/${FTSelectiveMeasuresPage(t).slug}`,
          }
        case '2':
          return {
            page: SMComfortCareWishesSecondPage(t),
            url: `${DIRECTIVE_URL}/${SMComfortCareWishesSecondPage(t).slug}`,
          }
        case '3':
          return {
            page: CCSpecificWishesSecondPage(t),
            url: `${DIRECTIVE_URL}/${CCSpecificWishesSecondPage(t).slug}`,
          }
        default:
          return {
            page: GoalsOfCarePage(t),
            url: `${DIRECTIVE_URL}/${GoalsOfCarePage(t).slug}`,
          }
      }
    },
    getNextStep: () => ({
      page: GuidanceToMyProxyPage(t),
      url: `${DIRECTIVE_URL}/${GuidanceToMyProxyPage(t).slug}`,
    }),
    setInitialValues: (answers, proxies = []) => {
      const sorted = reject(isNil)(proxies.sort((a, b) => (b.isPrimary ? 1 : -1)))
      const length = sorted.length > 2 ? 2 : sorted.length

      switch (length) {
        case 1:
          return {
            [PROXY_QUALITIES_QID]: getAnswerByQid(PROXY_QUALITIES_QID)(answers),
            primary: {
              ...sorted[0],
              ...{
                state: statesOptions.find((option) => option.value === sorted[0].state),
              },
            },
            secondary: null,
          }
        case 2:
          return {
            [PROXY_QUALITIES_QID]: getAnswerByQid(PROXY_QUALITIES_QID)(answers),
            primary: {
              ...sorted[0],
              ...{
                state: statesOptions.find((option) => option.value === sorted[0].state),
              },
            },
            secondary: {
              ...sorted[1],
              ...{
                state: statesOptions.find((option) => option.value === sorted[1].state),
              },
            },
          }
        default:
          return {
            [PROXY_QUALITIES_QID]: getAnswerByQid(PROXY_QUALITIES_QID)(answers),
            primary: null,
            secondary: null,
          }
      }
    },
    serializeValues: (values) => {
      const serializedAnswers = {
        variables: {
          qid: PROXY_QUALITIES_QID,
          val: values[PROXY_QUALITIES_QID] || '',
        },
        update: updateCacheAnswers,
      }
      if (values.primary && values.secondary) {
        return {
          serializedAnswers,
          serializedProxies: [
            {
              ...DEFAULT_PROXY_VALUES,
              ...values.primary,
              ...{ isPrimary: true },
              ...{ state: values.primary.state && values.primary.state.value },
            },
            {
              ...DEFAULT_PROXY_VALUES,
              ...values.secondary,
              ...{ isPrimary: false },
              ...{
                state: values.secondary.state && values.secondary.state.value,
              },
            },
          ],
        }
      }

      if (values.primary) {
        return {
          serializedAnswers,
          serializedProxies: [
            {
              ...DEFAULT_PROXY_VALUES,
              ...values.primary,
              ...{ isPrimary: true },
              ...{ state: values.primary.state && values.primary.state.value },
            },
          ],
        }
      }
    },
    isRequired: true,
    isAnswered: (answers, proxies) => !!proxies.length,
    dashboard: {
      title: t('directive.well.page.proxyDesignation.dashboardTitle'),
    },
    summary: {
      title: t('directive.well.page.proxyDesignation.summaryTitle'),
      blocks: [
        {
          component: ProxiesSummary,
        },
        {
          component: TextAnswerSummary,
          params: {
            id: PROXY_QUALITIES_QID,
            heading: t('directive.well.page.proxyDesignation.summaryHeading'),
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.well.page.proxyDesignation.blocks.title'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.well.page.proxyDesignation.blocks.text'),
        },
        columnRight: true,
      },
      {
        component: TooltipList,
        params: {
          tooltips: [
            {
              title: t('common.proxy.tooltipList.tooltips.0.title'),
              reTitle: t('common.proxy.tooltipList.tooltips.0.reTitle'),
              text: t('common.proxy.tooltipList.tooltips.0.text'),
            },
            {
              title: t('common.proxy.tooltipList.tooltips.1.title'),
              reTitle: t('common.proxy.tooltipList.tooltips.1.reTitle'),
              text: t('common.proxy.tooltipList.tooltips.1.text'),
            },
            {
              title: t('common.proxy.tooltipList.tooltips.2.title'),
              reTitle: t('common.proxy.tooltipList.tooltips.2.reTitle'),
              text: t('common.proxy.tooltipList.tooltips.2.text'),
            },
          ],
        },
        columnRight: true,
      },
      {
        component: IdentifyProxyPage,
        params: {
          id: 'question_2',
        },
      },
      {
        component: QuestionText,
        params: {
          heading: t('directive.well.page.proxyDesignation.blocks.questionText'),
        },
      },
      {
        component: TextAnswer,
        params: {
          id: PROXY_QUALITIES_QID,
        },
      },
    ],
  }
}

export { ProxyDesignationPage, PROXY_DESIGNATION_URL }
