import React from 'react'
import cn from 'classnames'

import '../../styles/blocks/form-control.scss'

const FormControl = ({ label, invalid, error, hint, noMargin, className, children }) => (
  <div className={cn(
    className,
    'form-control',
    { '-invalid': invalid },
    { '-no-margin': noMargin },
  )}>
    {label &&
      <label className="form-control_label">{label}</label>
    }
    <div className="form-control_content">
      {children}
    </div>
    {invalid && error &&
      <span className="form-control_error">{error}</span>
    }
    {hint && !(invalid && error) &&
      <span className={cn('form-control_hint')}>{hint}</span>
    }
  </div>
)

export default FormControl
