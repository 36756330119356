import React from 'react'
import ReactMarkdown from 'react-markdown'
import sanitizeHtml from 'sanitize-html'

const linkRenderer = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
)

const Markdown = ({ children, allowParagraphs, allowExternalLinks, options, ...props }) => {
  let propsMD = props

  if (!allowParagraphs) {
    propsMD = {
      disallowedTypes: ['paragraph'],
      ...props,
    }
  }

  if (allowExternalLinks) {
    propsMD = {
      renderers: {
        link: linkRenderer,
      },
      ...propsMD,
    }
  }

  propsMD = {
    ...propsMD,
    ...options,
  }

  return (
    <ReactMarkdown
      escapeHtml={false}
      unwrapDisallowed={true}
      {...propsMD}
    >
      {sanitizeHtml(children)}
    </ReactMarkdown>
  )
}

export default Markdown
