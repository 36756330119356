import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import YesIfNoOption, { YES } from '../blocks/YesIfNoOption'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswerByQid } from '../TheDirective'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { WELL_SUMMARY_TYPE } from '../../constants/productTypes'
import { SMComfortCareWishesSecondPage } from './SMComfortCareWishesSecondPage'
import { SMChangesPage } from './SMChangesPage'
import { YesIfNoSummary } from '../blocks/summary/YesIfNoSummary'
import {
  getCprHintData,
  getDialysisHintData,
  getFeedingTubeHintData,
  getMechventHintData,
} from '../goalsOfCareHintsData'

const SM_COMFORT_CARE_WISHES_URL = 'sm-cc-goals-of-care-1'
const SM_CC_CPR = 'smtocccpr'
const SM_CC_MECHVENT = 'smtoccmechvent'
const SM_CC_FEEDINGTUBE = 'smtoccfeedingtube'
const SM_CC_DIALYSIS = 'smtoccdialysis'
const SM_CC_QIDS = [SM_CC_CPR, SM_CC_MECHVENT, SM_CC_FEEDINGTUBE, SM_CC_DIALYSIS]

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(WELL_SUMMARY_TYPE))

const SMComfortCareWishesFirstPage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: SM_COMFORT_CARE_WISHES_URL,
    getPrevStep: () => ({
      page: SMChangesPage(t),
      url: `${DIRECTIVE_URL}/${SMChangesPage(t).slug}`,
    }),
    getNextStep: () => ({
      page: SMComfortCareWishesSecondPage(t),
      url: `${DIRECTIVE_URL}/${SMComfortCareWishesSecondPage(t).slug}`,
    }),
    setInitialValues: (answers) => {
      const initialValues = SM_CC_QIDS.reduce((res, qid) => ({ ...res, [qid]: getAnswerByQid(qid)(answers) }), {})
      if (Object.values(initialValues).some(Boolean)) {
        return initialValues
      }
      return {
        [SM_CC_CPR]: getAnswerByQid(SM_CC_CPR)(answers),
        [SM_CC_MECHVENT]: getAnswerByQid(SM_CC_MECHVENT)(answers),
        [SM_CC_FEEDINGTUBE]: getAnswerByQid(SM_CC_FEEDINGTUBE)(answers),
        [SM_CC_DIALYSIS]: getAnswerByQid(SM_CC_DIALYSIS)(answers),
      }
    },
    serializeValues: (values) =>
      SM_CC_QIDS.filter((qid) => values[qid] !== undefined && values[qid].answer !== YES).map((qid) => ({
        variables: { qid, val: values[qid] },
        update: updateCacheAnswers,
      })),
    validate: () => {},
    isRequired: false,
    isAnswered: (answers) => SM_CC_QIDS.some((qid) => !!getAnswerByQid(qid)(answers)),
    dashboard: {
      title: t('directive.well.page.SMComfortCareWishes.dashboardTitle'),
    },
    summary: {
      title: t('directive.well.page.SMComfortCareWishes.summaryTitle'),
      blocks: [
        {
          component: YesIfNoSummary,
          params: {
            qids: SM_CC_QIDS,
            labels: [
              t('directive.well.page.SMComfortCareWishes.summaryLabels.cpr'),
              t('directive.well.page.SMComfortCareWishes.summaryLabels.mechanicalVentilation'),
              t('directive.well.page.SMComfortCareWishes.summaryLabels.feedingTube'),
              t('directive.well.page.SMComfortCareWishes.summaryLabels.dialysis'),
            ],
            options: answersOptions,
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.well.page.SMComfortCareWishes.blocks.pageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.well.page.SMComfortCareWishes.blocks.pageText'),
        },
        columnRight: true,
      },
      {
        component: YesIfNoOption,
        params: {
          id: SM_CC_CPR,
          title: t('common.goalsOfCare.card.cpr.title'),
          text: t('common.goalsOfCare.card.cpr.text'),
          hint: getCprHintData(t),
          hiddenOptions: [YES],
          yesIf: {
            title: t('common.goalsOfCare.card.cpr.yesIf.title'),
            text: t('common.goalsOfCare.card.cpr.yesIf.text'),
            questionChoose: t('common.goalsOfCare.card.cpr.yesIf.questionChoose'),
            questionCustom: t('common.goalsOfCare.card.cpr.yesIf.questionCustom'),
            otherOptionId: answersOptions[SM_CC_CPR].otherOptionId,
            options: answersOptions[SM_CC_CPR].options,
          },
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: SM_CC_MECHVENT,
          title: t('common.goalsOfCare.card.mechvent.title'),
          text: t('common.goalsOfCare.card.mechvent.text'),
          hint: getMechventHintData(t),
          hiddenOptions: [YES],
          yesIf: {
            title: t('common.goalsOfCare.card.mechvent.yesIf.title'),
            text: t('common.goalsOfCare.card.mechvent.yesIf.text'),
            otherOptionId: answersOptions[SM_CC_MECHVENT].otherOptionId,
            options: answersOptions[SM_CC_MECHVENT].options,
            questionChoose: t('common.goalsOfCare.card.mechvent.yesIf.questionChoose'),
            questionCustom: t('common.goalsOfCare.card.mechvent.yesIf.questionCustom'),
          },
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: SM_CC_FEEDINGTUBE,
          title: t('common.goalsOfCare.card.feedingtube.title'),
          text: t('common.goalsOfCare.card.feedingtube.text'),
          hiddenOptions: [YES],
          yesIf: {
            title: t('common.goalsOfCare.card.feedingtube.yesIf.title'),
            text: t('common.goalsOfCare.card.feedingtube.yesIf.text'),
            otherOptionId: answersOptions[SM_CC_FEEDINGTUBE].otherOptionId,
            options: answersOptions[SM_CC_FEEDINGTUBE].options,
          },
          hint: getFeedingTubeHintData(t),
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: SM_CC_DIALYSIS,
          title: t('common.goalsOfCare.card.dialysis.title'),
          text: t('common.goalsOfCare.card.dialysis.text'),
          hiddenOptions: [YES],
          yesIf: {
            title: t('common.goalsOfCare.card.dialysis.yesIf.title'),
            text: t('common.goalsOfCare.card.dialysis.yesIf.text'),
            otherOptionId: answersOptions[SM_CC_DIALYSIS].otherOptionId,
            options: answersOptions[SM_CC_DIALYSIS].options,
          },
          hint: getDialysisHintData(t),
        },
      },
    ],
  }
}

export { SMComfortCareWishesFirstPage, SM_COMFORT_CARE_WISHES_URL, SM_CC_QIDS }
