import React from 'react'
import LayoutLanding from '../../application/directive/blocks/landing/LayoutLanding'
import LandingHero from '../../application/directive/blocks/landing/LandingHero'
import WhatIs from '../../application/directive/blocks/landing/WhatIs'
import Time from '../../application/directive/blocks/landing/Time'
import Why from '../../application/directive/blocks/landing/Why'
import Reasons from '../../application/directive/blocks/landing/Reasons'
import Share from '../../application/directive/blocks/landing/Share'
import { LandingContext } from '../../application/landing/LandingContext'

const Landing = () => (
  <LandingContext.Provider
    value={{
      startLink: 'https://vitaldecisions.mylivingvoice.com/login',
      loginLink: 'https://vitaldecisions.mylivingvoice.com/login',
      useExternalLinks: true,
    }}
  >
    <LayoutLanding>
      <div className="landing">
        <LandingHero />
        <WhatIs />
        <Time />
        <Why />
        <Reasons />
        <Share />
      </div>
    </LayoutLanding>
  </LandingContext.Provider>
)

export default Landing
