import React from 'react'
import cn from 'classnames'
import MD from './Markdown'
import Modal from './Modal'

import '../../styles/blocks/page-text.scss'

const PageText = ({ params: { text, inlineParts }, className }) => (
  <div className={cn('page-text', className)}>
    {text && <MD allowParagraphs>{text}</MD>}
    {inlineParts && (
      <p>
        {inlineParts.map(({ type, text, title, reTitle }, index) => (
          <span key={index}>
            {type === 'text' && <MD allowParagraphs={false}>{text}</MD>}
            {type === 'link' && (
              <Modal title={reTitle} button={<a>{title}</a>}>
                <MD allowParagraphs>{text}</MD>
              </Modal>
            )}
          </span>
        ))}
      </p>
    )}
  </div>
)

export default PageText
