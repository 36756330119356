import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import ChooseMultiple from '../blocks/ChooseMultiple'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { getAnswerByQid } from '../TheDirective'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { WELL_SUMMARY_TYPE } from '../../constants/productTypes'
import { SMSpecificWishesPage } from './SMSpecificWishesPage'
import { SMComfortCareWishesFirstPage } from './SMComfortCareWishesFirstPage'
import { ValuesSummary } from '../blocks/summary/ValuesSummary'

const SM_CHANGES_URL = 'sm-changed'
const SM_CHANGES_QID = 'sm_changed'

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(WELL_SUMMARY_TYPE))

const SMChangesPage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)
  return {
    slug: SM_CHANGES_URL,
    getPrevStep: () => ({
      page: SMSpecificWishesPage(t),
      url: `${DIRECTIVE_URL}/${SMSpecificWishesPage(t).slug}`,
    }),
    getNextStep: () => ({
      page: SMComfortCareWishesFirstPage(t),
      url: `${DIRECTIVE_URL}/${SMComfortCareWishesFirstPage(t).slug}`,
    }),
    setInitialValues: (answers) => ({
      [SM_CHANGES_QID]: getAnswerByQid(SM_CHANGES_QID)(answers),
    }),
    serializeValues: (values) => {
      return {
        variables: {
          qid: SM_CHANGES_QID,
          val: values[SM_CHANGES_QID],
        },
        update: updateCacheAnswers,
      }
    },
    otherOptionId: answersOptions[SM_CHANGES_QID].otherOptionId,
    validate: () => {},
    isRequired: false,
    isAnswered: (answers) => {
      const values = getAnswerByQid(SM_CHANGES_QID)(answers)
      return !!values && Object.values(values).some(Boolean)
    },
    dashboard: {
      title: t('directive.well.page.SMChanges.dashboardTitle'),
    },
    summary: {
      title: t('directive.well.page.SMChanges.summaryTitle'),
      blocks: [
        {
          component: ValuesSummary,
          params: {
            id: SM_CHANGES_QID,
            options: answersOptions[SM_CHANGES_QID].options,
            otherOptionId: answersOptions[SM_CHANGES_QID].otherOptionId,
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.well.page.SMChanges.blocks.pageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.well.page.SMChanges.blocks.pageText'),
        },
      },
      {
        component: ChooseMultiple,
        params: {
          optional: false,
          id: SM_CHANGES_QID,
          options: answersOptions[SM_CHANGES_QID].options,
          otherOptionId: answersOptions[SM_CHANGES_QID].otherOptionId,
          alternativeOption: true,
          alternativeText: t('directive.well.page.SMChanges.blocks.chooseMultiple.alternativeText'),
          modalTitle: t('directive.well.page.SMChanges.blocks.chooseMultiple.modalTitle'),
          modalText: t('directive.well.page.SMChanges.blocks.chooseMultiple.modalText'),
        },
      },
    ],
  }
}

export { SMChangesPage, SM_CHANGES_URL, SM_CHANGES_QID }
