import React from 'react'
import cn from 'classnames'
import MD from './Markdown'

import '../../styles/blocks/page-title.scss'

const PageTitle = ({ params: { text }, className }) => (
  <div className={cn('page-title', className)}>
    <h1 className="page-title_heading">
      <MD>{text}</MD>
    </h1>
  </div>
)

export default PageTitle
