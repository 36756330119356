import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswerByQid } from '../TheDirective'
import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import TextAnswer from '../blocks/TextAnswer'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { MedicalDecisionsPage } from './MedicalDecisionsPage'
import { GoalsOfCarePage } from './GoalsOfCarePage'
import { TextAnswerSummary } from '../blocks/summary/TextAnswerSummary'

const FUTURE_DECISIONS_URL = 'future-decisions'
const FUTURE_DECISIONS_QID = 'futuredecisions_textbox'

const FutureDecisionsPage = (t) => ({
  slug: FUTURE_DECISIONS_URL,
  getPrevStep: () => ({
    page: MedicalDecisionsPage(t),
    url: `${DIRECTIVE_URL}/${MedicalDecisionsPage(t).slug}`,
  }),
  getNextStep: () => ({
    page: GoalsOfCarePage(t),
    url: `${DIRECTIVE_URL}/${GoalsOfCarePage(t).slug}`,
  }),
  setInitialValues: (answers) => ({
    [FUTURE_DECISIONS_QID]: getAnswerByQid(FUTURE_DECISIONS_QID)(answers),
  }),
  serializeValues: (values) => ({
    variables: {
      qid: FUTURE_DECISIONS_QID,
      val: values[FUTURE_DECISIONS_QID],
    },
    update: updateCacheAnswers,
  }),
  validate: () => {},
  isRequired: true,
  isAnswered: (answers) => !!getAnswerByQid(FUTURE_DECISIONS_QID)(answers),
  dashboard: {
    title: t('directive.well.page.futureDecisions.dashboardTitle'),
  },
  summary: {
    title: t('directive.well.page.futureDecisions.summaryTitle'),
    blocks: [
      {
        component: TextAnswerSummary,
        params: {
          id: FUTURE_DECISIONS_QID,
        },
      },
    ],
  },
  blocks: [
    {
      component: PageTitle,
      params: {
        text: t('directive.well.page.futureDecisions.blocks.pageTitle'),
      },
    },
    {
      component: PageText,
      params: {
        text: t('directive.well.page.futureDecisions.blocks.pageText'),
      },
    },
    {
      component: TextAnswer,
      params: {
        id: FUTURE_DECISIONS_QID,
      },
      hideBlockError: true,
    },
  ],
})

export { FutureDecisionsPage, FUTURE_DECISIONS_URL, FUTURE_DECISIONS_QID }
