import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import  '../styles/blocks/toast.scss'

const TOAST_DEFAULT_SETTINGS = {
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
}

export const configureToasts = () =>
  toast.configure({
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnVisibilityChange: true,
    draggable: false,
    pauseOnHover: true,
  })

export const showError = message => toast.error(message, TOAST_DEFAULT_SETTINGS)

export const showSuccess = message => toast.success(message, TOAST_DEFAULT_SETTINGS)
