import React from 'react'
import Tile from '../Tile'

const ReasonTile = ({ title, expanded, expandable, onClick, index, children }) => (
  <Tile
    className="reason-tile -landing-tile"
    title={title}
    expandable={expandable}
    expanded={expanded}
    onTitleClick={() => onClick(index)}
  >
    {children}
  </Tile>
)

export default ReasonTile
