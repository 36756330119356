import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import ChooseOneFancy from '../blocks/ChooseOneFancy'
import TooltipList from '../blocks/TooltipList'
import { getAnswerByQid } from '../TheDirective'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { ProxyDesignationPage } from './ProxyDesignationPage'
import { InvolvedPeoplePage } from './InvolvedPeoplePage'
import { WELL_SUMMARY_TYPE } from '../../constants/productTypes'
import TextAnswer from '../blocks/TextAnswer'
import PageSeparator from '../blocks/PageSeparator'
import { ChooseOneSummary } from '../blocks/summary/ChooseOneSummary'
import { TextAnswerSummary } from '../blocks/summary/TextAnswerSummary'

const GUIDANCE_URL = 'guidance-to-your-proxy'
const GUIDANCE_QID = 'lwshcp_guidance'
const GUIDANCE_MORE_QID = 'lwshcp_guidancetext'

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(WELL_SUMMARY_TYPE))

const GuidanceToMyProxyPage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: GUIDANCE_URL,
    getPrevStep: () => ({
      page: ProxyDesignationPage(t),
      url: `${DIRECTIVE_URL}/${ProxyDesignationPage(t).slug}`,
    }),
    getNextStep: () => ({
      page: InvolvedPeoplePage(t),
      url: `${DIRECTIVE_URL}/${InvolvedPeoplePage(t).slug}`,
    }),
    setInitialValues: (answers) => ({
      [GUIDANCE_QID]: getAnswerByQid(GUIDANCE_QID)(answers),
      [GUIDANCE_MORE_QID]: getAnswerByQid(GUIDANCE_MORE_QID)(answers),
    }),
    serializeValues: (values) =>
      [GUIDANCE_QID, GUIDANCE_MORE_QID]
        .filter((qid) => values[qid] !== undefined)
        .map((qid) => ({
          variables: {
            qid,
            val: values[qid],
          },
          update: updateCacheAnswers,
        })),
    validate: () => {},
    isRequired: false,
    isAnswered: (answers) => [GUIDANCE_QID, GUIDANCE_MORE_QID].some((qid) => !!getAnswerByQid(qid)(answers)),
    dashboard: {
      title: t('directive.well.page.guidanceToMyProxy.dashboardTitle'),
    },
    summary: {
      title: t('directive.well.page.guidanceToMyProxy.summaryTitle'),
      blocks: [
        {
          component: ChooseOneSummary,
          params: {
            id: GUIDANCE_QID,
            options: answersOptions[GUIDANCE_QID].options,
          },
        },
        {
          component: TextAnswerSummary,
          params: {
            id: GUIDANCE_MORE_QID,
            heading: t('directive.well.page.guidanceToMyProxy.summaryHeading'),
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.well.page.guidanceToMyProxy.blocks.title'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.well.page.guidanceToMyProxy.blocks.text'),
        },
      },
      {
        component: ChooseOneFancy,
        params: {
          id: GUIDANCE_QID,
          options: answersOptions[GUIDANCE_QID].options,
        },
      },
      {
        component: TooltipList,
        params: {
          tooltips: [
            {
              title: t('directive.well.page.guidanceToMyProxy.blocks.tooltipList.tooltips.0.title'),
              reTitle: t('directive.well.page.guidanceToMyProxy.blocks.tooltipList.tooltips.0.reTitle'),
              text: t('directive.well.page.guidanceToMyProxy.blocks.tooltipList.tooltips.0.text'),
            },
          ],
        },
        columnRight: true,
      },
      {
        component: PageSeparator,
      },
      {
        component: PageText,
        params: {
          text: t('directive.well.page.guidanceToMyProxy.blocks.textMore'),
        },
      },
      {
        component: TextAnswer,
        params: {
          id: GUIDANCE_MORE_QID,
          notRemoveEmptyField: true,
        },
      },
    ],
  }
}

export { GuidanceToMyProxyPage, GUIDANCE_URL, GUIDANCE_QID }
