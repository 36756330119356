import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import Container from './Container'
import HelpModal from './HelpModal'

import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '../../constants/UrlConstants'

import VDILogo from '../../assets/images/logos/vdi-logo.svg'
import '../../styles/blocks/footer.scss'

const Footer = ({ className }) => {
  const { t } = useTranslation()

  return (
    <footer className={cn('footer', className)}>
      <Container className="footer_container">
        <nav className="footer_navigation">
          <ul className="footer_menu">
            <li className="footer_menu-item">
              <Link className="footer_menu-link" to={TERMS_OF_SERVICE_URL}>
                {t('common.footer.navigation.termsAndConditions')}
              </Link>
            </li>
            <li className="footer_menu-item">
              <Link className="footer_menu-link" to={PRIVACY_POLICY_URL}>
                {t('common.footer.navigation.privacyPolicy')}
              </Link>
            </li>
            <li className="footer_menu-item">
              <HelpModal button={<a className="footer_menu-link">{t('common.footer.navigation.contactUs')}</a>} />
            </li>
          </ul>
        </nav>
        <div className="footer_copyright">
          <a href="http://www.vitaldecisions.net" target="_blank" rel="noopener noreferrer">
            <img className="footer_owner-logo" src={VDILogo}></img>
          </a>
          <div className="footer_owner-text">
            &copy; {new Date().getFullYear()} {t('common.footer.owner')}. {t('common.footer.allRightsReserved')}.
          </div>
        </div>
      <div className='footer_copyright'>
        {t('common.footer.evolentBlurb')} 
      </div>
      </Container>
    </footer>
  )
}

export default Footer
