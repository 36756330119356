import { replace } from 'ramda'

export const getOrganizationName = () => window.ORGANIZATION_THEME.organizationName
export const getLogoFileName = () => window.ORGANIZATION_THEME.logoFileName
export const getHidePartnership = () => window.ORGANIZATION_THEME.hidePartnership
export const getShowSignUpHeaderVD = () => window.ORGANIZATION_THEME.showSignUpHeaderVD
export const getPathPrefix = () => window.ORGANIZATION_THEME.pathPrefix

export const withOrganizationPrefix = (path) => {
    const prefix = getPathPrefix()
    return prefix ? `/${prefix}${path}` : path
}

export const pathnameClearedFromPrefix = () => {
    const path = window.location.pathname
    const prefix = getPathPrefix()
    return replace(new RegExp(`^/${prefix}`), '', path)
}

