import React from 'react'
import cn from 'classnames'

import '../../styles/blocks/column-right.scss'

const ColumnRight = ({ className, children }) => (
  <div className={cn('column-right', className)}>
    {children}
  </div>
)

export default ColumnRight
