import React, { useEffect } from 'react'
import { map } from 'ramda'
import FormFields from '../blocks/FormFields'
import { Field } from 'react-final-form'
import FormInput from '../blocks/FormInput'
import FormSelect from '../blocks/FormSelect'
import AddEntity from '../blocks/AddEntity'
import PageSeparator from '../blocks/PageSeparator'
import { relationships } from '../../constants/relationships'

import '../../styles/blocks/other-people.scss'
import { useTranslation } from 'react-i18next'

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

const PeopleFields = ({ id, index, withRole }) => {
  const { t } = useTranslation()
  const translatedRelationships = map((option) => ({ ...option, label: t(option.label) }))(relationships)

  return (
    <FormFields>
      <Field
        name={`[${id}][${index}][name]`}
        render={({ input, meta }) => (
          <FormInput
            label={t('common.otherPeopleControl.nameLabel')}
            placeholder={t('common.otherPeopleControl.namePlaceholder')}
            type="text"
            input={input}
            meta={meta}
          />
        )}
      />

      {withRole && (
        <Field name={`[${id}][${index}][role]`}>
          {({ input, meta }) => (
            <FormInput
              label={t('common.otherPeopleControl.roleLabel')}
              placeholder={t('common.otherPeopleControl.rolePlaceholder')}
              type="text"
              input={input}
              meta={meta}
            />
          )}
        </Field>
      )}

      <Field name={`[${id}][${index}][relationship]`}>
        {({ input, meta }) => (
          <FormSelect
            label={t('common.otherPeopleControl.relationshipLabel')}
            placeholder={t('common.otherPeopleControl.relationshipPlaceholder')}
            options={translatedRelationships}
            input={{
              ...input,
              value: translatedRelationships.find(({ value }) => value === input.value),
              onChange: ({ value }) => input.onChange(value),
            }}
            meta={meta}
          />
        )}
      </Field>

      <Condition when={`[${id}][${index}][relationship]`} is="7">
        <Field
          name={`[${id}][${index}][relationshipother]`}
          render={({ input, meta }) => (
            <FormInput
              label={t('common.otherPeopleControl.otherRelationshipLabel')}
              placeholder={t('common.otherPeopleControl.otherRelationshipPlaceholder')}
              type="text"
              input={input}
              meta={meta}
            />
          )}
        />
      </Condition>
    </FormFields>
  )
}

const OtherPeopleControl = (props) => {
  const { t } = useTranslation()

  const { params, values, form } = props
  const people = values[params.id]

  useEffect(() => {
    if (!people.length) {
      form.mutators.setValue(params.id, [{}])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addFields = () => {
    form.mutators.setValue(params.id, [...people, {}])
  }

  const removeField = (index) => {
    const result = people.slice()
    result.splice(index, 1)
    form.mutators.setValue(params.id, result)
  }

  return (
    <div className="other-people">
      {people.map((_, index) => (
        <div className="other-people_form" key={index}>
          <PeopleFields id={params.id} index={index} withRole={params.withRole} />
          {index === 0 && people.length > 1 && <PageSeparator />}
          {index > 0 && (
            <AddEntity onClick={() => removeField(index)}>
              &ndash; {t('common.otherPeopleControl.removePersonButton')}
            </AddEntity>
          )}
        </div>
      ))}
      <div className="other-people_add">
        <AddEntity className="-with-margin-top" onClick={addFields}>
          + {t('common.otherPeopleControl.addMoreButton')}
        </AddEntity>
      </div>
    </div>
  )
}

export default OtherPeopleControl
