import React, { useContext } from 'react'
import cn from 'classnames'
import Container from './Container'
import LogoHeader from './LogoHeader'
import TopHelp from './TopHelp'
import TopUser from './TopUser'
import ProgressBar from './ProgressBar'
import { UserContext } from '../../hoc'

import '../../styles/blocks/header.scss'
import TopLang from './TopLang'

const Header = ({ progress, className, showProgress, additionalContent }) => {
  const user = useContext(UserContext)

  return (
    <header className={cn('header', className)}>
      <div className="header_additional -desktop-hide">{additionalContent}</div>
      <Container className="header_container">
        <div className="header_row">
          <LogoHeader />
          <div className="header_additional -mobile-hide">{additionalContent}</div>
          {showProgress && (
            <div className="header_progress">
              <ProgressBar className="-in-header" progress={progress} />
            </div>
          )}
          <div className="header_controls">
            <TopHelp />
            <div className="header_controls-divider" />
            <TopLang />
            {user && (
              <>
                <div className="header_controls-divider" />
                <TopUser profile={user.profile} />
              </>
            )}
          </div>
        </div>
      </Container>
    </header>
  )
}

export default Header
