import React from 'react'
import cn from 'classnames'
import Tooltip from './Tooltip'

import '../../styles/blocks/tooltip-list.scss'

const TooltipList = ({ params: { tooltips }, className }) => (
  <ul className={cn('tooltip-list', className)}>
    {tooltips.map((tooltip, index) => (
      <li className="tooltip-list_item" key={index}>
        <Tooltip tooltip={tooltip} />
      </li>
    ))}
  </ul>
)

export default TooltipList
