import React, { useContext } from 'react'
import Container from '../Container'
import LandingTitle from './LandingTitle'
import LandingColumns from './LandingColumns'
import LandingColumnLeft from './LandingColumnLeft'
import LandingColumnRight from './LandingColumnRight'
import ReasonTiles from './ReasonTiles'
import Button from '../Button'
import { LandingContext } from '../../../landing/LandingContext'

import '../../../styles/blocks/landing/reasons.scss'
import { useTranslation } from 'react-i18next'

const Reasons = () => {
  const { t } = useTranslation()

  const { startLink, useExternalLinks } = useContext(LandingContext)
  const buttonProps = { [useExternalLinks ? 'href' : 'to']: startLink }

  return (
    <div className="reasons">
      <Container className="reasons_container -size-landing">
        <div className="reasons_tile">
          <LandingColumns className="reasons_columns">
            <LandingColumnLeft>
              <LandingTitle title={t('landing.reasons.title')} />
              <div className="why_text">
                <p>
                  {t('landing.reasons.paragraph1')}
                  <br />
                  {t('landing.reasons.paragraph2')}
                </p>
              </div>
              <Button className="share_cta -desktop-big" {...buttonProps}>
                {t('landing.reasons.cta')}
              </Button>
              {/*<div className="share_note">~10 minutes to complete, no card required</div>*/}
            </LandingColumnLeft>
            <LandingColumnRight className="-short">
              <ReasonTiles />
            </LandingColumnRight>
          </LandingColumns>
        </div>
      </Container>
    </div>
  )
}

export default Reasons
