import React from 'react'
import Modal from './Modal'
import MD from './Markdown'
import { useTranslation } from 'react-i18next'

const HelpModal = ({ button }) => {
  const { t } = useTranslation()

  return (
    <Modal title={t('common.helpModal.title')} button={button}>
      <div className="modal_text">
        <MD allowParagraphs>{`${t(
          'common.helpModal.text',
        )} [support@mylivingvoice.com](mailto:support@mylivingvoice.com)`}</MD>
      </div>
    </Modal>
  )
}

export default HelpModal
