import PageTitle from '../blocks/PageTitle'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswerByQid } from '../TheDirective'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { WELL_SUMMARY_TYPE } from '../../constants/productTypes'
import { ProxyDesignationPage } from './ProxyDesignationPage'
import { SMComfortCareWishesFirstPage } from './SMComfortCareWishesFirstPage'
import ChooseOneCard from '../blocks/ChooseOneCard'
import TextAnswer from '../blocks/TextAnswer'
import { ChooseOneSummary } from '../blocks/summary/ChooseOneSummary'
import { TextAnswerSummary } from '../blocks/summary/TextAnswerSummary'

const SM_COMFORT_CARE_WISHES_URL = 'sm-cc-goals-of-care-2'
const SM_CC_PAIN = 'smpain'
const SM_CC_ANTIBIOTICS = 'smantibiotics'
const SM_CC_QUICKEND = 'smeolpref'
const SM_CC_HOSPICE = 'smhospice'
const SM_CC_PLACEOFDEATH = 'smdieathome'
const SM_CC_OTHERWISHES = 'smtoccothertmttextbox'
const SM_CC_QIDS = [SM_CC_PAIN, SM_CC_ANTIBIOTICS, SM_CC_QUICKEND, SM_CC_HOSPICE, SM_CC_PLACEOFDEATH]
const SM_CC_ALL_QIDS = [...SM_CC_QIDS, SM_CC_OTHERWISHES]

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(WELL_SUMMARY_TYPE))

const SMComfortCareWishesSecondPage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: SM_COMFORT_CARE_WISHES_URL,
    getPrevStep: () => ({
      page: SMComfortCareWishesFirstPage(t),
      url: `${DIRECTIVE_URL}/${SMComfortCareWishesFirstPage(t).slug}`,
    }),
    getNextStep: () => ({
      page: ProxyDesignationPage(t),
      url: `${DIRECTIVE_URL}/${ProxyDesignationPage(t).slug}`,
    }),
    setInitialValues: (answers) => {
      return [...SM_CC_QIDS, SM_CC_OTHERWISHES].reduce(
        (res, qid) => ({ ...res, [qid]: getAnswerByQid(qid)(answers) }),
        {},
      )
    },
    serializeValues: (values) =>
      [...SM_CC_QIDS, SM_CC_OTHERWISHES]
        .filter((qid) => values[qid] !== undefined)
        .map((qid) => ({
          variables: { qid, val: values[qid] },
          update: updateCacheAnswers,
        })),
    validate: () => {},
    isRequired: false,
    isAnswered: (answers) => [...SM_CC_QIDS, SM_CC_OTHERWISHES].some((qid) => !!getAnswerByQid(qid)(answers)),
    dashboard: {
      title: t('directive.well.page.SMComfortCareWishesAdditional.dashboardTitle'),
    },
    summary: {
      title: t('directive.well.page.SMComfortCareWishesAdditional.summaryTitle'),
      blocks: [
        {
          component: ChooseOneSummary,
          params: {
            id: SM_CC_PAIN,
            heading: t('directive.well.page.SMComfortCareWishesAdditional.summaryLabels.pain'),
            options: answersOptions[SM_CC_PAIN].options,
          },
        },
        {
          component: ChooseOneSummary,
          params: {
            id: SM_CC_ANTIBIOTICS,
            heading: t('directive.well.page.SMComfortCareWishesAdditional.summaryLabels.antibiotics'),
            options: answersOptions[SM_CC_ANTIBIOTICS].options,
          },
        },
        {
          component: ChooseOneSummary,
          params: {
            id: SM_CC_QUICKEND,
            heading: t('directive.well.page.SMComfortCareWishesAdditional.summaryLabels.quickend'),
            options: answersOptions[SM_CC_QUICKEND].options,
          },
        },
        {
          component: ChooseOneSummary,
          params: {
            id: SM_CC_HOSPICE,
            heading: t('directive.well.page.SMComfortCareWishesAdditional.summaryLabels.hospice'),

            options: answersOptions[SM_CC_HOSPICE].options,
          },
        },
        {
          component: ChooseOneSummary,
          params: {
            id: SM_CC_PLACEOFDEATH,
            heading: t('directive.well.page.SMComfortCareWishesAdditional.summaryLabels.placeofdeath'),
            options: answersOptions[SM_CC_PLACEOFDEATH].options,
          },
        },
        {
          component: TextAnswerSummary,
          params: {
            id: SM_CC_OTHERWISHES,
            heading: t('directive.well.page.SMComfortCareWishesAdditional.summaryLabels.otherwishes'),
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.well.page.SMComfortCareWishesAdditional.blocks.pageTitle'),
        },
      },
      {
        component: ChooseOneCard,
        params: {
          id: SM_CC_PAIN,
          title: t('directive.well.page.SMComfortCareWishesAdditional.blocks.pain.title'),
          options: answersOptions[SM_CC_PAIN].options,
          hint: {
            title: t('directive.well.page.SMComfortCareWishesAdditional.blocks.pain.hintTitle'),
            text: t('directive.well.page.SMComfortCareWishesAdditional.blocks.pain.hintText'),
          },
        },
      },
      {
        component: ChooseOneCard,
        params: {
          id: SM_CC_ANTIBIOTICS,
          title: t('directive.well.page.SMComfortCareWishesAdditional.blocks.antibiotics.title'),
          options: answersOptions[SM_CC_ANTIBIOTICS].options,
          hint: {
            title: t('directive.well.page.SMComfortCareWishesAdditional.blocks.antibiotics.hintTitle'),
            text: t('directive.well.page.SMComfortCareWishesAdditional.blocks.antibiotics.hintText'),
          },
        },
      },
      {
        component: ChooseOneCard,
        params: {
          id: SM_CC_QUICKEND,
          title: t('directive.well.page.SMComfortCareWishesAdditional.blocks.quickend.title'),
          options: answersOptions[SM_CC_QUICKEND].options,
        },
      },
      {
        component: ChooseOneCard,
        params: {
          id: SM_CC_HOSPICE,
          title: t('directive.well.page.SMComfortCareWishesAdditional.blocks.hospice.title'),
          options: answersOptions[SM_CC_HOSPICE].options,
        },
      },
      {
        component: ChooseOneCard,
        params: {
          id: SM_CC_PLACEOFDEATH,
          title: t('directive.well.page.SMComfortCareWishesAdditional.blocks.placeofdeath.title'),
          options: answersOptions[SM_CC_PLACEOFDEATH].options,
        },
      },
      {
        component: TextAnswer,
        params: {
          id: SM_CC_OTHERWISHES,
          title: t('directive.well.page.SMComfortCareWishesAdditional.blocks.otherwishes.title'),
          text: t('directive.well.page.SMComfortCareWishesAdditional.blocks.otherwishes.text'),
          notRemoveEmptyField: true,
          tileWrapped: true,
        },
      },
    ],
  }
}

export { SMComfortCareWishesSecondPage, SM_COMFORT_CARE_WISHES_URL, SM_CC_QIDS, SM_CC_ALL_QIDS }
