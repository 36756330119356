import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import YesIfNoOption from '../blocks/YesIfNoOption'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswerByQid } from '../TheDirective'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { WELL_SUMMARY_TYPE } from '../../constants/productTypes'
import { FTChangesPage } from './FTChangesPage'
import { ProxyDesignationPage } from './ProxyDesignationPage'
import TextAnswer from '../blocks/TextAnswer'
import { YesIfNoSummary } from '../blocks/summary/YesIfNoSummary'
import { TextAnswerSummary } from '../blocks/summary/TextAnswerSummary'
import {
  getCprHintData,
  getDialysisHintData,
  getFeedingTubeHintData,
  getMechventHintData,
} from '../goalsOfCareHintsData'

const FT_SM_URL = 'ft-sm-wishes'
const FT_SM_CPR_QID = 'ftcpr'
const FT_SM_MECHVENT_QID = 'ftmechvent'
const FT_SM_FEEDINGTUBE_QID = 'ftfeedingtube'
const FT_SM_DIALYSIS_QID = 'ftdialysis'
const FT_SM_YESIFNO_QIDS = [FT_SM_CPR_QID, FT_SM_MECHVENT_QID, FT_SM_FEEDINGTUBE_QID, FT_SM_DIALYSIS_QID]
const FT_SM_OTHERWISHES_QID = 'ftgoalschangetextbox'
const FT_SM_ALL_QIDS = [...FT_SM_YESIFNO_QIDS, FT_SM_OTHERWISHES_QID]

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(WELL_SUMMARY_TYPE))

const FTSelectiveMeasuresPage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: FT_SM_URL,
    getPrevStep: () => ({
      page: FTChangesPage(t),
      url: `${DIRECTIVE_URL}/${FTChangesPage(t).slug}`,
    }),
    getNextStep: () => ({
      page: ProxyDesignationPage(t),
      url: `${DIRECTIVE_URL}/${ProxyDesignationPage(t).slug}`,
    }),
    setInitialValues: (answers) => {
      const initialValues = FT_SM_YESIFNO_QIDS.reduce(
        (res, qid) => ({ ...res, [qid]: getAnswerByQid(qid)(answers) }),
        {},
      )
      if (Object.values(initialValues).some(Boolean)) {
        return { ...initialValues, [FT_SM_OTHERWISHES_QID]: getAnswerByQid(FT_SM_OTHERWISHES_QID)(answers) }
      }
      return {
        [FT_SM_CPR_QID]: getAnswerByQid(FT_SM_CPR_QID)(answers),
        [FT_SM_MECHVENT_QID]: getAnswerByQid(FT_SM_MECHVENT_QID)(answers),
        [FT_SM_FEEDINGTUBE_QID]: getAnswerByQid(FT_SM_FEEDINGTUBE_QID)(answers),
        [FT_SM_DIALYSIS_QID]: getAnswerByQid(FT_SM_DIALYSIS_QID)(answers),
        [FT_SM_OTHERWISHES_QID]: getAnswerByQid(FT_SM_OTHERWISHES_QID)(answers),
      }
    },
    serializeValues: (values) =>
      FT_SM_ALL_QIDS.filter((qid) => values[qid] !== undefined).map((qid) => ({
        variables: { qid, val: values[qid] },
        update: updateCacheAnswers,
      })),
    validate: () => {},
    isRequired: false,
    isAnswered: (answers) => FT_SM_ALL_QIDS.some((qid) => !!getAnswerByQid(qid)(answers)),
    dashboard: {
      title: t('directive.well.page.FTSelectiveMeasures.dashboardTitle'),
    },
    summary: {
      title: t('directive.well.page.FTSelectiveMeasures.summaryTitle'),
      blocks: [
        {
          component: YesIfNoSummary,
          params: {
            qids: FT_SM_YESIFNO_QIDS,
            labels: [
              t('directive.well.page.FTSelectiveMeasures.summaryLabels.cpr'),
              t('directive.well.page.FTSelectiveMeasures.summaryLabels.mechanicalVentilation'),
              t('directive.well.page.FTSelectiveMeasures.summaryLabels.feedingTube'),
              t('directive.well.page.FTSelectiveMeasures.summaryLabels.dialysis'),
            ],
            options: answersOptions,
          },
        },
        {
          component: TextAnswerSummary,
          params: {
            id: FT_SM_OTHERWISHES_QID,
            heading: t('directive.well.page.FTSelectiveMeasures.summaryLabels.other'),
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.well.page.FTSelectiveMeasures.blocks.pageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          inlineParts: [
            {
              type: 'text',
              text: t('directive.well.page.FTSelectiveMeasures.blocks.pageText'),
            },
          ],
        },
        columnRight: true,
      },
      {
        component: YesIfNoOption,
        params: {
          id: FT_SM_CPR_QID,
          title: t('common.goalsOfCare.card.cpr.title'),
          text: t('common.goalsOfCare.card.cpr.text'),
          hint: getCprHintData(t),
          yesIf: {
            title: t('common.goalsOfCare.card.cpr.yesIf.title'),
            text: t('common.goalsOfCare.card.cpr.yesIf.text'),
            questionChoose: t('common.goalsOfCare.card.cpr.yesIf.questionChoose'),
            questionCustom: t('common.goalsOfCare.card.cpr.yesIf.questionCustom'),
            otherOptionId: answersOptions[FT_SM_CPR_QID].otherOptionId,
            options: answersOptions[FT_SM_CPR_QID].options,
          },
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: FT_SM_MECHVENT_QID,
          title: t('common.goalsOfCare.card.mechvent.title'),
          text: t('common.goalsOfCare.card.mechvent.text'),
          hint: getMechventHintData(t),
          yesIf: {
            title: t('common.goalsOfCare.card.mechvent.yesIf.title'),
            text: t('common.goalsOfCare.card.mechvent.yesIf.text'),
            otherOptionId: answersOptions[FT_SM_MECHVENT_QID].otherOptionId,
            options: answersOptions[FT_SM_MECHVENT_QID].options,
            questionChoose: t('common.goalsOfCare.card.mechvent.yesIf.questionChoose'),
            questionCustom: t('common.goalsOfCare.card.mechvent.yesIf.questionCustom'),
          },
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: FT_SM_FEEDINGTUBE_QID,
          title: t('common.goalsOfCare.card.feedingtube.title'),
          text: t('common.goalsOfCare.card.feedingtube.text'),
          hint: getFeedingTubeHintData(t),
          yesIf: {
            title: t('common.goalsOfCare.card.feedingtube.yesIf.title'),
            text: t('common.goalsOfCare.card.feedingtube.yesIf.text'),
            otherOptionId: answersOptions[FT_SM_FEEDINGTUBE_QID].otherOptionId,
            options: answersOptions[FT_SM_FEEDINGTUBE_QID].options,
          },
        },
      },
      {
        component: YesIfNoOption,
        params: {
          id: FT_SM_DIALYSIS_QID,
          title: t('common.goalsOfCare.card.dialysis.title'),
          text: t('common.goalsOfCare.card.dialysis.text'),
          hint: getDialysisHintData(t),
          yesIf: {
            title: t('common.goalsOfCare.card.dialysis.yesIf.title'),
            text: t('common.goalsOfCare.card.dialysis.yesIf.text'),
            otherOptionId: answersOptions[FT_SM_DIALYSIS_QID].otherOptionId,
            options: answersOptions[FT_SM_DIALYSIS_QID].options,
          },
        },
      },
      {
        component: TextAnswer,
        params: {
          id: FT_SM_OTHERWISHES_QID,
          title: t('directive.well.page.FTSelectiveMeasures.blocks.otherwishes.title'),
          text: t('directive.well.page.FTSelectiveMeasures.blocks.otherwishes.text'),
          notRemoveEmptyField: true,
          tileWrapped: true,
        },
      },
    ],
  }
}

export { FTSelectiveMeasuresPage, FT_SM_URL, FT_SM_YESIFNO_QIDS, FT_SM_ALL_QIDS }
