import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LOCALES } from '../constants/locales'

const TermsTextEN = ({ privacyUrl = '/privacy' }) => {
  const { origin } = document.location

  const LinkToHome = () => <Link to="/">{origin}</Link>
  const LinkToPrivacy = () => <Link to={privacyUrl}>{origin + privacyUrl}</Link>

  return (
    <Fragment>
      <p className="common-text_paragraph">
        Welcome! Your use of the Vital Decision, LLC website located at &nbsp;
        <LinkToHome /> and the services made available through the same (collectively, the &quot;Website&quot;) is
        subject to your acceptance of this Terms and Conditions of Use Agreement (the “Terms of Use”), which
        incorporates by reference our Privacy Notice, located at&nbsp;
        <LinkToPrivacy />. If you do not understand or have questions about the Terms of Use, immediately stop all use
        of the Website. If you do not accept this Terms of Use, you may not use the Website. If at any time you do not
        agree to this Terms of Use, you must cease your use of this Website.
      </p>

      <p className="common-text_paragraph">
        The Terms of Use apply only to the Website and do not apply to any websites that are linked to the Website. For
        access to the terms and conditions or privacy policies of linked websites, please refer to the policies of such
        websites. It is your obligation to review the Terms of Use before accessing the Website. Any changes to the
        Terms of Use will be effective immediately upon our posting them to the Website, unless otherwise stated. We
        reserve the right to change the contents of the Website at any time, with or without notice.
      </p>

      <div id="DEFINITIONS"></div>

      <h2 className="common-header_h2"> I. Definitions</h2>

      <p className="common-text_paragraph">For the purposes of this Terms of Use, the following definitions apply:</p>

      <p className="common-text_paragraph">
        <b>“Affiliates” </b> refers to companies and divisions under the ownership of the Company or that own the
        Company.
      </p>

      <p className="common-text_paragraph">
        <b>“Company” </b> means Vital Decisions, LLC, the owner and operator of the Website.
      </p>

      <p className="common-text_paragraph">
        <b>“Content” </b> includes all Text, Graphics, design and Programming used on the Website.
      </p>

      <p className="common-text_paragraph">
        <b>“Graphics” </b> includes all logos, buttons, and other graphical elements on the Website, including the color
        combinations and the page layout of the Website, with the exception of trademarks and intellectual property
        belonging to third-parties and displayed with permission.
      </p>

      <p className="common-text_paragraph">
        <b>“Programming” </b> includes, but is not limited to, both client-side code (including but not limited to HTML,
        JavaScript, etc.) and server-side code (including but not limited to Active Server Pages, VBScript, databases,
        etc.) used on the Website.
      </p>

      <p className="common-text_paragraph">
        <b>“Text” </b> includes all text on every page of the Website, whether editorial, navigational, or
        instructional.
      </p>

      <p className="common-text_paragraph">
        <b>“You” </b> or <b>“your” </b> (whether or not capitalized) refers to the person accessing the Website and
        agreeing to this Terms of Use.
      </p>

      <div id="USE_OF_THIS_WEBSITE"></div>

      <h2 className="common-header_h2">II. Use Of This Website</h2>
      <p className="common-text_paragraph">
        This Website provides an advance care planning service that allows you to obtain state-specific advance
        directives, healthcare proxy, and other similar forms.
      </p>

      <p className="common-text_paragraph">
        A limited license is granted to you by us to view, download, and use a single copy of the Website solely for
        your personal, non-commercial use and only as an aid to using the services that are provided on the Website. The
        Website may only be used in a manner consistent with this purpose, and only within the context of the services
        and information set forth on the Website. Except as provided herein, you are not permitted to download (other
        than page caching), copy or change any portion of the Website, unless you have our express written consent.
      </p>

      <p className="common-text_paragraph">
        We attempt to ensure that information on the Website is complete, accurate and current. Despite our efforts, the
        information on this Website may occasionally be inaccurate, incomplete or out of date, and we make no
        representation as to the completeness, accuracy or currency of any information on the Website.
      </p>

      <div id="ACCOUNT_REGISTRATION"></div>

      <h2 className="common-header_h2">III. Account Registration</h2>
      <p className="common-text_paragraph">
        Certain functions of the Website may require registration, including purchasing products or services. You may
        only create and use an account for your own personal use. If you register or provide information to us in any
        manner, you agree to provide only true, accurate, current and complete information. You are responsible for
        maintaining the security of your account, and you are fully responsible for all activities and actions that
        occur in connection with the account. If we issue a username and/or password to you, you agree to protect such
        information by, among other things, keeping your password and other information relating to your account
        confidential. You must immediately notify us of any unauthorized uses of your account or any other breaches of
        security. We will not be liable for any acts or omissions by you, including but not limited to any damages of
        any kind incurred as a result of such acts or omissions. If you delete your account, we may still need to retain
        certain information, including your survey data, for record keeping, administrative, legal and technical
        purposes. By registering for an account, you grant us permission to send emails, surveys, offers, promotions and
        other marketing material to you via email or push notification, including both commercial and
        transactional/relationship content. You may unsubscribe at any time.
      </p>

      <div id="RESTRICTIONS_ON_USE_OF_THE_WEBSITE"></div>

      <h2 className="common-header_h2">IV. Restrictions on Use of the Website</h2>
      <p className="common-text_paragraph">
        The information displayed on the Website may not be used for any purpose except in connection with your direct
        use of the Website as permitted by this Terms of Use, and may not be excerpted, summarized, duplicated or
        otherwise removed from the Website except with our explicit, written permission. You may not collect or use any
        portion of the content of this Website in any derivative way, or download, or copy or sell information or other
        matter for use of any other party. You may not gather information and data on the Website from mining, robots or
        other extraction tools.
      </p>

      <p className="common-text_paragraph">
        In addition, you represent, warrant and agree that you will not use (or plan, encourage or help others to use)
        the Website for any purpose or in any manner that is prohibited by this Terms of Use or by applicable law. You
        also may not interfere with the proper operation of the Website including, but not limited to, by attacking,
        hacking into, or otherwise attempting to penetrate any non-publicly accessible elements of the Website or its
        servers or network, through the use of bots, Trojan Horses, viruses, DNS attacks, or other technology which is
        designed or intended to interfere with the proper operation of the Website or the use of the Website by any
        users. You agree that you will not circumvent or attempt to circumvent any security or access control technology
        implemented on the Website, or the servers and network associated with the Website. Any unauthorized use
        terminates the permission or license granted by us, in addition to all rights at law or in equity. In addition,
        you specifically agree not to use this Website to do any of the following:
      </p>

      <ul className="common-text-list">
        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Email or transmit content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory,
            obscene, libelous, invasive of another’s privacy, or otherwise unlawful;
          </p>
        </li>
        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Impersonate or misrepresent your connection to any other entity or person or otherwise manipulate
            identifiers to disguise the origin of the content;
          </p>
        </li>
        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Email or transmit content that infringes on the intellectual property or the right of any entity or person;
          </p>
        </li>
        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Intentionally or unintentionally violate any applicable local, state, national or international law;
          </p>
        </li>
        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Email or transmit material that includes viruses, worms or any other computer code, files or programs
            designed to interrupt, destroy or limit the functionality of any computer software or hardware or
            telecommunications; and/or
          </p>
        </li>
        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Disrupt the normal flow of communications or affect the ability of others to engage in activities via this
            Website.
          </p>
        </li>
      </ul>

      <div id="TERMINATION_OF_ACCESS"></div>

      <h2 className="common-header_h2">V. Termination of Access</h2>

      <p className="common-text_paragraph">
        Use of this Website is a privilege. We reserve the right to suspend or terminate your access to the Website for
        any reason at any time, in our sole discretion. The Website and its contents are not intended for the use of
        children under the age of 18. Children under the age of 18 may not use or submit any information to the Website.
        Individuals under the age of 18 may only access the Website under the supervision of a parent or legal guardian
        who is at least 18 years of age, and who agrees to be bound by, and responsible for, actions taken and
        information submitted on the Website.
      </p>

      <div id="INFORMATION_YOU_PROVIDE_TO_US_VIA_THIS_WEBSITE"></div>

      <h2 className="common-header_h2">VI. Information You Provide to Us Via this Website</h2>
      <p className="common-text_paragraph">
        If you choose to provide any personal information via this Website, the information will be used only for
        certain purposes, as described in our Privacy Notice. Additionally, we may collect or share certain information
        based on your usage of the Website, as described in our Privacy Notice.
      </p>

      <p className="common-text_paragraph">
        In order to facilitate communications between you and us, this Website offers you the ability to contact us.
        Although we strive to protect and secure our online communications, and use the security measures detailed in
        our Privacy Notice to protect your information, please note that no data transmitted over the Internet can be
        guaranteed to be completely secure and no security measures are perfect or impenetrable. We shall have no
        liability whatsoever for any unaccepted or unprocessed email instructions or requests, or for any loss or damage
        arising from or in connection with any unauthorized use by third parties of any information that you send by
        email.
      </p>

      <p className="common-text_paragraph">
        <b>Limitations on Information Submitted</b>
      </p>

      <p className="common-text_paragraph">
        We do not seek to receive any confidential or proprietary information or trade secrets of third parties through
        the Website, and we seek only the minimum necessary information to provide our services and products.
      </p>

      <p className="common-text_paragraph">
        <b>Ownership of Submissions</b>
      </p>

      <p className="common-text_paragraph">
        By sending us information (“Submissions”), you are granting us an irrevocable and unrestricted worldwide license
        to use, modify, reproduce, adapt, transmit, sell, license and sub-license, create derivative works from,
        publicly display, perform and distribute your Submissions so long as they are de-identified or aggregated for
        any purpose whatsoever (commercial or otherwise), in any form, media or technology, whether now known or
        hereafter developed, alone or as part of other works, with no additional payment or other compensation to you,
        subject to the Privacy Notice. We will not publicize any information that could be used to identify you
        personally in connection with the Submission to protect your privacy.
      </p>

      <p className="common-text_paragraph">
        You agree to indemnify us for all claims arising from or in connection with any claims to any rights in any
        Submission, as further set forth below. You further irrevocably waive any “moral rights” or other rights with
        respect to attribution of authorship or integrity of materials regarding any Submission that you may have under
        any applicable law or under any legal theory, excluding This section will survive termination of this Agreement
        for any reason.
      </p>

      <div id="MONITORING_OF_INFORMATION"></div>

      <h2 className="common-header_h2">VII. Monitoring Of Information</h2>
      <p className="common-text_paragraph">
        We do not assume any obligation to review or monitor the content or other information submitted to the Website
        by third-parties. You assume the risk of verifying the accuracy of such information posted through independent
        investigation. Notwithstanding the foregoing, we may, at our discretion, review information submitted to the
        Website for any purpose whatsoever and we reserve the right, in our sole discretion, to remove, edit or reject
        any information submitted to the Website for any reason whatsoever.
      </p>

      <p className="common-text_paragraph">
        We reserve the right to cooperate with any law enforcement authorities or court orders requesting or directing
        us to disclose the identity of anyone believed to be violating the Terms of Use or law. You agree that in the
        event we receive a subpoena issued by a court or from a law enforcement or government agency, we may
        unilaterally choose to comply with such subpoenas without your consent or prior notice to you and may disclose
        your IP address, username, name, IP location or other information in response thereto, subject to applicable
        laws. You agree to waive and indemnify and hold us harmless from and against any and all claims whatsoever
        resulting from, or in connection with, any action by us regarding any investigations either by us or law
        enforcement authorities.
      </p>

      <div id="NO_MEDICAL_ADVICE"></div>

      <h2 className="common-header_h2">VIII. No Medical Advice</h2>
      <p className="common-text_paragraph">
        The Website, its content, and the services and products provided by the Company are for informational purposes
        only and do not constitute professional medical advice, diagnosis, or treatment. Using, accessing and/or
        browsing the Website and/or providing personal or medical information to the Company does not create a
        physician-patient relationship between you and the Company, and nothing contained in the Website is intended
        replace the services or to be a substitute for medical advice of a licensed physician or trained health
        professional licensed in your state. Please seek the advice of your physician or other qualified health provider
        with any questions you may have regarding a medical condition or your living will.You hereby agree that you
        shall not make any health or medical related decision based in whole or in part on anything contained in the
        Website.
      </p>

      <div id="NO_LEGAL_ADVICE"></div>

      <h2 className="common-header_h2">IX. No Legal Advice</h2>
      <p className="common-text_paragraph">
        The Website, its content, and the services and products provided by the Company are for informational purposes
        only and are not intended to be a substitute for professional legal advice.{' '}
        <b>
          Using, accessing and/or browsing the Website and/or providing personal information to the Company does not
          create an attorney-client relationship between you and the Company.
        </b>{' '}
        Each state&apos;s laws may include separate requirements for advance directives, healthcare powers of attorney,
        and similar documents, and may be subject to interpretation by different courts. The information contained on
        the Website and the services and products provided by the Company are not legal advice and are not guaranteed to
        be correct, complete or up-to-date. The Website and the Company’s products and services are not substitutes for
        the advice or services of an attorney licensed in the appropriate jurisdiction.
      </p>

      <div id="INTELLECTUAL_PROPERTY_RIGHTS"></div>

      <h2 className="common-header_h2">X. Intellectual Property Rights</h2>
      <p className="common-text_paragraph">
        The Company owns any and all intellectual property rights relating to the Vital Decisions brand, trade name,
        trade dress, and other content including: copyright, trademark, service mark, trade name, trade dress,
        proprietary logo, insignia, business identifier, and/or other Text and Graphics that has or provides the “look
        and feel” of the Company’s brand image, as well as all of the Content, including the Text, Graphics,
        Programming, photographs, video and audio contained herein (the “Intellectual Property”). Your use of the
        Website does not grant you any rights or licenses relating to the Intellectual Property, including but not
        limited to any copyrights, trademark rights, patent rights, database rights, moral rights, sui generis rights
        and other intellectual property and proprietary rights therein, except as expressly provided for in these Terms
        of Use. None of the Intellectual Property may be used, reproduced, published, transmitted, distributed,
        displayed, performed, exhibited, modified, used to create derivative works, sold, re-sold or used in any sale,
        or exploited for in any way, in whole or in part, except as provided for herein and unless you obtain our prior
        written consent. You may not reproduce, modify, display, sell, or distribute the Intellectual Property, or use
        it in any other way for public or commercial purposes. The foregoing limitations include, but are not limited
        to, copying or adapting the HTML code used to generate web pages on the Website, as well as any Graphics or
        Programming. All other Content, including product names, names of services, trademarks, service marks and other
        intellectual property is the property of their respective owners, as indicated, and may only be used as
        permitted.
      </p>

      <div id="DISCLAIMER"></div>

      <h2 className="common-header_h2">XI. Disclaimer of Warranties & Limitations of Liability</h2>
      <p className="common-text_paragraph">
        <b>
          Your consent and agreement to the following disclaimers and limitations is a material inducement for us to
          permit you to access the Website and for the Company to provide you with its products and/or services. Your
          use of the Website, and the obligations and liabilities of us in respect of your use of the Website and the
          products and/or services provided by the Company, is expressly limited as follows:
        </b>
      </p>

      <h2 className="common-header_h2">DISCLAIMER OF WARRANTIES</h2>
      <p className="common-text_paragraph">
        THE WEBSITE, ITS CONTENT, AND THE SERVICES AND/OR PRODUCTS OFFERED BY THE COMPANY ARE PROVIDED “AS IS” AND
        WITHOUT ANY WARRANTY WHATSOEVER. THE COMPANY DISCLAIMS ANY AND ALL EXPRESS AND IMPLIED WARRANTIES WHATSOEVER TO
        THE MAXIMUM EXTENT PERMITTED BY LAW, INCLUDING WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, TITLE,
        NON-INFRINGEMENT OF THIRD-PARTIES RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE. Your use of this Website and
        SERVICES, and/OR reliance on any of its content is at your own risk.
      </p>

      <p className="common-text_paragraph">
        THE COMPANY does not warrant that the functions or content contained in this Website will be uninterrupted,
        Accurate or error-free. You, and not THE COMPANY, assume the cost of all necessary servicing, repair, or
        correction in the event that there is any loss or damage arising from or in connection with the use of this
        Website or its content. THE COMPANY DOES NOT WARRANT OR MAKE ANY REPRESENTATION WHATSOEVER REGARDING the USE, OR
        THE RESULT OF USE, OF THE CONTENT OF THIS WEBSITE RELATED TO ACCURACY, RELIABILITY OR OTHERWISE. THE CONTENT OF
        THIS WEBSITE MAY INCLUDE ERRORS (INCLUDING, WITHOUT LIMITATION, TECHNICAL OR TYPOGRAPHICAL ERRORS), AND We MAY
        MAKE CHANGES OR IMPROVEMENTS TO THIS WEBSITE AT ANY TIME with or without notice.
      </p>

      <p className="common-text_paragraph">
        NEITHER THE COMPANY NOR ANY OTHER PERSON OR ENTITY ASSOCIATED WITH THE DESIGN OR MAINTENANCE OF THIS WEBSITE
        SHALL BE HELD LIABLE OR RESPONSIBLE IN ANY WAY FOR ANY DAMAGE, LOSS, INJURY, OR MALFUNCTION ASSOCIATED WITH YOUR
        USE OF THIS WEBSITE.
      </p>

      <p className="common-text_paragraph">LIMITATION OF LIABILITY</p>

      <p className="common-text_paragraph">
        IN NO EVENT SHALL THE COMPANY AND/OR ITS SUBSIDIARIES, AFFILIATES, RELATED COMPANIES, SUPPLIERS, ADVERTISERS,
        SPONSORS, THIRD-PARTY SERVICE PROVIDERS, AND THEIR RESPECTIVE EMPLOYEES, OFFICERS, DIRECTORS, AND AGENTS BE
        LIABLE FOR ANY INCIDENTAL, CONSEQUENTIAL, PUNITIVE, INDIRECT OR SPECIAL DAMAGES, (INCLUDING LOST PROFITS AND
        DAMAGES) WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE COMPANY IS
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
        LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE LIMITATIONS SET FORTH IN THIS PARAGRAPH MAY
        NOT APPLY TO YOU. IF THE FOREGOING LIMITATIONS ARE HELD INAPPLICABLE OR UNENFORCEABLE FOR ANY REASON, THEN THE
        MAXIMUM LIABILITY OF THE COMPANY TO YOU FOR ANY TYPE OF DAMAGES SHALL BE LIMITED TO THE TOTAL AMOUNT YOU PAID
        THE COMPANY FOR SERVICES OR ONE HUNDRED U.S. DOLLARS, WHICHEVER IS GREATER.
      </p>

      <p className="common-text_paragraph">Release and Indemnification</p>

      <p className="common-text_paragraph">
        You, on behalf of your successors, assigns, heirs, and personal representatives hereby irrevocably and fully
        release the Company, and its subsidiaries, Affiliates and each of their officers, directors, employees, assigns,
        agents and representatives from and against any and all suits, claims, actions, causes of action, arbitration,
        liabilities, obligations, damages, losses, penalties or fines known or unknown, arising out of or in connection
        with your use of this Website, and the products and/or services provided by the Company.
      </p>

      <p className="common-text_paragraph">
        You agree to indemnify and hold the Company and its subsidiaries, Affiliates, officers, agents, employees,
        partners, and licensors harmless from any claim or demand, including reasonable attorneys’ fees and costs of
        suit, made by any third-party due to or arising out of (1) your use of this Website and the products and/or
        services provided by the Company, (2) information that you submit, transmit, or otherwise make available via
        this Website or otherwise, including but not limited to Submissions, or (3) your breach of this Terms of Use.
      </p>

      <p className="common-text_paragraph">
        You agree to indemnify, defend and hold us harmless from and against any and all liability, claims, causes of
        actions, damages, costs and expenses, including but not limited to, attorneys’ fees and costs of suit, arising
        out of your breach of these Terms of Use.
      </p>

      <p className="common-text_paragraph">Exclusions</p>

      <p className="common-text_paragraph">
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY
        FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. THEREFORE SOME OF THE EXCLUSIONS AND/OR LIMITATIONS STATED ABOVE MAY
        NOT APPLY TO YOU.
      </p>

      <p className="common-text_paragraph">Third-Party Websites</p>

      <p className="common-text_paragraph">
        The Website may contain links to other websites for your convenience and information and to help us provide our
        services and products. These websites may be operated by companies that are not affiliated with the Company, and
        may have different privacy policies and terms of use. The Company does not control the content that appears on
        these websites or their privacy practices. Notwithstanding the presentation of, or links to, any third-party
        information or website on the Website, no such presentation shall be considered an endorsement, guarantee,
        representation or warranty, either express or implied, by us on behalf of any third-party. We shall have no
        liability or responsibility whatsoever for the content, subject matter or substance of any information accessed
        or obtained from third-party websites accessed from or via the Website. Access to third-party websites from the
        Website is done at your own risk.
      </p>

      <div id="GENERAL_INFORMATION"></div>

      <h2 className="common-header_h2">XII. General Information</h2>
      <p className="common-text_paragraph">
        <b>Entire Agreement. </b> These Terms of Use, together with the Privacy Notice, constitute the entire agreement
        between you and the Company governing your use of this Website, superseding any prior agreements between you and
        the Company with respect to this Website. You also may be subject to additional terms and conditions that may
        apply when you use or purchase certain services of the Company.
      </p>

      <p className="common-text_paragraph">
        <b>Waiver and Severability.</b> The failure of the Company to enforce any right of the provisions in the Terms
        of Use or Privacy Notice shall not constitute a waiver of such right or provision. If any provision of the Terms
        of Use is found by a court of competent jurisdiction to be invalid, illegal, or unenforceable, it shall not
        affect any other provision of the Terms of Use or Privacy Notice, and the Terms of Use or Privacy Notice shall
        be construed without regard to the invalid, illegal, or unenforceable provision.
      </p>

      <p className="common-text_paragraph">
        <b>Choice of Law; Jurisdiction; Venue. </b> Your use of this Website and any dispute arising out of or in
        connection with this Website shall be governed by the laws of the State of New Jersey without giving effect to
        any conflict of laws provisions. By accessing this Website, you agree that any action or proceeding arising out
        of or in connection with this Website shall be brought solely in a court of competent jurisdiction sitting in
        the State of New Jersey, and you agree to submit to the personal and exclusive jurisdiction of the courts
        located in New Jersey. You hereby waive any defense of an inconvenient forum to the maintenance of any action or
        proceeding in other courts and any objection to venue with respect to such proceeding.
      </p>

      <p className="common-text_paragraph">
        <b>Viruses and Transmission of Sensitive Information</b>
      </p>

      <p className="common-text_paragraph">
        We cannot and do not guarantee or warrant that the materials contained on this Website will be free of viruses,
        worms or other code or related hazards that may have destructive properties (collectively “viruses”). It is your
        responsibility to ensure that you have sufficient procedures, firewalls, checkpoints, and safeguards within your
        computer system to satisfy your particular requirements to protect against viruses. The Company does not assume
        any responsibility or risk for your use of the Internet, nor do we assume any responsibility for any products or
        services of, or hyperlinks to, third-parties.
      </p>

      <p className="common-text_paragraph">
        <b>Miscellaneous</b>
      </p>

      <p className="common-text_paragraph">
        We do not represent that materials on the Website are appropriate or available for use in your location. Persons
        who choose to access the Website do so on their own initiative and at their own risk, and are responsible for
        compliance with local laws, if and to the extent local laws are applicable. You agree that regardless of any
        statute or law to the contrary, any claim or cause of action arising out of or related to use of the Website
        must be filed within one (1) year after such claim or cause of action arose or be forever barred.
      </p>
    </Fragment>
  )
}

const TermsTextES = ({ privacyUrl = '/privacy' }) => {
  const { origin } = document.location

  const LinkToHome = () => <Link to="/">{origin}</Link>
  const LinkToPrivacy = () => <Link to={privacyUrl}>{origin + privacyUrl}</Link>

  return (
    <Fragment>
      <p className="common-text_paragraph">
        ¡Bienvenido! Su uso del sitio web de Vital Decision, LLC ubicado en &nbsp;
        <LinkToHome /> y los servicios disponibles a través del mismo (colectivamente, el &quot;Sitio Web&quot;) están sujetos
        a su aceptación de este Acuerdo de Términos y Condiciones de Uso (los &quot;Términos de Uso&quot;), que
        incorpora por referencia nuestro Aviso de Privacidad, ubicado en &nbsp;
        <LinkToPrivacy />. Si no comprende o tiene preguntas sobre los Términos de Uso, detenga inmediatamente todo
        uso del Sitio Web. Si no acepta estos Términos de Uso, no podrá utilizar el Sitio Web. Si en algún momento no
        está de acuerdo con estos Términos de Uso, debe dejar de usar este Sitio Web.
      </p>
      <p className="common-text_paragraph">
        Los Términos de Uso aplican solo al Sitio Web y no aplican a ningún sitio web que esté vinculado al Sitio Web.
        Para acceder a los términos y condiciones o a las políticas de privacidad de los sitios web vinculados,
        consulte las políticas de dichos sitios web. Es su obligación revisar los Términos de Uso antes
        de acceder al Sitio Web. Cualquier cambio en los Términos de Uso entrará en vigencia inmediatamente después de
        que los publiquemos en el Sitio Web, a menos que se indique lo contrario. Nos reservamos el derecho de cambiar
        el contenido del Sitio Web en cualquier momento, con o sin previo aviso.
      </p>
      <div id="DEFINITIONS"></div>
      <h2 className="common-header_h2"> I. Definiciones</h2>
      <p className="common-text_paragraph">A los efectos de estos Términos de Uso, aplican las siguientes definiciones:</p>
      <p className="common-text_paragraph">
        <b>“Afiliados” </b> se refiere a las empresas y divisiones bajo la propiedad de la Empresa o que son propietarias de la Empresa.
      </p>
      <p className="common-text_paragraph">
        <b>“Empresa” </b> significa Vital Decisions, LLC, el propietario y operador del Sitio Web.
      </p>
      <p className="common-text_paragraph">
        <b>“Contenido” </b> incluye todo el Texto, Gráficos, Diseño y Programación utilizados en el Sitio Web.
      </p>
      <p className="common-text_paragraph">
        <b>“Gráficos” </b> incluye todos los logotipos, botones y otros elementos gráficos del Sitio Web, incluidas las
        combinaciones de colores y el diseño de la página del Sitio Web, con la excepción de las marcas registradas
        y propiedad intelectual pertenecientes a terceros y mostradas con permiso.
      </p>
      <p className="common-text_paragraph">
        <b>“Programación” </b> incluye, entre otros, tanto el código del lado del cliente (incluido, entre otros, HTML,
        JavaScript, etc.) como el código del lado del servidor (incluidas, entre otras, páginas Active Server, VBScript,
        bases de datos, etc.) utilizados en el Sitio Web.
      </p>
      <p className="common-text_paragraph">
        <b>“Texto” </b> incluye todo el texto en cada página del Sitio Web, ya sea editorial, de navegación o instructivo.
      </p>
      <p className="common-text_paragraph">
        <b>“Usted” </b> o  <b>“su” </b> (sea o no con mayúscula inicial) se refiere a la persona que accede al Sitio
        Web y acepta estos Términos de Uso.
      </p>
      <div id="USE_OF_THIS_WEBSITE"></div>
      <h2 className="common-header_h2">II. Uso de Este Sitio Web</h2>
      <p className="common-text_paragraph">
        Este Sitio Web proporciona un servicio de planificación de atención anticipada que le permite obtener directivas
        anticipadas específicas del estado, poder de atención médica y otros formularios similares.
      </p>
      <p className="common-text_paragraph">
        Le otorgamos una licencia limitada para ver, descargar y usar una única copia del Sitio Web únicamente para su
        uso personal, no comercial y solo como ayuda para usar los servicios que se proporcionan en el Sitio Web.
        El Sitio Web solo se puede usar de manera consistente con este propósito, y solo dentro del contexto
        de los servicios y la información establecidos en el Sitio Web. Salvo lo dispuesto en este documento,
        no se le permite descargar (que no sea el almacenamiento en caché de la página), copiar o cambiar cualquier
        parte del Sitio Web, a menos que tenga nuestro consentimiento expreso por escrito.
      </p>
      <p className="common-text_paragraph">
        Intentamos asegurarnos de que la información en el Sitio Web esté completa, precisa y actualizada.
        A pesar de nuestros esfuerzos, la información en este Sitio Web en ocasiones puede ser inexacta,
        incompleta o desactualizada, y no garantizamos la integridad, precisión o vigencia de la información en el Sitio Web.
      </p>
      <div id="ACCOUNT_REGISTRATION"></div>
      <h2 className="common-header_h2">III. Registro de Cuenta</h2>
      <p className="common-text_paragraph">
        Ciertas funciones del Sitio Web pueden requerir registro, incluida la compra de productos o servicios.
        Solo puede crear y utilizar una cuenta para su uso personal. Si se registra o nos proporciona información
        de alguna manera, acepta proporcionar solo información verdadera, precisa, actual y completa.
        Usted es responsable de mantener la seguridad de su cuenta y es totalmente responsable de todas
        las actividades y acciones que se produzcan en relación con la cuenta. Si le proporcionamos un nombre
        de usuario y/o contraseña, usted acepta proteger dicha información, entre otras cosas, manteniendo
        la confidencialidad de su contraseña y otra información relacionada con su cuenta. Debe notificarnos
        inmediatamente de cualquier uso no autorizado de su cuenta o cualquier otra violación de seguridad.
        No seremos responsables de ningún acto u omisión por su parte, incluidos, entre otros, los daños de cualquier
        tipo incurridos como resultado de dichos actos u omisiones. Si elimina su cuenta, es posible que aún
        necesitemos retener cierta información, incluidos los datos de su encuesta, para el mantenimiento de
        registros, con fines administrativos, legales y técnicos. Al registrarse para obtener una cuenta,
        nos otorga permiso para enviarle correos electrónicos, encuestas, ofertas, promociones y otro material
        de marketing por correo electrónico o notificación automática, incluido contenido comercial
        y transaccional/de relaciones. Puede darse de baja en cualquier momento.
      </p>
      <div id="RESTRICTIONS_ON_USE_OF_THE_WEBSITE"></div>
      <h2 className="common-header_h2">IV. Restricciones en el Uso del Sitio Web</h2>
      <p className="common-text_paragraph">
        La información que se muestra en el Sitio Web no se puede utilizar para ningún propósito, excepto en relación
        con su uso directo del Sitio Web según lo permitido por estos Términos de Uso, y no puede ser extraída,
        resumida, duplicada o eliminada de otro modo del Sitio Web, excepto con nuestro permiso explícito y por escrito.
        No puede recopilar ni usar ninguna parte del contenido de este Sitio Web de ninguna manera derivada, ni descargar,
        copiar o vender información u otro material para uso de cualquier otra parte.
        No puede recopilar información y datos en el Sitio Web de minería, robots u otras herramientas de extracción.
      </p>
      <p className="common-text_paragraph">
        Además, usted declara, garantiza y acepta que no usará (o planificará, alentará o ayudará a otros a usar)
        el Sitio Web para ningún propósito o de cualquier manera que esté prohibido por estos Términos de Uso o por la ley aplicable.
        Tampoco puede interferir con el funcionamiento adecuado del Sitio Web, incluido, entre otros, atacando,
        pirateando o intentando penetrar cualquier elemento no accesible al público del Sitio Web o sus servidores o red,
        mediante el uso de bots, Troyanos, virus, ataques de DNS u otra tecnología que está diseñada o destinada
        a interferir con el funcionamiento adecuado del Sitio Web o el uso del Sitio Web por parte de cualquier usuario.
        Usted acepta que no eludirá ni intentará eludir ninguna tecnología de control de acceso
        o seguridad implementada en el Sitio Web, o los servidores y la red asociados con el Sitio Web.
        Cualquier uso no autorizado cancela el permiso o la licencia otorgada por nosotros,
        además de todos los derechos legales o en equidad. Además, acepta específicamente
        no utilizar este Sitio Web para realizar cualquiera de las siguientes acciones:
      </p>
      <ul className="common-text-list">
        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Enviar por correo electrónico o transmitir contenido que sea ilegal, dañino, amenazante, abusivo, acosador,
            agravante, difamatorio, obsceno, calumnioso, que invada la privacidad de otra persona o que sea ilegal de cualquier otra manera;
          </p>
        </li>
        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Suplantar o tergiversar su conexión con cualquier otra entidad o persona o manipular identificadores para disfrazar el origen del contenido;
          </p>
        </li>
        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Enviar por correo electrónico o transmitir contenido que infrinja la propiedad intelectual o el derecho de cualquier entidad o persona;
          </p>
        </li>
        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Violar intencionalmente o involuntariamente cualquier ley local, estatal, nacional o internacional aplicable;
          </p>
        </li>
        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Enviar por correo electrónico o transmitir material que incluya virus, gusanos o cualquier otro código
            de computadora, archivos o programas diseñados para interrumpir, destruir o limitar la funcionalidad
            de cualquier software o hardware de computadora o telecomunicaciones; y/o
          </p>
        </li>
        <li className="common-text-list_item">
          <p className="common-text_paragraph">
            Interrumpir el flujo normal de comunicaciones o afectar la capacidad de otros para participar en actividades a través de este Sitio Web.
          </p>
        </li>
      </ul>
      <div id="TERMINATION_OF_ACCESS"></div>
      <h2 className="common-header_h2">V. Cancelación del Acceso</h2>
      <p className="common-text_paragraph">
        El uso de este Sitio Web es un privilegio. Nos reservamos el derecho de suspender o cancelar su acceso
        al Sitio Web por cualquier motivo en cualquier momento, a nuestro exclusivo criterio.
        El Sitio Web y su contenido no están destinados al uso de niños menores de 18 años. Los niños menores
        de 18 años no pueden usar ni enviar ninguna información al Sitio Web. Las personas menores de 18 años
        solo pueden acceder al Sitio Web bajo la supervisión de un padre o tutor legal que tenga al menos 18 años
        de edad y que acepte estar sujeto y ser responsable de las acciones tomadas y de la información enviada en el Sitio Web.
      </p>
      <div id="INFORMATION_YOU_PROVIDE_TO_US_VIA_THIS_WEBSITE"></div>
      <h2 className="common-header_h2">VI. Información que nos Proporciona a Través de este Sitio Web</h2>
      <p className="common-text_paragraph">
        Si elige proporcionar información personal a través de este Sitio Web, la información se utilizará solo
        para ciertos fines, como se describe en nuestro Aviso de Privacidad. Además, podemos recopilar o compartir
        cierta información en función de su uso del Sitio Web, como se describe en nuestro Aviso de Privacidad.
      </p>
      <p className="common-text_paragraph">
        Con el fin de facilitar las comunicaciones entre usted y nosotros, este sitio web le ofrece la posibilidad
        de ponerse en contacto con nosotros. Aunque nos esforzamos por proteger y asegurar nuestras comunicaciones en línea,
        y utilizamos las medidas de seguridad detalladas en nuestro Aviso de Privacidad para proteger su información,
        por favor tenga en cuenta que no se puede garantizar que ningún dato transmitido a través de Internet sea completamente
        seguro y que ninguna medida de seguridad sea perfecta o impenetrable. No tendremos responsabilidad alguna por
        las instrucciones o solicitudes de correo electrónico no aceptadas o no procesadas, o por cualquier pérdida
        o daño que surja de o en conexión con cualquier uso no autorizado por parte de terceros de cualquier información
        que envíe por correo electrónico.
      </p>
      <p className="common-text_paragraph">
        <b>Limitaciones de la Información Enviada</b>
      </p>
      <p className="common-text_paragraph">
        No buscamos recibir ninguna información confidencial o patentada o secretos comerciales de terceros
        a través del Sitio Web, y buscamos solo la información mínima necesaria para brindar nuestros servicios y productos.
      </p>
      <p className="common-text_paragraph">
        <b>Propiedad de los Envíos</b>
      </p>
      <p className="common-text_paragraph">
        Al enviarnos información (&quot;Envíos&quot;), nos otorga una licencia mundial irrevocable y sin restricciones para usar,
        modificar, reproducir, adaptar, transmitir, vender, licenciar y sublicenciar, crear trabajos derivados de,
        mostrar públicamente, realizar y distribuir sus Envíos siempre que no estén identificados o agregados
        para cualquier propósito (comercial o de otro tipo), en cualquier forma, medio o tecnología, ya sea conocida
        ahora o desarrollada en el futuro, sola o como parte de otros trabajos, sin pago adicional u otra compensación
        para usted, sujeta al Aviso de Privacidad. No publicaremos ninguna información que pueda usarse para identificarlo
        personalmente en relación con el Envío para proteger su privacidad.
      </p>
      <p className="common-text_paragraph">
        Usted acepta indemnizarnos por todas las reclamaciones que surjan de o en conexión con cualquier reclamación
        sobre cualquier derecho en cualquier Envío, como se establece a continuación. Además, renuncia irrevocablemente
        a cualquier &quot;derecho moral&quot; u otros derechos con respecto a la atribución de autoría o integridad de los materiales
        con respecto a cualquier Envío que pueda tener bajo cualquier ley aplicable o bajo cualquier teoría legal,
        excluyendo que Esta sección sobrevivirá a la terminación de este Acuerdo para cualquier razón.
      </p>
      <div id="MONITORING_OF_INFORMATION"></div>
      <h2 className="common-header_h2">VII. Monitoreo de la Información</h2>
      <p className="common-text_paragraph">
        No asumimos ninguna obligación de revisar o monitorear el contenido u otra información enviada al Sitio Web por terceros.
        Usted asume el riesgo de verificar la exactitud de dicha información publicada a través de una investigación independiente.
        No obstante lo anterior, podemos, a nuestra discreción, revisar la información enviada al Sitio Web para cualquier propósito
        y nos reservamos el derecho, a nuestra entera discreción, a eliminar, editar o rechazar cualquier información
        enviada al Sitio Web por cualquier motivo.
      </p>
      <p className="common-text_paragraph">
        Nos reservamos el derecho de cooperar con las autoridades policiales u órdenes judiciales que nos soliciten
        o nos indiquen que revelemos la identidad de cualquier persona que se crea que está violando los Términos de Uso o la ley.
        Usted acepta que, en caso de que recibamos una citación emitida por un tribunal o por una agencia gubernamental
        o de aplicación de la ley, podemos optar unilateralmente por cumplir con dichas citaciones sin su consentimiento
        o notificación previa y podemos divulgar su dirección IP, nombre de usuario, nombre, ubicación de la IP u otra información
        en respuesta a los mismos, sujeto a las leyes aplicables. Usted acepta renunciar e indemnizarnos y eximirnos
        de toda responsabilidad de y contra cualquier reclamo que resulte de, o en conexión con, cualquier acción
        de nuestra parte con respecto a cualquier investigación, ya sea por nosotros o por las autoridades policiales.
      </p>
      <div id="NO_MEDICAL_ADVICE"></div>
      <h2 className="common-header_h2">VIII. Sin Asesoramiento Médico</h2>
      <p className="common-text_paragraph">
        El Sitio Web, su contenido y los servicios y productos proporcionados por la Empresa son solo para fines
        informativos y no constituyen asesoramiento, diagnóstico o tratamiento médico profesional. Usar, acceder y/o navegar por
        el Sitio Web y/o proporcionar información personal o médica a la Empresa no crea una relación médico-paciente
        entre usted y la Empresa, y nada de lo contenido en el Sitio Web está destinado a reemplazar los servicios
        o ser un sustituto del consejo médico de un médico con licencia o profesional de la salud capacitado con licencia
        en su estado. Por favor busque el consejo de su médico u otro proveedor de salud calificado con cualquier pregunta
        que pueda tener con respecto a una condición médica o su testamento en vida. Por la presente, usted acepta que no
        tomará ninguna decisión médica o relacionada con la salud basada en todo o en parte en cualquier contenido del Sitio Web.
      </p>
      <div id="NO_LEGAL_ADVICE"></div>
      <h2 className="common-header_h2">IX. Sin Asesoramiento Legal</h2>
      <p className="common-text_paragraph">
        El Sitio Web, su contenido y los servicios y productos proporcionados por la Empresa son solo para fines
        informativos y no están destinados a sustituir el asesoramiento legal profesional. {' '}
        <b>
          El uso, acceso y/o navegación del Sitio Web y/o proporcionar información personal a la Empresa
          no crea una relación abogado-cliente entre usted y la Empresa.
        </b>{' '}
        Las leyes de cada estado pueden incluir requisitos separados para directivas anticipadas, poderes notariales
        de atención médica y documentos similares, y pueden estar sujetos a interpretación por diferentes tribunales.
        La información contenida en el Sitio Web y los servicios y productos proporcionados por la Empresa no son
        asesoramiento legal y no se garantiza que sean correctos, completos o actualizados. El Sitio Web y los productos
        y servicios de la Empresa no sustituyen el asesoramiento o los servicios de un abogado con licencia en la jurisdicción correspondiente.
      </p>
      <div id="INTELLECTUAL_PROPERTY_RIGHTS"></div>
      <h2 className="common-header_h2">X. Derechos de Propiedad Intelectual</h2>
      <p className="common-text_paragraph">
        La Empresa posee todos y cada uno de los derechos de propiedad intelectual relacionados con la marca Vital Decisions,
        el nombre comercial, la imagen comercial y otro contenido, incluido: derechos de autor, marca comercial,
        marca de servicio, nombre comercial, imagen comercial, logotipo de propiedad, distintivo, identificador comercial
        y/u otro texto y gráficos que tengan o proporcionen la &quot;apariencia&quot; de la imagen de marca de la empresa,
        sí como todo el Contenido, incluido el Texto, los Gráficos, la Programación, las Fotografías, el Video y
        el Audio incluidos en este documento (la “Propiedad Intelectual”). Su uso del Sitio Web no le otorga ningún derecho
        o licencia relacionados con la propiedad intelectual, incluidos, entre otros, los derechos de autor, derechos de marca registrada,
        derechos de patente, derechos de base de datos, derechos morales, derechos sui generis y otros derechos
        de propiedad intelectual y de propiedad en los mismos, excepto en los casos expresamente previstos en estos Términos de Uso.
        Ninguna parte de la Propiedad Intelectual puede ser utilizada, reproducida, publicada, transmitida, distribuida,
        mostrada, realizada, exhibida, modificada, utilizada para crear trabajos derivados, vendida, revendida o utilizada
        en cualquier venta, o explotada de cualquier manera, en su totalidad o en parte, excepto según lo dispuesto en este
        documento y a menos que obtenga nuestro consentimiento previo por escrito. No puede reproducir, modificar, exhibir,
        vender o distribuir la Propiedad Intelectual, ni utilizarla de ninguna otra manera para fines públicos o comerciales.
        Las limitaciones anteriores incluyen, entre otras, copiar o adaptar el código HTML utilizado para generar páginas web
        en el Sitio Web, así como cualquier Gráfico o Programación. Todo el resto del Contenido, incluidos los nombres de productos,
        nombres de servicios, marcas comerciales, marcas de servicio y otra propiedad intelectual es propiedad de sus respectivos dueños,
        como se indica, y solo se puede usar según lo permitido.
      </p>
      <div id="DISCLAIMER"></div>
      <h2 className="common-header_h2">XI. Renuncia de Garantías y Limitaciones de Responsabilidad</h2>
      <p className="common-text_paragraph">
        <b>
          Su consentimiento y aceptación de las siguientes renuncias y limitaciones es un incentivo material para que
          le permitamos acceder al Sitio Web y para que la Empresa le proporcione sus productos y/o servicios. Su uso del Sitio Web,
          y las obligaciones y responsabilidades de nosotros con respecto a su uso del Sitio Web y los productos y/o servicios
          proporcionados por la Empresa, están expresamente limitados de la siguiente manera:
        </b>
      </p>
      <h2 className="common-header_h2">RENUNCIA DE GARANTÍAS</h2>
      <p className="common-text_paragraph">
        EL SITIO WEB, SU CONTENIDO Y LOS SERVICIOS Y/O PRODUCTOS OFRECIDOS POR LA EMPRESA SE PROPORCIONAN &quot;COMO SON&quot; Y
        SIN GARANTÍA ALGUNA. LA EMPRESA RENUNCIA A TODAS Y CADA UNA DE LAS GARANTÍAS EXPRESAS E IMPLÍCITAS EN LA MÁXIMA
        MEDIDA PERMITIDA POR LA LEY, INCLUIDAS, ENTRE OTRAS, LAS GARANTÍAS DE COMERCIABILIDAD, TITULACIÓN, SIN INFRACCIÓN
        DE DERECHOS DE TERCEROS E IDONEIDAD PARA UN FIN DETERMINADO. Su uso de este Sitio Web y los SERVICIOS,
        y/o la confianza en cualquiera de sus contenidos es bajo su propio riesgo.
      </p>
      <p className="common-text_paragraph">
        LA EMPRESA no garantiza que las funciones o contenidos en este Sitio Web serán ininterrumpidos, precisos o libres
        de errores. Usted, y no LA EMPRESA, asume el costo de todo el servicio, reparación o corrección necesarios
        en caso de que haya alguna pérdida o daño que surja de o en conexión con el uso de este Sitio Web o su contenido.
        LA EMPRESA NO GARANTIZA NI HACE NINGUNA REPRESENTACIÓN CON RESPECTO AL USO, O EL RESULTADO DEL USO, DEL CONTENIDO
        DE ESTE SITIO WEB RELACIONADO CON LA PRECISIÓN, CONFIABILIDAD O DE OTRO TIPO. EL CONTENIDO DE ESTE SITIO WEB PUEDE
        INCLUIR ERRORES (INCLUIDOS, ENTRE OTROS, ERRORES TÉCNICOS O TIPOGRÁFICOS), Y PODEMOS REALIZAR CAMBIOS O MEJORAS
        EN ESTE SITIO WEB EN CUALQUIER MOMENTO con o sin previo aviso.
      </p>
      <p className="common-text_paragraph">
        NI LA EMPRESA NI NINGUNA OTRA PERSONA O ENTIDAD ASOCIADA CON EL DISEÑO O MANTENIMIENTO DE ESTE SITIO WEB SERÁN
        RESPONSABLES DE NINGUNA MANERA POR CUALQUIER DAÑO, PÉRDIDA, LESIÓN O MAL FUNCIONAMIENTO ASOCIADO CON EL USO DE ESTE SITIO WEB.
      </p>
      <p className="common-text_paragraph">LIMITACIÓN DE RESPONSABILIDAD</p>
      <p className="common-text_paragraph">
        EN NINGÚN CASO LA EMPRESA Y/O SUS SUBSIDIARIAS, AFILIADAS, EMPRESAS RELACIONADAS, PROVEEDORES, ANUNCIANTES,
        PATROCINADORES, LOS PROVEEDORES DE SERVICIOS DE TERCEROS Y SUS RESPECTIVOS EMPLEADOS, FUNCIONARIOS,
        DIRECTORES Y AGENTES SERÁN RESPONSABLES DE CUALQUIER INCIDENTE, DAÑOS CONSECUENTES, PUNITIVOS,
        INDIRECTOS O ESPECIALES (INCLUIDA LA PÉRDIDA DE BENEFICIOS Y DAÑOS) YA SEA EN BASE A LA GARANTÍA, CONTRATO,
        AGRAVIO O CUALQUIER OTRA TEORÍA LEGAL, Y SI LA EMPRESA ES INFORMADA O NO DE LA POSIBILIDAD DE TALES DAÑOS.
        DEBIDO A QUE ALGUNOS ESTADOS O JURISDICCIONES NO PERMITEN LA EXCLUSIÓN O LIMITACIÓN DE RESPONSABILIDAD POR DAÑOS
        CONSECUENTES O INCIDENTALES, ES POSIBLE QUE LAS LIMITACIONES ESTABLECIDAS EN ESTE PÁRRAFO NO APLIQUEN A USTED.
        SI LAS LIMITACIONES ANTERIORES SE CONSIDERAN INAPLICABLES O INEJECUTABLES POR CUALQUIER MOTIVO, ENTONCES LA RESPONSABILIDAD
        MÁXIMA DE LA EMPRESA ANTE USTED POR CUALQUIER TIPO DE DAÑO SE LIMITARÁ AL MONTO TOTAL QUE LE PAGÓ A LA EMPRESA
        POR LOS SERVICIOS O CIEN DÓLARES ESTADOUNIDENSES, LO QUE SEA MAYOR.
      </p>
      <p className="common-text_paragraph">Liberación e Indemnización</p>
      <p className="common-text_paragraph">
        Usted, en nombre de sus sucesores, cesionarios, herederos y representantes personales por la presente libera
        de manera irrevocable y plena a la Empresa, y sus subsidiarias, afiliadas y cada uno de sus funcionarios,
        directores, empleados, asignados, agentes y representantes de y contra todos y cada uno de los juicios, reclamos,
        acciones, causas de acción, arbitraje, pasivos, obligaciones, daños, pérdidas, sanciones o multas conocidas
        o desconocidas, que surjan de o en conexión con su uso de este Sitio Web, y los productos y/o servicios proporcionados por la Empresa.
      </p>
      <p className="common-text_paragraph">
        Usted acepta indemnizar y eximir a la Empresa y sus subsidiarias, afiliados, funcionarios, agentes, empleados,
        socios y licenciatarios de cualquier reclamo o demanda, incluidos los honorarios razonables de abogados y los
        costos de la demanda, realizada por un tercero debido a o que surja de (1) su uso de este Sitio Web y los productos
        y/o servicios proporcionados por la Empresa, (2) información que envía, transmite o pone a disposición de otro modo
        a través de este Sitio Web o de otro modo, incluidos, entre otros, Envíos, o (3) su incumplimiento de estos Términos de Uso.
      </p>
      <p className="common-text_paragraph">
        Usted acepta indemnizar, defender y mantenernos indemnes de y contra cualquier responsabilidad, reclamos,
        causas de acciones, daños, costos y gastos, incluidos, entre otros, los honorarios de abogados y los costos
        de la demanda, que surjan de su incumplimiento de estos Términos de Uso.
      </p>
      <p className="common-text_paragraph">Exclusiones</p>
      <p className="common-text_paragraph">
        ALGUNAS JURISDICCIONES NO PERMITEN LA EXCLUSIÓN DE CIERTAS GARANTÍAS O LA LIMITACIÓN O EXCLUSIÓN
        DE RESPONSABILIDAD POR DAÑOS INCIDENTALES O CONSECUENTES. POR LO TANTO, ES POSIBLE QUE ALGUNAS DE LAS
        EXCLUSIONES Y/O LIMITACIONES MENCIONADAS ANTERIORMENTE NO APLIQUEN A USTED.
      </p>
      <p className="common-text_paragraph">Sitios Web de Terceros</p>
      <p className="common-text_paragraph">
        El Sitio Web puede contener enlaces a otros sitios web para su conveniencia e información y para ayudarnos
        a proporcionar nuestros servicios y productos. Estos sitios web pueden ser operados por empresas que no están
        afiliadas a la Empresa y pueden tener diferentes políticas de privacidad y términos de uso. La Empresa no controla
        el contenido que aparece en estos sitios web o sus prácticas de privacidad. Sin perjuicio de la presentación de,
        o enlaces a, cualquier información o sitio web de terceros en el Sitio Web, dicha presentación no se considerará
        un respaldo, garantía, representación o garantía, ya sea expresa o implícita, por nuestra parte en nombre de un tercero.
        No tendremos responsabilidad alguna por el contenido, el tema o la sustancia de cualquier información
        a la que se acceda u obtenida de sitios web de terceros a los que se acceda desde o a través del Sitio Web.
        El acceso a sitios web de terceros desde el Sitio Web se realiza bajo su propio riesgo.
      </p>
      <div id="GENERAL_INFORMATION"></div>
      <h2 className="common-header_h2">XII. Información General</h2>
      <p className="common-text_paragraph">
        <b>Acuerdo Completo. </b> Estos Términos de Uso, conjuntamente con el Aviso de Privacidad, constituyen
        el acuerdo completo entre usted y la Empresa que rige su uso de este Sitio Web, reemplazando cualquier acuerdo
        anterior entre usted y la Empresa con respecto a este Sitio Web. También puede estar sujeto a términos
        y condiciones adicionales que pueden aplicarse cuando usa o compra ciertos servicios de la Empresa.
      </p>
      <p className="common-text_paragraph">
        <b>Renuncia y Divisibilidad.</b> El hecho de que la Empresa no haga cumplir cualquier derecho de las disposiciones
        de los Términos de Uso o el Aviso de Privacidad no constituirá una renuncia a dicho derecho o disposición.
        Si un tribunal de jurisdicción competente determina que alguna disposición de los Términos de Uso es inválida,
        ilegal o inaplicable, no afectará ninguna otra disposición de los Términos de Uso o del Aviso de Privacidad,
        y los Términos de Uso o el Aviso de Privacidad se interpretarán sin tener en cuenta la disposición inválida,
        ilegal o inaplicable.
      </p>
      <p className="common-text_paragraph">
        <b>Elección de la Ley; Jurisdicción; Lugar. </b> Su uso de este Sitio Web y cualquier controversia que surja de o
        en conexión con este Sitio Web se regirá por las leyes del estado de Nueva Jersey sin dar efecto a cualquier conflicto
        de disposiciones legales. Al acceder a este Sitio Web, usted acepta que cualquier acción o procedimiento que surja
        de o en conexión con este Sitio Web se iniciará únicamente en un tribunal de jurisdicción competente del estado
        de Nueva Jersey, y acepta someterse a la jurisdicción personal y exclusiva de los tribunales ubicados en Nueva Jersey.
        Por la presente renuncia a cualquier defensa de un foro inconveniente al mantenimiento de cualquier acción o procedimiento
        en otros tribunales y cualquier objeción al lugar con respecto a dicho procedimiento.
      </p>
      <p className="common-text_paragraph">
        <b>Virus y Transmisión de Información Confidencial</b>
      </p>
      <p className="common-text_paragraph">
        No podemos y no garantizamos ni aseguramos que los materiales contenidos en este Sitio Web estén libres de virus,
        gusanos u otro código o peligros relacionados que puedan tener propiedades destructivas (colectivamente &quot;virus&quot;).
        Es su responsabilidad asegurarse de tener suficientes procedimientos, firewalls, puntos de control
        y salvaguardas dentro de su sistema informático para satisfacer sus requisitos particulares de protección contra virus.
        La Empresa no asume ninguna responsabilidad o riesgo por su uso de Internet, ni asumimos ninguna responsabilidad
        por ningún producto o servicio de, o hipervínculos a, terceros.
      </p>
      <p className="common-text_paragraph">
        <b>Misceláneos</b>
      </p>
      <p className="common-text_paragraph">
        No representamos que los materiales en el Sitio Web sean apropiados o estén disponibles para su uso en su ubicación.
        Las personas que eligen acceder al Sitio Web lo hacen por su propia iniciativa y bajo su propio riesgo,
        y son responsables del cumplimiento de las leyes locales, si y en la medida en que las leyes locales sean aplicables.
        Usted acepta que, independientemente de cualquier estatuto o ley que indique lo contrario, cualquier reclamo o causa
        de acción que surja de o esté relacionado con el uso del Sitio Web debe presentarse dentro de un (1) año después
        de que dicho reclamo o causa de acción surgió o prescribirá para siempre.
      </p>
    </Fragment>
  )
}

const TermsText = ({ termsUrl = '/terms' }) => {
  const { i18n } = useTranslation()

  switch (i18n.language) {
    case LOCALES.en:
      return <TermsTextEN termsUrl={termsUrl} />

    case LOCALES.es:
      return <TermsTextES termsUrl={termsUrl} />

    default:
      return <TermsTextEN termsUrl={termsUrl} />
  }
}

export default TermsText
