import { isEmpty, startsWith, mapObjIndexed } from 'ramda'
import 'whatwg-fetch'
import { stringify } from 'qs'

import { showError } from './toastUtils'
import * as Routes from '../constants/UrlConstants'
import { getPathPrefix, withOrganizationPrefix, pathnameClearedFromPrefix } from './organizationTheme'

const path = {
  protocol: `${window.location.protocol}//`,
  host: window.location.hostname,
  port: window.location.port ? `:${window.location.port}` : '',
  pathPrefix: getPathPrefix(),
}

const basePath = `${path.protocol}${path.host}${path.port}/${path.pathPrefix ? `${path.pathPrefix}/` : ''}`

export const REQUESTS = {
  LOGIN: 'auth/login',
  LOGOUT: 'auth/logout',
  SIGNUP: 'auth/sign_up',
  VALIDATE_TOKEN: 'auth/validate_token',
  RESET_PASSWORD: 'password/reset',
  CHANGE_PASSWORD: 'password/change',
  GENERATE_PDF_URL: 'directive_pdf',
}

export const getAuthToken = () => {
  return window.localStorage.getItem('authToken')
}

export const clearStorage = () => {
  window.localStorage.clear()
}

export const setAuthToken = (token) => {
  window.localStorage.setItem('authToken', token)
}

export const clearAuthToken = () => {
  window.localStorage.removeItem('authToken')
}

const getHeaders = () => {
  const headers = new Headers()
  const authToken = getAuthToken()

  if (authToken) headers.append('Authorization', `Bearer ${authToken}`)
  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')

  return headers
}

const dispatch500 = () => {
  showError('Something went wrong')
}

export const createRedirectUrl = (withFromQuery = true) => {
  const from = pathnameClearedFromPrefix()
  let query = ''

  if (!startsWith('/login', from)) {
    query = withFromQuery && from && from !== '/' ? '?' + stringify({ from }) + window.location.search : ''
  } else {
    query = window.location.search
  }

  return withOrganizationPrefix(`/login${query}`)
}

export const redirectToLogin = (withFromQuery = true) => window.location.assign(createRedirectUrl(withFromQuery))

export const isLoginPage = () => startsWith('/login', pathnameClearedFromPrefix())

export const publicPages = [
  Routes.BASE_URL,
  Routes.SIGNIN_URL,
  Routes.SIGNUP_URL,
  Routes.PASSWORD_RESET_URL,
  Routes.PASSWORD_RECOVERY_URL,
  Routes.PRIVACY_POLICY_URL,
]
export const isPublicPage = () => publicPages.some((url) => url === pathnameClearedFromPrefix())

const handle401 = () => {
  clearStorage()
  if (!isLoginPage()) {
    redirectToLogin()
  }
}

const redirectTo404 = () => {
  window.location.assign(withOrganizationPrefix('/404'))
}

const handleDefaultError = async (response) => {
  const { errors } = await response.json()
  if (errors && errors.length) {
    mapObjIndexed((error) => showError(error), errors)
  } else {
    showError('Something went wrong')
  }
}

const responseHandler = async (response, requestResponseType = 'json') => {
  let data = {}

  if (!response.ok) {
    switch (response.status) {
      case 401:
        handle401()
        break

      case 404:
        redirectTo404()
        break

      case 500:
        dispatch500()
        break
      case 422:
        // dispatch500()
        //it's form validation, no need to raise any toast notification
        break

      default:
        handleDefaultError(response)
        break
    }
  }


  try {
    data = await response[requestResponseType]()
  } catch (e) {
    data = {}
  }
  data.message = data.error
  data.status = response.status
  data.error = !response.ok
  return data
}

export const GET = (partialUrl, opts = {}, requestResponseType) =>
  async function () {
    const query = isEmpty(opts) ? '' : `?${stringify(opts)}`

    const request = new Request(basePath + partialUrl + query, {
      method: 'GET',
      headers: getHeaders(),
      qs: opts,
    })

    let response = {}

    try {
      response = await fetch(request)
    } catch (error) {
      console.log(error)
    }

    return await responseHandler(response, requestResponseType)
  }

export const PUT = (partialUrl, data, baseUrl = basePath, headers = getHeaders()) =>
  async function () {
    const request = new Request(baseUrl + partialUrl, {
      method: 'PUT',
      headers,
      body: data,
    })

    let response = {}

    try {
      response = await fetch(request)
    } catch (error) {
      console.log(error)
    }

    return await responseHandler(response)
  }

export const POST = (partialUrl, data) =>
  async function () {
    const request = new Request(basePath + partialUrl, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
    })

    let response = {}

    try {
      response = await fetch(request)
    } catch (error) {
      console.log(error)
    }

    return await responseHandler(response)
  }

export const DELETE = (partialUrl) =>
  async function () {
    const request = new Request(basePath + partialUrl, {
      method: 'DELETE',
      headers: getHeaders(),
    })

    let response = {}

    try {
      response = await fetch(request)
    } catch (error) {
      console.log(error)
    }

    return await responseHandler(response)
  }

export default { GET, DELETE, PUT, POST }
