import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Layout from '../directive/blocks/Layout'
import Container from '../directive/blocks/Container'
import ForWhom from '../directive/blocks/ForWhom'
import PageText from '../directive/blocks/PageText'
import PageTitle from '../directive/blocks/PageTitle'
import TileGroup from '../directive/blocks/TileGroup'
import Tile from '../directive/blocks/Tile'
import Columns from '../directive/blocks/Columns'
import ColumnTop from '../directive/blocks/ColumnTop'
import ColumnLeft from '../directive/blocks/ColumnLeft'
import ColumnRight from '../directive/blocks/ColumnRight'
import SurveyNav from '../directive/blocks/SurveyNav'
import Button from '../directive/blocks/Button'
import ButtonGroup from '../directive/blocks/ButtonGroup'
import TooltipList from '../directive/blocks/TooltipList'
import cn from 'classnames'
import { HowToPopupTextByLocale } from './HowToPopupText'
import { WhatNowPopupTextByLocale } from './WhatNowPopupText'

import { forAuth, UserContext } from '../hoc'
import { useTranslation } from 'react-i18next'
import DocumentUploader from './documentUpload'
import DocumentArchive from './documentArchive'


const Tooltips = ({ className }) => {
  const { t, i18n } = useTranslation()

  return (
    <>
      <TooltipList
        className={className}
        params={{
          tooltips: [
            {
              title: t('documents.tooltips.0.title'),
              reTitle: t('documents.tooltips.0.title'),
              text: HowToPopupTextByLocale(i18n.language),
            },
            {
              title: t('documents.tooltips.1.title'),
              reTitle: t('documents.tooltips.1.title'),
              text: WhatNowPopupTextByLocale(i18n.language),
            },
          ],
        }}
      />
    </>
  )
}

const DocumentGroup = (props) => {
  const [isSigned, setIsSigned] = useState(false)
  const me = useContext(UserContext)
  const { profile } = me

  useEffect(() => {
    (async () => {
      setIsSigned(profile.signatureUrl !== undefined)
    })()
  }, [profile.signatureUrl])

  return (
    <div>
      {isSigned && <DocumentUploader setIsSigned={setIsSigned} documentType={props.documentType} docs={props.docs} />}
    </div>
  )
}


const _Documents = () => {
  const { t } = useTranslation()

  const me = useContext(UserContext)
  const { profile } = me

  const documentTypes = {
    advanceDirective: 'AdvanceDirective',
    medicalOrders: 'MedicalOrders',
    other1: 'Other1',
    other2: 'Other2',
  }

  let advDirectiveDocs = []
  let medicalDocs = []
  let firstOtherDocs = []
  let secondOtherDocs = []

  if (me.documents.length > 0) {
    const executedDocuments = me.documents.filter(doc => doc.documentVersions[0].executed)

    advDirectiveDocs = executedDocuments.filter(doc => doc.dType === 'AdvanceDirective').slice(-4)
    medicalDocs = executedDocuments.filter(doc => doc.dType === 'MedicalOrders').slice(-4)
    firstOtherDocs = executedDocuments.filter(doc => doc.dType === 'Other1').slice(-4)
    secondOtherDocs = executedDocuments.filter(doc => doc.dType === 'Other2').slice(-4)
  }

  const [advanceDirectiveExpanded, setAdvanceDirectiveExpanded] = useState(true)
  const [medicalOrdersExpanded, setMedicalOrdersExpanded] = useState(medicalDocs.length > 0)
  const [firstOtherExpanded, setFirstOtherExpanded] = useState(firstOtherDocs.length > 0)
  const [secondOtherExpanded, setSecondOtherExpanded] = useState(secondOtherDocs.length > 0)

  const onAdvanceDirectiveExpand = () => {
    setAdvanceDirectiveExpanded(!advanceDirectiveExpanded)
  }
  const onMedicalOrdersExpand = () => {
    setMedicalOrdersExpanded(!medicalOrdersExpanded)
  }
  const firstOtherExpand = () => {
    setFirstOtherExpanded(!firstOtherExpanded)
  }
  const secondOtherExpand = () => {
    setSecondOtherExpanded(!secondOtherExpanded)
  }

  return (
    <Layout>
      <div className="page-documents">
        <Container>
          <ColumnTop>
            <ForWhom>
              {t('documents.for')} <Link to="/profile">{`${profile.firstName} ${profile.lastName}`}</Link>
            </ForWhom>
            <PageTitle params={{ text: t('documents.title') }} />
          </ColumnTop>
          <Columns>
            <ColumnLeft className={'flex-grow-half'}>
              <PageText params={{ text: t('documents.infoText') }} />
              <TileGroup white >
                <Tile
                  title={t('documents.advanceDirective')}
                  expandable
                  expanded={advanceDirectiveExpanded}
                  onTitleClick={onAdvanceDirectiveExpand}
                  white
                >
                  <DocumentGroup documentType={documentTypes.advanceDirective} docs={advDirectiveDocs} />
                  <DocumentArchive docs={advDirectiveDocs} />
                </Tile>
                <Tile
                  title={t('documents.medicalOrders')}
                  expandable
                  expanded={medicalOrdersExpanded}
                  onTitleClick={onMedicalOrdersExpand}
                  white
                >
                  <DocumentGroup documentType={documentTypes.medicalOrders} docs={medicalDocs} />
                  <DocumentArchive docs={medicalDocs} />
                </Tile>
                <Tile
                  title={t('documents.other')}
                  expandable
                  expanded={firstOtherExpanded}
                  onTitleClick={firstOtherExpand}
                  white
                >
                  <DocumentGroup documentType={documentTypes.other1} docs={firstOtherDocs} />
                  <DocumentArchive docs={firstOtherDocs} />
                </Tile>
                <Tile
                  title={t('documents.other')}
                  expandable
                  expanded={secondOtherExpanded}
                  onTitleClick={secondOtherExpand}
                  white
                >
                  <DocumentGroup documentType={documentTypes.other2} docs={secondOtherDocs} />
                  <DocumentArchive docs={secondOtherDocs} />
                </Tile>
              </TileGroup>

              <Tooltips className="-desktop-hide" />
            </ColumnLeft>
            <ColumnRight>
              <Tooltips />
            </ColumnRight>
          </Columns>
        </Container>
        <SurveyNav>
          <Container className="survey-nav_container">
            <ButtonGroup className={cn('-survey-nav')}>
              <ButtonGroup className="-survey-next-btns">
                <Button to="/dashboard">
                  {t('documents.myDashboard')}
                </Button>
              </ButtonGroup>
              <Button
                className="-link"
              >
              </Button>
            </ButtonGroup>
          </Container>
        </SurveyNav>
      </div>
    </Layout>
  )
}
let Documents = forAuth(_Documents)
export default Documents
