import React from 'react'
import cn from 'classnames'

import '../../styles/blocks/columns.scss'

const Columns = ({ className, children }) => (
  <div className={cn('columns', className)}>
    {children}
  </div>
)

export default Columns
