import { map } from 'ramda'
import baseOptions from '../baseAnswersOptions.json'
import wellSummaryOptions from '../wellSummaryAnswersOptions.json'
import { BASE_TYPE, WELL_SUMMARY_TYPE } from '../constants/productTypes'

export const getAnswersOptions = (productType) => {
  switch (productType) {
    case BASE_TYPE:
      return baseOptions
    case WELL_SUMMARY_TYPE:
      return wellSummaryOptions
    default:
      return {}
  }
}

export const getAnswersOptionsTranslator = (answersOptions) => (t) =>
  map((value) => ({
    ...value,
    options: value.options.map((option) => ({
      ...option,
      title: t(option.title),
      text: t(option.text),
    })),
  }))(answersOptions)
