import React, { useMemo, useState } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import PageTitle from './blocks/PageTitle'
import PageText from './blocks/PageText'
import TooltipList from './blocks/TooltipList'
import FormFields from './blocks/FormFields'
import FormInput from './blocks/FormInput'
import AddEntity from './blocks/AddEntity'
import FormSelect from './blocks/FormSelect'
import Block from './blocks/Block'
import { capitalize, chain as formatChain, formatPhoneNumber, trim, formatZip } from '../inputs/formatters'
import { required, minLength, emailFormat, chain, zipValidation, nameValidation, noSpecialCharacters, cityValidation } from '../inputs/validators'
import { getStatesOptions } from '../constants/UsStates'

const IdentifyProxyPage = ({ values, form, blocks }) => {
  const { t } = useTranslation()
  const [backupProxyOpened, setOpenedBackupProxy] = useState(!!values.secondary)

  const removeBackupProxy = () => {
    form.mutators.setValue('[secondary]', undefined)
    setOpenedBackupProxy(false)
  }

  const addBackupProxy = () => {
    form.mutators.setValue('[secondary]', {})
    setOpenedBackupProxy(true)
  }

  return (
    <div className="page-proxy">
      {(blocks || []).map((block, index) => (
        <Block key={index} block={block} />
      ))}

      <ProxyFields entityId={'primary'} />
      {!values.secondary && !backupProxyOpened && (
        <AddEntity
          onClick={() => addBackupProxy()}
          hint={{
            title: t('common.backUpProxy.hintModal.title'),
            text: t('common.backUpProxy.hintModal.text'),
          }}
        >
          + {t('common.backUpProxy.addButton')}
        </AddEntity>
      )}
      {(!!values.secondary || backupProxyOpened) && (
        <>
          <PageTitle params={{ text: t('common.backUpProxy.title') }} />
          <PageText params={{ text: t('common.backUpProxy.text') }} />
          <TooltipList
            params={{
              tooltips: [
                {
                  title: t('common.backUpProxy.tooltips.0.title'),
                  reTitle: t('common.backUpProxy.tooltips.0.reTitle'),
                  text: t('common.backUpProxy.tooltips.0.text'),
                },
              ],
            }}
          />
          <ProxyFields entityId={'secondary'} />
          <AddEntity onClick={() => removeBackupProxy()}>&ndash; {t('common.backUpProxy.removeButton')}</AddEntity>
        </>
      )}
    </div>
  )
}

const ProxyFields = ({ entityId }) => {
  const { t } = useTranslation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const statesOptions = useMemo(() => getStatesOptions(t), [])

  return (
    <FormFields>
      <Field
        name={`${entityId}[firstName]`}
        validate={chain(required, minLength(2),nameValidation)}
        format={formatChain(trim, capitalize)}
        render={({ input, meta }) => (
          <FormInput
            label={t('form.firstNameLabel')}
            placeholder={t('form.firstNamePlaceholder')}
            type="text"
            input={input}
            meta={meta}
          />
        )}
      />

      <Field
        name={`${entityId}[lastName]`}
        validate={chain(required, nameValidation)}
        format={formatChain(trim, capitalize)}
        render={({ input, meta }) => (
          <FormInput
            label={t('form.lastNameLabel')}
            placeholder={t('form.lastNamePlaceholder')}
            type="text"
            input={input}
            meta={meta}
          />
        )}
      />

      <Field name={`${entityId}[email]`} validate={chain(emailFormat)}>
        {({ input, meta }) => (
          <FormInput
            label={t('form.emailLabel')}
            placeholder={t('form.emailPlaceholder')}
            type="text"
            input={input}
            meta={meta}
          />
        )}
      </Field>

      <Field name={`${entityId}[address]`} validate={chain(noSpecialCharacters)}>
        {({ input, meta }) => (
          <FormInput
            label={t('form.addressLabel')}
            placeholder={t('form.addressPlaceholder')}
            type="text"
            input={input}
            meta={meta}
          />
        )}
      </Field>

      <Field name={`${entityId}[city]`} validate={chain(cityValidation)}>
        {({ input, meta }) => (
          <FormInput
            label={t('form.cityLabel')}
            placeholder={t('form.cityPlaceholder')}
            type="text"
            input={input}
            meta={meta}
          />
        )}
      </Field>

      <Field name={`${entityId}[state]`}>
        {({ input, meta }) => (
          <FormSelect
            label={t('form.stateLabel')}
            placeholder={t('form.statePlaceholder')}
            options={statesOptions}
            input={input}
            meta={meta}
          />
        )}
      </Field>

      <Field name={`${entityId}[zip]`} validate={chain(zipValidation)} parse={formatZip}>
        {({ input, meta }) => (
          <FormInput
            label={t('form.zipLabel')}
            placeholder={t('form.zipPlaceholder')}
            type="text"
            input={input}
            meta={meta}
          />
        )}
      </Field>

      <Field name={`${entityId}[phone]`} format={formatPhoneNumber}>
        {({ input, meta }) => (
          <FormInput
            label={t('form.mainPhoneLabel')}
            placeholder={t('form.mainPhonePlaceholder')}
            type="text"
            input={input}
            meta={meta}
          />
        )}
      </Field>

      <Field name={`${entityId}[additionalPhone]`} format={formatPhoneNumber}>
        {({ input, meta }) => (
          <FormInput
            label={t('form.additionalPhoneLabel')}
            placeholder={t('form.additionalPhonePlaceholder')}
            type="text"
            input={input}
            meta={meta}
          />
        )}
      </Field>
    </FormFields>
  )
}

export default IdentifyProxyPage
