import PageTitle from '../blocks/PageTitle'
import PageText from '../blocks/PageText'
import { updateCacheAnswers } from '../../common/cacheUtils'
import { getAnswerByQid } from '../TheDirective'
import { getAnswersOptions, getAnswersOptionsTranslator } from '../../common/answersOptions'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'
import { WELL_SUMMARY_TYPE } from '../../constants/productTypes'
import { ProxyDesignationPage } from './ProxyDesignationPage'
import { CCSpecificWishesFirstPage } from './CCSpecificWishesFirstPage'
import ChooseOneCard from '../blocks/ChooseOneCard'
import TextAnswer from '../blocks/TextAnswer'
import { ChooseOneSummary } from '../blocks/summary/ChooseOneSummary'
import { TextAnswerSummary } from '../blocks/summary/TextAnswerSummary'

const COMFORT_CARE_WISHES_URL = 'cc-goals-of-care-2'
const CC_PAIN = 'ccpain'
const CC_ANTIBIOTICS = 'ccantibiotics'
const CC_QUICKEND = 'cceolpref'
const CC_HOSPICE = 'cchospice'
const CC_PLACEOFDEATH = 'ccdieathome'
const CC_OTHERWISHES = 'ccothertmttextbox'
const CC_QIDS = [CC_PAIN, CC_ANTIBIOTICS, CC_QUICKEND, CC_HOSPICE, CC_PLACEOFDEATH]
const CC_ALL_QIDS = [...CC_QIDS, CC_OTHERWISHES]

const getTranslatedAnswersOptions = getAnswersOptionsTranslator(getAnswersOptions(WELL_SUMMARY_TYPE))

const CCSpecificWishesSecondPage = (t) => {
  const answersOptions = getTranslatedAnswersOptions(t)

  return {
    slug: COMFORT_CARE_WISHES_URL,
    getPrevStep: () => ({
      page: CCSpecificWishesFirstPage(t),
      url: `${DIRECTIVE_URL}/${CCSpecificWishesFirstPage(t).slug}`,
    }),
    getNextStep: () => ({
      page: ProxyDesignationPage(t),
      url: `${DIRECTIVE_URL}/${ProxyDesignationPage(t).slug}`,
    }),
    setInitialValues: (answers) => {
      return [...CC_QIDS, CC_OTHERWISHES].reduce((res, qid) => ({ ...res, [qid]: getAnswerByQid(qid)(answers) }), {})
    },
    serializeValues: (values) =>
      [...CC_QIDS, CC_OTHERWISHES]
        .filter((qid) => values[qid] !== undefined)
        .map((qid) => ({
          variables: { qid, val: values[qid] },
          update: updateCacheAnswers,
        })),
    validate: () => {},
    isRequired: false,
    isAnswered: (answers) => [...CC_QIDS, CC_OTHERWISHES].some((qid) => !!getAnswerByQid(qid)(answers)),
    dashboard: {
      title: t('directive.well.page.CCSpecificWishesAdditional.dashboardTitle'),
    },
    summary: {
      title: t('directive.well.page.CCSpecificWishesAdditional.summaryTitle'),
      blocks: [
        {
          component: ChooseOneSummary,
          params: {
            id: CC_PAIN,
            heading: t('directive.well.page.CCSpecificWishesAdditional.summaryLabels.pain'),
            options: answersOptions[CC_PAIN].options,
          },
        },
        {
          component: ChooseOneSummary,
          params: {
            id: CC_ANTIBIOTICS,
            heading: t('directive.well.page.CCSpecificWishesAdditional.summaryLabels.antibiotics'),
            options: answersOptions[CC_ANTIBIOTICS].options,
          },
        },
        {
          component: ChooseOneSummary,
          params: {
            id: CC_QUICKEND,
            heading: t('directive.well.page.CCSpecificWishesAdditional.summaryLabels.quickend'),
            options: answersOptions[CC_QUICKEND].options,
          },
        },
        {
          component: ChooseOneSummary,
          params: {
            id: CC_HOSPICE,
            heading: t('directive.well.page.CCSpecificWishesAdditional.summaryLabels.hospice'),
            options: answersOptions[CC_HOSPICE].options,
          },
        },
        {
          component: ChooseOneSummary,
          params: {
            id: CC_PLACEOFDEATH,
            heading: t('directive.well.page.CCSpecificWishesAdditional.summaryLabels.placeofdeath'),
            options: answersOptions[CC_PLACEOFDEATH].options,
          },
        },
        {
          component: TextAnswerSummary,
          params: {
            id: CC_OTHERWISHES,
            heading: t('directive.well.page.CCSpecificWishesAdditional.summaryLabels.otherwishes'),
          },
        },
      ],
    },
    blocks: [
      {
        component: PageTitle,
        params: {
          text: t('directive.well.page.CCSpecificWishesAdditional.blocks.pageTitle'),
        },
      },
      {
        component: PageText,
        params: {
          text: t('directive.well.page.CCSpecificWishesAdditional.blocks.pageText'),
        },
        columnRight: true,
      },
      {
        component: ChooseOneCard,
        params: {
          id: CC_PAIN,
          title: t('directive.well.page.CCSpecificWishesAdditional.blocks.pain.title'),
          options: answersOptions[CC_PAIN].options,
          hint: {
            title: t('directive.well.page.CCSpecificWishesAdditional.blocks.pain.hintTitle'),
            text: t('directive.well.page.CCSpecificWishesAdditional.blocks.pain.hintText'),
          },
        },
      },
      {
        component: ChooseOneCard,
        params: {
          id: CC_ANTIBIOTICS,
          title: t('directive.well.page.CCSpecificWishesAdditional.blocks.antibiotics.title'),
          options: answersOptions[CC_ANTIBIOTICS].options,
          hint: {
            title: t('directive.well.page.CCSpecificWishesAdditional.blocks.antibiotics.hintTitle'),
            text: t('directive.well.page.CCSpecificWishesAdditional.blocks.antibiotics.hintText'),
          },
        },
      },
      {
        component: ChooseOneCard,
        params: {
          id: CC_QUICKEND,
          title: t('directive.well.page.CCSpecificWishesAdditional.blocks.quickend.title'),
          options: answersOptions[CC_QUICKEND].options,
        },
      },
      {
        component: ChooseOneCard,
        params: {
          id: CC_HOSPICE,
          title: t('directive.well.page.CCSpecificWishesAdditional.blocks.hospice.title'),
          options: answersOptions[CC_HOSPICE].options,
        },
      },
      {
        component: ChooseOneCard,
        params: {
          id: CC_PLACEOFDEATH,
          title: t('directive.well.page.CCSpecificWishesAdditional.blocks.placeofdeath.title'),
          options: answersOptions[CC_PLACEOFDEATH].options,
        },
      },
      {
        component: TextAnswer,
        params: {
          id: CC_OTHERWISHES,
          title: t('directive.well.page.CCSpecificWishesAdditional.blocks.otherwishes.title'),
          notRemoveEmptyField: true,
          tileWrapped: true,
        },
      },
    ],
  }
}

export { CCSpecificWishesSecondPage, COMFORT_CARE_WISHES_URL, CC_QIDS, CC_ALL_QIDS }
