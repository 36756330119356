import React from 'react'
import cn from 'classnames'

import '../../styles/blocks/content.scss'

const Content = ({ className, children }) => (
  <main className={cn('content', className)}>
    {children}
  </main>
)

export default Content
