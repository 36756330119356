import React from 'react'
import SelectorRadio from './SelectorRadio'
import Tile from './Tile'
import Hint from './Hint'
import MD from './Markdown'

import '../../styles/blocks/choose-one-card.scss'

const ChooseOneCard = ({ params: { id, title, text, hint, options, onChange } }) => (
  <div className="choose-one-card">
    <Tile className="choose-one-card_tile" title={title}>
      {hint && <Hint className="choose-one-card_hint" title={hint.title} contents={hint.contents} text={hint.text} />}
      {text && (
        <div className="choose-one-card_text">
          <MD>{text}</MD>
        </div>
      )}
      <div>
        {options.map((question, index) => (
          <SelectorRadio
            key={index}
            name={id}
            className="-in-card"
            value={question.id.toString()}
            label={question.text}
            onChange={onChange}
            fancy
          />
        ))}
      </div>
    </Tile>
  </div>
)

export default ChooseOneCard
