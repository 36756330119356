import React from 'react'
import cn from 'classnames'

import '../../styles/blocks/survey-nav.scss'

const SurveyNav = ({ children, className }) => (
  <div className={cn('survey-nav', className)}>
    {children}
  </div>
)

export default SurveyNav
